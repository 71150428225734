import React from 'react'
import { Skeleton } from '@mui/material'
import { Container, TextContainer } from './styles'

export const AlertsSkeleton = () => {
  return (
    <Container>

            <Skeleton style={{
              borderRadius: 8
            }} variant="rectangular" width={98} height={86}/>

      <TextContainer>

                <div>
                    <Skeleton style={{
                      marginBottom: 5,
                      borderRadius: 0
                    }} variant="text" width={200} height={21}/>

          <Skeleton style={{
            borderRadius: 0
          }} variant="text" width={120} height={19}/>
          <Skeleton style={{
            borderRadius: 0
          }} variant="text" width={120} height={19}/>
        </div>

        <Skeleton variant="text" width={300} height={1}/>

      </TextContainer>

    </Container>
  )
}
