import { Geo } from '@aws-amplify/geo'
import maplibregl from 'maplibre-gl'

/** getPlaces()
 * @param {object} provider
 * @param {number} cityConfig
 * @param {string} searchTerm
 * @param {object} userPosition
 * @param {string} sessionToken
 * @param {object} autoCompleteRef
 * @param {function} displaySuggestions
 * @returns {Promise<Response | void>}
 */

export const getPlaces = async (
  provider,
  cityConfig,
  searchTerm,
  userPosition,
  sessionToken,
  autoCompleteRef,
  displaySuggestions
) => {
  const corner1 = new maplibregl.LngLat(cityConfig?.map?.map_southwest_lng, cityConfig?.map?.map_southwest_lat)

  const corner2 = new maplibregl.LngLat(cityConfig?.map?.map_northeast_lng, cityConfig?.map?.map_northeast_lat)

  const bounds = new maplibregl.LngLatBounds(corner1, corner2)

  switch (provider?.id) {
    case 'google_places_autocomplete':
      return getGooglePlacesAutocomplete(
        cityConfig,
        provider,
        searchTerm,
        userPosition,
        sessionToken,
        autoCompleteRef,
        displaySuggestions,
        bounds
      )
    case 'here_maps_autocomplete':
      return fetch(`https://places.api.here.com/places/v1/autosuggest?at=${cityConfig.map.map_center_lat},${cityConfig.map.map_center_lng}&app_id=6WEpHL1aol82fWWevOTM&app_code=6R-4NGJdzqmklqUWtF6A_g&size=${provider?.limit || 1}&q=${searchTerm + ', ' + cityConfig.name}`)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Error getting place')
          }
        })
        .then((response) => {
          return {
            results: response?.results?.map(item => ({
              ...item,
              geometry: {
                coordinates: [item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[1] : item?.position[0], item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[0] : item?.position[1]]
              }
            }))
          }
        })
    case 'aws_here_maps_autocomplete':
      return getAmplifyPlaces(
        searchTerm,
        cityConfig,
        userPosition,
        provider,
        bounds
      )
    case 'photon_komoot':
      return fetch(`https://photon.komoot.io/api/?lat=${cityConfig?.map.map_center_lat}&lon=${cityConfig?.map.map_center_lng}&lang=en&limit=${provider?.limit || 5}&q=${searchTerm + ', ' + cityConfig?.name}&bbox=${cityConfig?.map.map_southwest_lng},${cityConfig?.map.map_southwest_lat},${cityConfig?.map.map_northeast_lng},${cityConfig?.map.map_northeast_lat}`)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Error getting place')
          }
        })
        .then((response) => {
          return {
            results: response?.features?.map(item => ({
              ...item,
              geometry: {
                coordinates: [item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[1] : item?.position[0], item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[0] : item?.position[1]]
              }
            }))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    case 'photon_ualabee':
      return fetch(`${process.env.REACT_APP_PLACES_HOST}/api/?lat=${cityConfig?.map.map_center_lat}&lon=${cityConfig?.map.map_center_lng}&lang=${cityConfig?.country?.country_id === 4 ? 'en' : 'es'}&limit=${provider?.limit || 5}&q=${searchTerm + ', ' + cityConfig?.name}&bbox=${cityConfig?.map.map_southwest_lng},${cityConfig?.map.map_southwest_lat},${cityConfig?.map.map_northeast_lng},${cityConfig?.map.map_northeast_lat}`)
        .then(response => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Error getting place')
          }
        })
        .then((response) => {
          return {
            results: response?.features?.map(item => ({
              ...item,
              geometry: {
                coordinates: [item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[1] : item?.position[0], item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[0] : item?.position[1]]
              }
            }))
          }
        })
    default: // "photon_ualabee" or "photon_komoot" or other provider didn't specified
      return fetch(`${cityConfig?.country?.country_id === 4 ? 'https://photon.komoot.io' : process.env.REACT_APP_PLACES_HOST}/api/?lat=${cityConfig?.map.map_center_lat}&lon=${cityConfig?.map.map_center_lng}&lang=${cityConfig?.country?.country_id === 4 ? 'en' : 'es'}&limit=${provider?.limit || 5}&q=${searchTerm + ', ' + cityConfig?.name}&bbox=${cityConfig?.map.map_southwest_lng},${cityConfig?.map.map_southwest_lat},${cityConfig?.map.map_northeast_lng},${cityConfig?.map.map_northeast_lat}`)
        .then(response => {
          if (response.ok) {
            return response.json()
          }
        })
        .then((response) => {
          const currentResults = response?.features || response?.results
          return {
            results: currentResults?.map(item => ({
              ...item,
              geometry: {
                coordinates: [item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[1] : item?.position[0], item?.geometry?.coordinates?.length >= 1 ? item?.geometry?.coordinates[0] : item?.position[1]]
              }
            }))
          }
        })
  }
}

const getGooglePlacesAutocomplete = (
  cityConfig,
  provider,
  searchTerm,
  userPosition,
  sessionToken,
  autoCompleteRef,
  displaySuggestions,
  bounds
) => {
  const service = new window.google.maps.places.AutocompleteService()

  const useUserPosition = userPosition?.lat && userPosition?.lng && bounds.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))

  const request = {
    input: searchTerm,
    componentRestrictions: { country: 'ar' },
    language: 'es-419',
    radius: 50000,
    strictbounds: 'true',
    location: new window.google.maps.LatLng(cityConfig.map.map_center_lat, cityConfig.map.map_center_lng),
    origin: new window.google.maps.LatLng(useUserPosition ? userPosition?.lat : cityConfig.map.map_center_lat, useUserPosition ? userPosition?.lng : cityConfig.map.map_center_lng),
    bounds: new window.google.maps.LatLngBounds(bounds?._southWest || {
      lat: cityConfig.map.map_southwest_lat,
      lng: cityConfig.map.map_southwest_lng
    }, bounds?._northEast || { lat: cityConfig.map.map_northeast_lat, lng: cityConfig.map.map_northeast_lng }),
    sessionToken
  }

  service.getPlacePredictions(request, displaySuggestions)
}

const getAmplifyPlaces = async (
  searchTerm,
  cityConfig,
  userPosition,
  provider,
  bounds
) => {
  const useUserPosition = userPosition?.lat && userPosition?.lng && bounds.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))

  const countryCode = getCountryCode(cityConfig.country.country_id)

  const searchOptionsWithBiasPosition = {
    countries: [countryCode], // Alpha-3 country codes https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    maxResults: provider?.limit || 5, // 50 is the max and the default
    biasPosition: useUserPosition
      ? [userPosition?.lng, userPosition?.lat]
      : [cityConfig.map.map_center_lng, cityConfig.map.map_center_lat] // Coordinates point to act as the center of the search
  }

  return Geo.searchByText(searchTerm, searchOptionsWithBiasPosition)
    .then((response) => ({
      results: response.map(place => ({
        ...place,
        geometry: {
          coordinates: [place.geometry.point[1], place.geometry.point[0]]
        },
        name: place.label.split(',')[0].trim() || `${place.street} ${place.postalCode}`,
        latLng: {
          lat: place.geometry.point[1],
          lng: place.geometry.point[0]
        },
        properties: {
          city: place?.region || cityConfig?.name,
          country: cityConfig?.country?.name,
          countrycode: place?.country || '',
          housenumber: place?.addressNumber || '',
          name: place.label.split(',')[0].trim() || `${place.street} ${place.postalCode}`,
          osm_id: `${place.geometry.point[1]}${place.geometry.point[0]}`,
          osm_key: null,
          osm_type: null,
          osm_value: null,
          postcode: place?.postalCode || null,
          state: place?.properties?.state || cityConfig?.name || null,
          street: null
        },
        id: `${place.geometry.point[1]}${place.geometry.point[0]}`
      })) || []
    }))
    .catch((e) => {
      console.log(e)
    })
}

const getCountryCode = (countryId) => {
  if (typeof countryId !== 'number') return

  switch (countryId) {
    case 1: // Argentina
      return 'ARG'
    case 2: // Chile
      return 'CHL'
    case 3: // Colombia
      return 'COL'
    case 4: // Mexico
      return 'MEX'
    case 5: // Uruguay
      return 'URY'
    case 6: // Perú
      return 'PER'
    default:
      return 'ARG'
  }
}
