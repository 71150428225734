import React from 'react'
import { CheckIcon, ReportsContainer } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { handleClickWebToAppButton } from '../../../../actions/ui'
import { Report } from '../Report'
import { useLocation } from 'react-router-dom'
import { logEvent } from '../../../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { Box, Chip, Fab, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../../../theme/theme'
import { Add } from '@mui/icons-material'

export const Reports = ({ reports, showBar }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const mobile = useMediaQuery('(max-width:480px)')

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]),
    stop_id: pathname?.split('/')[3]
  }

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const user = useSelector(state => state?.user?.auth)

  const handleReport = () => {
    dispatch(handleClickWebToAppButton(true, {
      from: 'stop_add_stop_report',
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: user?.uid,
      os: 'web',
      city_id: cityConfig?.city_id.toString() || urlParams?.city_id.toString(),
      link_to: `${process.env.REACT_APP_HOST}/stops/${urlParams?.city_id}/${urlParams?.stop_id}`
    }, logEvent))
  }

  return (
        <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 0 10px 0',
              backgroundColor: '#FAFAFA',
              borderBottomRightRadius: '16px',
              borderBottomLeftRadius: '16px',
              height: '100%',
              '@media (max-width:480px)': {
                height: showBar ? 'calc(100vh - 158px)' : 'calc(100vh - 98px)',
                borderRadius: 0
              }
            }}
        >
            {reports?.length > 0
              ? (
                    <ReportsContainer>
                        {reports?.map((report) => {
                          return (
                                <Report
                                    key={report?.id}
                                    report={report}
                                />
                          )
                        })}
                    </ReportsContainer>
                )
              : (<Box
                        sx={{
                          padding: '15px 24px',
                          display: 'flex',
                          backgroundColor: 'white',
                          borderRadius: '16px',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          margin: '34px 0 0 0',
                          '@media (max-width:480px)': {
                            margin: '34px 0 0 10px',
                            fontSize: '14px',
                            justifyContent: 'flex-start'
                          }
                        }}
                    >
                        <CheckIcon/>
                        <Typography
                            sx={{
                              margin: 0,
                              color: theme.text.primary.main,
                              fontFamily: 'Visby Round',
                              fontSize: 'inherit',
                              '@media (max-width:480px)': {
                                margin: '0 0 0 16px',
                                fontSize: '14px'
                              }
                            }}
                        >
                            {t('stop.not_reports')}
                        </Typography>
                        <Chip
                            sx={{
                              '@media (max-width:480px)': {
                                display: 'none'
                              }
                            }}
                            clickable
                            variant='outlined'
                            label={t('stop.report')}
                            onClick={handleReport}
                        />
                    </Box>
                )
            }

            {mobile &&
                <Fab
                    sx={{
                      color: '#ffffff',
                      backgroundColor: theme.palette.primary.main,
                      right: '20px',
                      bottom: '80px',
                      position: 'absolute',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main
                      }
                    }}
                    aria-label="add stop report"
                    onClick={() => dispatch(handleClickWebToAppButton(true, {
                      from: 'stop_add_stop_report',
                      lat: userPosition ? userPosition?.lat : null,
                      lng: userPosition ? userPosition?.lng : null,
                      user_id: user?.uid,
                      os: 'web',
                      city_id: pathname?.split('/')[2],
                      link_to: `${process.env.REACT_APP_HOST}/stops/${Number(pathname?.split('/')[2])}/${pathname?.split('/')[3]}`
                    }, logEvent))}
                >
                    <Add/>
                </Fab>
            }
        </Box>
  )
}
