import { getAuth } from 'firebase/auth'

export const getNearby = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      'Client-Platform': 'web'
    },
    body: JSON.stringify(params)
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/stops/getNearby`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting stops nearby')
      }
    })
    .then((response) => response?.result)
}
