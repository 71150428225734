import styled from 'styled-components/macro'
import { ArrowBackRounded, CloseRounded, LocationOnRounded, Menu } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export const SearchNavbarContainer = styled.div` 
    height: ${props => props?.mode ? '57px' : 'auto'}; 
    display: ${props => props?.mode && 'flex'}; 
    align-items: ${props => props?.mode && 'center'};  
`

export const Navbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    width: 100%;
`

export const MenuButton = styled(IconButton)` 
    padding: 8px;
`

export const MenuIcon = styled(Menu)`
    color: ${props => props.theme.palette.icons.secondary.main}; 
`

export const BackButton = styled(IconButton)` 
    padding: 8px;
`

export const BackIcon = styled(ArrowBackRounded)`
    color: ${props => props.theme.palette.icons.secondary.main}; 
`

export const Logo = styled.img`
    width: auto;
    height: 38px;
`

export const LocationContainer = styled.div`
`

export const CityLabel = styled.div`
    min-width: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const LabelIcon = styled(LocationOnRounded)`
    color: ${props => props.theme.palette.icons.secondary.main};
`

export const Label = styled.p`
    margin: 0;
    color: ${props => props.theme.palette.text.secondary.main}; 
`

export const CloseButton = styled(IconButton)`
`

export const CloseIcon = styled(CloseRounded)`
    color: ${props => props.theme.palette.icons.secondary.main}
`

export const FiltersTitle = styled.h1`
    font-family: 'Visby Round';
    margin: 0 0 0 24px; 
    font-size: 18px;
    color: #222831;
    font-weight: 500;
`
