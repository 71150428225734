import React, { useEffect, useState } from 'react'
import { Address, AddressContent, AddressIcon, BackButton, Container, Information, NavigationBar, Title } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { handleOpenAlert, setFromPlacesSelected, setToPlacesSelected } from '../../actions/ui'
import { getPlaceByLatLng } from '../../db/getPlaceByLatLng'
import { useHistory, useLocation } from 'react-router-dom'
import NavBar from '../NavBar'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../uicomponents/layouts/Layout'
import { theme } from '../../theme/theme'
import { Box, Button, TextField, Typography } from '@mui/material'
import { ArrowBackRounded, MyLocationRounded } from '@mui/icons-material'
import { MapContainer } from '../../uicomponents/Map'
import { useMap } from 'react-map-gl'
import { addDoc, collection, GeoPoint } from 'firebase/firestore'
import { db, logEvent } from '../../firebase/firebase-config'
import LoadingButton from '@mui/lab/LoadingButton'
import { setPlace } from '../../actions/user'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Grid from '@mui/material/Grid'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const ChooseFromMap = () => {
  const history = useHistory()
  const query = useQuery()

  const favoritePlace = query.get('add_favorite_place')

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { map } = useMap()

  const [address, setAddress] = useState(null)
  const [loading, setLoading] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [favoritePlaceName, setFavoritePlaceName] = useState('')

  const orientation = useSelector(state => state?.ui?.orientation)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const auth = useSelector(state => state?.user?.auth)
  const savedPlaces = useSelector(state => state?.user?.savedPlaces)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)

  const [currentCenter, setCurrentCenter] = useState(null)

  useEffect(() => {
    if (!map || currentCenter) return
    setCurrentCenter(map.getCenter())
  }, [map])

  const handleClickConfirm = async () => {
    if (!favoritePlace) {
      if (orientation === 'from') dispatch(setFromPlacesSelected(address))
      if (orientation === 'to') dispatch(setToPlacesSelected(address))
      return history.push('/destination-preview')
    }

    if (favoritePlace === 'home' || favoritePlace === 'work') {
      await handleAddFavoritePlace()
    }
    if (favoritePlace === 'place') {
      setFormOpen(true)
    }
  }

  const getAddress = async (orientation, latLng) => {
    try {
      const response = await getPlaceByLatLng(latLng, cityConfig)

      if (orientation === 'from') {
        dispatch(setFromPlacesSelected(response))
      } else {
        dispatch(setToPlacesSelected(response))
      }

      setAddress({
        name: response?.name,
        latLng
      })
    } catch (e) {
      console.log(e)
    }
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleMove = (e) => {
    const center = e.target.getCenter()
    setCurrentCenter(center)

    const latLng = { lat: center?.lat.toString(), lng: center?.lng.toString() }

    setAddress({
      name: t('home.searching'),
      latLng
    })

    if (orientation === 'from') {
      dispatch(setFromPlacesSelected({ name: t('home.searching'), latLng }))
    } else if (orientation === 'to') {
      dispatch(setToPlacesSelected({ name: t('home.searching'), latLng }))
    }

    getAddress(orientation, latLng)
  }

  const handleAddFavoritePlace = async () => {
    console.log({ address })
    const data = {
      address: address.name,
      city_id: cityConfig.city_id.toString(),
      location: new GeoPoint(address.latLng.lat, address.latLng.lng)
    }
    let collectionRef
    if (favoritePlace === 'place') {
      collectionRef = collection(db, `/users/${auth.uid}/places`)
      data.name = favoritePlaceName
    } else {
      collectionRef = collection(db, '/global/users/saved_places')
      data.type = favoritePlace
      data.user_id = auth.uid
    }

    setLoading(true)

    const logData = {
      lat: userPosition?.lat ? userPosition.lat : null, // Double
      lng: userPosition?.lng ? userPosition.lng : null, // Double
      user_gender: userData?.gender ? userData.gender : null, // String
      user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null, // Long
      user_id: auth?.uid, // String
      place_type: favoritePlace === 'place' ? 'other' : favoritePlace, // String
      city_id: cityConfig.city_id.toString() // String
    }

    try {
      await addDoc(collectionRef, data)
      logEvent('add_fav_place', logData)
      // add new place to redux
      dispatch(setPlace([...savedPlaces, { ...data, type: favoritePlace }]))

      setLoading(false)
      dispatch(handleOpenAlert({
        title: t('choose_from_map.favorite_place_success_title'),
        message: t('choose_from_map.favorite_place_success_message'),
        severity: 'success'
      }))
      history.push('/')
    } catch (e) {
      setLoading(false)
      console.log(e)
      dispatch(handleOpenAlert({
        title: t('choose_from_map.favorite_place_error_title'),
        message: t('choose_from_map.favorite_place_error_message'),
        severity: 'error'
      }))
    }
  }

  return (
    <Layout>
      <Container>
        <NavigationBar>
          <BackButton edge="start" onClick={handleBack}>
            <ArrowBackRounded variant="secondary.main"/>
          </BackButton>
          <Title>{t('choose_from_map.select_location')}</Title>
        </NavigationBar>

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '80%'
          }}
        >

          <MapContainer
            showUserPosition={false}
            showStopsNearby={false}
            height='100%'
            onMoveEnd={handleMove}
          />

          <MyLocationRounded
            sx={{
              borderRadius: '10px',
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 400,
              color: theme.palette.primary.main
            }}
          />

        </Box>

        <Information>
          {address?.name &&
            <AddressContent>
              {address?.name !== t('home.searching') &&
                <AddressIcon
                  sx={{
                    color: theme.palette.icons.secondary.main,
                    marginRight: '8px'
                  }}
                />
              }

              <Address>{address?.name}</Address>
            </AddressContent>
          }
          <LoadingButton
            loading={loading}
            loadingPosition='start'
            sx={{
              width: '100%',
              maxWidth: '300px',
              height: '45px',
              borderRadius: '30px',
              textTransform: 'none',
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
              '&:hover': {
                backgroundColor: theme.palette.primary.main
              },
              '&.Mui-disabled': {
                backgroundColor: theme.palette.secondary.main
              },
              '&.MuiLoadingButton-loading': {
                backgroundColor: `${theme.palette.secondary.main} !important`
              },
              '& .MuiLoadingButton-loadingIndicator': {
                left: '20px !important'
              },
              fontSize: '16px'
            }}
            onClick={handleClickConfirm}
            disabled={!address}
          >
            {favoritePlace ? t('choose_from_map.add_favorite_place') : t('choose_from_map.confirm')}
          </LoadingButton>
        </Information>

        {isIosApp &&
          <NavBar/>
        }
      </Container>
      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        anchor="bottom"
        open={formOpen}
        onClose={() => setFormOpen(false)}
        onOpen={() => setFormOpen(true)}
        sx={{
          borderRadius: '14px 14px 0 0',
          '.MuiDrawer-paper': {
            borderRadius: '14px 14px 0 0',
            padding: '10px'
          }
        }}
      >
        <Box>

          <Grid
            item={true}
            component='div'
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
        <span
          style={{
            margin: 0,
            padding: 0,
            width: '20px',
            height: '4px',
            borderRadius: '2px',
            backgroundColor: 'rgba(0,0,0,0.2)',
            boxSizing: 'border-box',
            display: 'table'
          }}
        />
          </Grid>
          <Typography
            variant='h6'
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
              textAlign: 'center',
              margin: 0
            }}
          >{t('choose_from_map.favorite_place_name_form_title')}</Typography>
        </Box>
        <Box
          sx={{
            padding: '10px',
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px'
          }}
        >
          <TextField
            inputProps={{
              maxLength: 50
            }}
            label={t('choose_from_map.favorite_place_name_form_label')}
            InputLabelProps={{
              shrink: true
            }}
            disabled={loading}
            onChange={(e) => setFavoritePlaceName(e.target.value)}
            value={favoritePlaceName}
            variant="standard"
            placeholder={address?.name}
            sx={{
              width: '80%',
              '& .MuiInput-underline:after': {
                borderBottomColor: theme.palette.primary.main
              }
            }}
          />
          <Grid
            component='div'
            container sx={{
              display: 'flex',
              boxSizing: 'border-box',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Grid
              item={true}
              component='div'
              xs={10}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '10px'
              }}
            >
              <LoadingButton
                onClick={handleAddFavoritePlace}
                loadingPosition='start'
                loading={loading}
                disabled={!address || loading || !favoritePlaceName}
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  height: '45px',
                  borderRadius: '30px',
                  textTransform: 'none',
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main
                  },
                  '&.Mui-disabled': {
                    backgroundColor: theme.palette.secondary.main
                  },
                  '&.MuiLoadingButton-loading': {
                    backgroundColor: `${theme.palette.secondary.main} !important`
                  },
                  '& .MuiLoadingButton-loadingIndicator': {
                    left: '20px !important'
                  },
                  fontSize: '16px',
                  boxSizing: 'border-box',
                  padding: '0 20px'
                }}
              >
                {t('choose_from_map.favorite_place_confirm')}
              </LoadingButton>
              <Button
                onClick={() => setFormOpen(false)}
                sx={{
                  maxWidth: '300px',
                  height: '45px',
                  textTransform: 'none',
                  fontSize: '14px',
                  borderRadius: '30px',
                  padding: '0 20px'
                }}
              >
                {t('choose_from_map.favorite_place_cancel')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </SwipeableDrawer>
    </Layout>
  )
}
