import React from 'react'
import { ModalButtonsContainer, ModalContainer, ModalSubtitle, ModalTitle, WorldIconContainer } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { setNoLoginDialog } from '../../actions/ui'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme/theme'
import { Button, Modal } from '@mui/material'
import { PersonRounded } from '@mui/icons-material'

const NoLoginDialog = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const noLoginMarketplaceDialog = useSelector(state => state?.ui?.noLoginDialog)
  const { t } = useTranslation()

  const handleCloseModal = () => {
    dispatch(setNoLoginDialog(false))
  }

  const handleLoginClick = () => {
    dispatch(setNoLoginDialog(false))
    history.push('/login')
  }

  return (
    <Modal
      open={noLoginMarketplaceDialog}
      onClose={handleCloseModal}
    >
      <ModalContainer>
        <WorldIconContainer>
          <PersonRounded
            style={{ color: theme.palette.primary.main }}
            fontSize={'large'}
          />
        </WorldIconContainer>
        <ModalTitle>
          {t('login.modal.title')}
        </ModalTitle>
        <ModalSubtitle>
          {t('login.modal.login_benefits_1')} <br/> <br/>
          {t('login.modal.login_benefits_2')}
        </ModalSubtitle>
        <ModalButtonsContainer>
          <Button
            onClick={() => handleCloseModal()}
            style={{
              color: '#dc5849',
              textTransform: 'none'
            }}
          >
            {t('login.modal.later')}
          </Button>
          <Button
            onClick={() => handleLoginClick()}
            style={{ color: theme.palette.primary.main, textTransform: 'none' }}
          >
            {t('login.modal.title')}
          </Button>
        </ModalButtonsContainer>
      </ModalContainer>
    </Modal>
  )
}

export default NoLoginDialog
