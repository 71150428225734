import styled from 'styled-components/macro'

export const AlertImageContainer = styled.div`
    width: 100%;
    height: 122px;
    border-radius: 14px 14px 0 0;
    padding: 10px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    -webkit-box-shadow: inset 0px -39px 22px -10px rgba(0,0,0,0.65);
    -moz-box-shadow: inset 0px -39px 22px -10px rgba(0,0,0,0.65);
    box-shadow: ${props => props?.showShadow ? 'inset 0px -39px 22px -10px rgba(0,0,0,0.65)' : 'none'};
    background-image: url(${props => props?.backgroundImg});  
`

export const AddressContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`

export const Address = styled.h3`
  font-family: 'Visby Round';
  font-size: 14px;
  margin: 0;
  color: #ffffff;
`

export const AlertInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  box-sizing: border-box;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`

export const IconImg = styled.img`
  width: 36px;
  margin-right: 14px;
`

export const Title = styled.h2`
  font-family: 'Visby Round';
  font-size: 22px;
  margin: 0;
  color: ${props => props.theme.palette.text.primary.main};
  &:first-letter {
        text-transform: uppercase;
  }
`

export const Description = styled.p`
  width: 100%;
  font-family: 'Visby Round';
  font-size: 18px;
  color: ${props => props.theme.palette.text.primary.main}; 
  margin: 20px 0;
`

export const H4 = styled.h4`
  font-family: 'Visby Round';
  font-size: 16px;
  margin: 0;
  color: #000000;
  margin-bottom: 15px;
  font-weight: 300;
`

export const LinesScrollContainer = styled.div`
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  padding: 0 20px 0 10px;
  box-sizing: border-box;
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
  display: none;
}
`

export const SourceContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Visby Round';
  font-size: 14px;
  color: ${props => props.theme.palette.text.secondary.main};  
`

export const Username = styled.h4`
  font-family: 'Visby Round';
  font-size: 14px;
  margin: 0;
  color: ${props => props.theme.palette.text.primary.main};   
  font-weight: 600;
  margin-left: 5px;
`

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`

export const ShareButtonsContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`
