import React from 'react'
import { Box, Dialog, IconButton, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { CloseRounded } from '@mui/icons-material'

/** <CustomDialog />
 * @param {boolean} open
 * @param {function} handleClose
 * @param {object | array} children
 * @param {string} title
 * @param {string} id
 * @returns {JSX.Element}
 * @constructor
 */

export const CustomDialog = ({
  open,
  handleClose,
  children,
  title,
  id
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`${id}-title`}
      disableEnforceFocus
      PaperProps={{
        style: {
          borderRadius: '16px'
        }
      }}
    >
      <Box
        sx={{
          padding: '16px 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="h2"
          id={`${id}-title`}
        >
          {title}
        </Typography>
        <IconButton edge={'end'} onClick={handleClose}>
          <CloseRounded/>
        </IconButton>
      </Box>
      {children}
    </Dialog>
  )
}

CustomDialog.prototype = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOf([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ]),
  title: PropTypes.string,
  id: PropTypes.string.isRequired
}
