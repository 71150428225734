import styled from 'styled-components/macro'

export const CountriesListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  overflow: auto;
`

export const CountryContainer = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  :last-child {
    margin-bottom: 0px;
  }
`

export const Flag = styled.img`
  width: 38px;
  margin-right: 23px;
`

export const CountryName = styled.h3`
  font-family: 'Visby Round';
  color: #000000;
  font-size: 18px;
  margin: 0;
  padding: 0;
`
