import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SwipeableDrawer } from '@mui/material'
import SwipeableBar from '../../uicomponents/SwipeableBar'
import TitleDrawer from '../../uicomponents/TitleDrawer'
import {
  CardDescription,
  CardInfo,
  CardTitle,
  CityInfoContainer,
  Content,
  ContentContainer,
  MobileCityInfo,
  TitleContainer
} from './styles'
import ShareSmallButton from '../../uicomponents/ShareSmallButton'
import { handleCityInfoClick, setCityInfoSelected, setOpenCountries } from '../../actions/ui'
import NavBar from '../NavBar'
import SendToPhoneModal from '../SendToPhoneModal'
import ShareModal from '../ShareModal'
import { Loading as LoadingLinear } from '../../uicomponents/mobile/Loading'
import { CityInfoSkeleton } from '../../uicomponents/mobile/cityInfo/Skeleton'
import { InfoItemSkeleton } from '../../uicomponents/desktop/cityInfo/Skeleton'
import { Home } from '../Desktop/Home'
import { Layout } from '../../uicomponents/layouts/Layout'
import CountriesDrawer from '../CountriesDrawer'
import CitiesDrawer from '../CitiesDrawer'

const CityInfo = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector(state => state?.user?.auth)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const mode = useSelector(state => state?.ui?.mode)
  const infoList = useSelector(state => state?.ui?.components?.city_info)
  const itemSelected = useSelector(state => state?.ui?.components?.city_info_selected)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  useEffect(() => {
    dispatch(setOpenCountries(false))
  }, [])

  const handleCloseItem = () => {
    dispatch(setCityInfoSelected(undefined))
    history.push('/city_info')
  }

  return (
    <Layout>
      {mode
        ? (
          <MobileCityInfo>
            {!infoList && <LoadingLinear/>}
            <Content isIosApp={isIosApp} isLoading={!infoList}>
              <CityInfoContainer>
                {infoList
                  ? (
                    <>
                      {infoList?.map((item, index) => {
                        return (
                          <CardInfo
                            onClick={() => dispatch(handleCityInfoClick(item, history))}
                            key={index}
                            color={item?.color}
                          >
                            <CardTitle>{item?.title}</CardTitle>
                            <CardDescription>{item?.description}</CardDescription>
                          </CardInfo>
                        )
                      })}
                    </>
                    )
                  : (
                    <CityInfoSkeleton/>
                    )
                }
              </CityInfoContainer>
              <SwipeableDrawer
                disableDiscovery={true}
                disableSwipeToOpen={true}
                sx={{
                  '& .MuiDrawer-paper': {
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '13px 16px 56px 16px',
                    backgroundColor: '#ffffff',
                    borderRadius: '10px 10px 0 0',
                    boxSizing: 'border-box',
                    maxHeight: '80vh',
                    height: '80vh'
                  },
                  zIndex: '402 !important'
                }}
                anchor="bottom"
                open={Boolean(pathname?.split('/')[3])}
                onClose={handleCloseItem}
                onOpen={() => {
                }}
              >
                <SwipeableBar/>

                {itemSelected
                  ? (
                    <>
                      <TitleContainer>
                        <TitleDrawer title={itemSelected?.title}/>
                        {(isIosApp && navigator?.canShare) || !isIosApp &&
                          <ShareSmallButton
                            eventName={'share_city_info'}
                            eventParams={{
                              user_id: auth?.uid,
                              os: 'web',
                              user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                              user_gender: userData ? userData?.gender : null,
                              user_lat: userPosition ? userPosition.lat : null,
                              user_lng: userPosition ? userPosition.lng : null,
                              city_id: cityConfig?.city_id,
                              id: itemSelected?.id,
                              title: itemSelected?.title
                            }}
                            params={
                              {
                                link: `${process.env.REACT_APP_HOST}/city_info/${params?.city}/${itemSelected?.id}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share_city_info`,
                                title: `Información de Ualabee - ${itemSelected?.title}`,
                                description: itemSelected?.description
                              }}
                            shareType={'share'}
                            shareText={t('share.title')}
                            iconColor={'#222831'}
                          />
                        }
                      </TitleContainer>
                      <ContentContainer
                        dangerouslySetInnerHTML={{ __html: itemSelected?.content }}
                      />
                    </>
                    )
                  : (
                    <InfoItemSkeleton/>
                    )
                }
              </SwipeableDrawer>
            </Content>
            <div>
              <NavBar/>
            </div>
            <CountriesDrawer/>
            <CitiesDrawer/>
          </MobileCityInfo>
          )
        : (<Home/>
          )
      }
      <SendToPhoneModal/>
      <ShareModal/>
    </Layout>
  )
}

export default CityInfo
