import React from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import BikeStationData from '../../uicomponents/BikeStationData'
import Modal from '@mui/material/Modal'
import { Box, useMediaQuery } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useDispatch, useSelector } from 'react-redux'
import { setBikeStationSelected } from '../../actions/map'

export const BikeStationsDrawer = () => {
  const dispatch = useDispatch()
  const mobile = useMediaQuery('(max-width:480px)')

  const bikeStationSelected = useSelector(state => state.map.bikeStationSelected)

  return (
    <>
      {mobile
        ? <SwipeableDrawer
          disableDiscovery
          disableSwipeToOpen
          disableEnforceFocus
          open={Boolean(bikeStationSelected)}
          onClose={() => dispatch(setBikeStationSelected(null))}
          onOpen={() => console.log('bike station open')}
          anchor="bottom"
          sx={{
            borderRadius: '10px 10px 0 0',
            boxSizing: 'border-box',
            '& .MuiDrawer-paper': {
              padding: '10px 20px'
            }
          }}
        >
               <span
                 style={{
                   width: '20px',
                   height: '4px',
                   backgroundColor: 'rgba(0, 0, 0, 0.2)',
                   borderRadius: '5px'
                 }}
               ></span>
          <BikeStationData station={bikeStationSelected}/>
        </SwipeableDrawer>
        : <Modal
          open={Boolean(bikeStationSelected)}
          onClose={() => dispatch(setBikeStationSelected(null))}
          onOpen={() => console.log('bike station open')}
        >
          <Box
            sx={{
              width: '100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              padding: '20px',
              maxWidth: '500px'
            }}
          >
            <IconButton
              onClick={() => dispatch(setBikeStationSelected(null))}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '15px',
                padding: 0
              }}
            >
              <CloseRoundedIcon/>
            </IconButton>
            <BikeStationData station={bikeStationSelected}/>
          </Box>
        </Modal>
      }
    </>
  )
}
