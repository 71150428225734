import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'
import { getAuth } from 'firebase/auth'

/** getUserPlaces()
 * @description Get all places of a user
 * @returns {Promise<*[]>}
 */

export const getUserPlaces = async () => {
  const auth = getAuth()
  const user = auth.currentUser

  const places = []

  if (user?.uid) {
    const placesRef = collection(db, `/users/${user?.uid}/places/`)

    const querySnapshot = await getDocs(placesRef)

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      places.push({
        id: doc.id,
        type: 'place',
        ...doc.data()
      })
    })
  } else {
    console.log('Error getting user places. User not logged in')
  }

  return places
}
