import React from 'react'
import {
  AddButton,
  AddIcon,
  AddText,
  ButtonContainer,
  ButtonsContainer,
  Data,
  DefaultImageContainer,
  ImageContainer,
  ImageData,
  Likes,
  LikesContainer,
  LikesIcon,
  Name,
  StopDefaultContent,
  StopDefaultImage,
  StopImage,
  User,
  UserIcon
} from './styles'
import 'swiper/swiper.min.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import defaultImage from '../../../../img/stop_default_image.png'
import { useDispatch, useSelector } from 'react-redux'
import { handleClickWebToAppButton, setOpenStop, setStopImage, setStopPictures } from '../../../../actions/ui'
import { getLocalUserUid } from '../../../../db/getLocalUserUid'
import SwiperCore, { Pagination } from 'swiper/core'
import 'swiper/components/pagination/pagination.min.css'
import { UserInformation } from '../Report/styles'
import { logEvent } from '../../../../firebase/firebase-config'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PhotoLibrary } from '@mui/icons-material'

// install Swiper modules
SwiperCore.use([Pagination])

export const StopImages = ({ handleOpenPhoto }) => {
  const dispatch = useDispatch()
  const uid = getLocalUserUid()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]),
    stop_id: pathname?.split('/')[3]
  }

  const userPosition = useSelector(state => state?.user?.userPosition)
  const pictures = useSelector(state => state?.ui?.stopPictures)
  const mode = useSelector(state => state?.ui?.mode)

  const handleOpenPictures = () => {
    dispatch(setStopPictures({ enabled: true, pictures }))
  }

  const handleOpenGallery = (image, index) => {
    if (mode) {
      handleOpenPhoto()
      dispatch(setStopPictures({ enabled: false, pictures }))
      dispatch(setStopImage(image))
    } else {
      dispatch(setStopImage(image))
      dispatch(setOpenStop(true))
      dispatch(setStopPictures({ enabled: true, pictures, index }))
    }
  }

  return (
    <ImageContainer mode={mode}>
      {pictures
        ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className="mySwiper"
            navigation={true}
          >
            {pictures?.map((image, i) => {
              if (i < 3) {
                return (
                  <SwiperSlide onClick={() => handleOpenGallery(image, i)}
                    style={{ position: 'relative !important', cursor: 'pointer' }}
                    key={image?.id}>
                    <StopImage src={image?.stop_picture_uri}/>
                    <ImageData>
                      <Data>
                        <UserInformation>
                          <User>
                            <UserIcon fontSize={'small'}/>
                            <Name>{image?.user_nickname}</Name>
                          </User>
                          <LikesContainer>
                            <LikesIcon fontSize={'small'}/>
                            <Likes>
                              {image?.likes_count}
                            </Likes>
                          </LikesContainer>
                        </UserInformation>
                      </Data>
                    </ImageData>
                  </SwiperSlide>
                )
              }
            })}

            {pictures &&
                            <SwiperSlide>
                              <DefaultImageContainer>
                                <StopDefaultImage>
                                  <StopImage src={defaultImage}/>
                                </StopDefaultImage>
                                <StopDefaultContent>
                                  <div style={{
                                    padding: '20px 80px',
                                    backgroundColor: '#3434344a',
                                    height: 120,
                                    borderRadius: 8
                                  }}>
                                    <ButtonsContainer>

                                      <ButtonContainer>
                                        <AddButton
                                          sx={{
                                            width: '60px',
                                            height: '60px',
                                            border: '2px solid white'
                                          }}
                                          onClick={handleOpenPictures}
                                        >
                                          <PhotoLibrary sx={{ color: '#fff' }}/>
                                        </AddButton>
                                        <AddText>{t('stop.more_pictures')}</AddText>
                                      </ButtonContainer>
                                      <ButtonContainer>
                                        <AddButton
                                          sx={{
                                            width: '60px',
                                            height: '60px',
                                            border: '2px solid white'
                                          }}
                                          onClick={() => dispatch(handleClickWebToAppButton(true, {
                                            from: 'stop_picture_take',
                                            lat: userPosition ? userPosition?.lat : null,
                                            lng: userPosition ? userPosition?.lng : null,
                                            user_id: uid,
                                            os: 'web',
                                            city_id: urlParams?.city_id.toString(),
                                            link_to: `${process.env.REACT_APP_HOST}/stops/${urlParams?.city_id}/${urlParams?.stop_id}`
                                          }, logEvent))}
                                        >
                                          <AddIcon sx={{ color: '#fff' }}/>
                                        </AddButton>
                                        <AddText>{t('stop.take_picture')}</AddText>
                                      </ButtonContainer>
                                    </ButtonsContainer>

                                  </div>
                                </StopDefaultContent>
                              </DefaultImageContainer>
                            </SwiperSlide>
            }
          </Swiper>
          )
        : (<DefaultImageContainer>
          <StopDefaultImage>
            <StopImage src={defaultImage}/>
          </StopDefaultImage>
          <StopDefaultContent>
            <div style={{
              backgroundColor: '#3434344a',
              height: 120,
              borderRadius: 8,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <ButtonsContainer style={{ alignItems: 'center' }}>
                <ButtonContainer style={{ width: '100%' }}>
                  <AddButton
                    sx={{
                      width: '60px',
                      height: '60px',
                      border: '2px solid white'
                    }}
                    onClick={() => dispatch(handleClickWebToAppButton(true, {
                      from: 'stop_picture_take',
                      lat: userPosition ? userPosition?.lat : null,
                      lng: userPosition ? userPosition?.lng : null,
                      user_id: uid,
                      os: 'web',
                      city_id: urlParams?.city_id.toString(),
                      link_to: `${process.env.REACT_APP_HOST}/stops/${urlParams?.city_id}/${urlParams?.stop_id}`
                    }, logEvent))}
                  >
                    <AddIcon sx={{ color: '#fff' }}/>
                  </AddButton>
                  <AddText>{t('stop.take_picture')}</AddText>
                </ButtonContainer>
              </ButtonsContainer>
            </div>
          </StopDefaultContent>
        </DefaultImageContainer>
          )
      }
    </ImageContainer>
  )
}
