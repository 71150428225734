import React from 'react'
import {
  DepartureBoardRounded,
  FastfoodRounded,
  FavoriteRounded,
  FitnessCenterRounded,
  HistoryRounded,
  HomeRounded,
  LocalCafeRounded,
  LocalHospitalRounded,
  LocalHotelRounded,
  LocalMallRounded,
  LocalParkingRounded,
  LocalPharmacyRounded,
  LocationCityRounded,
  LocationOnRounded,
  MovieRounded,
  MuseumRounded,
  NatureRounded,
  SchoolRounded,
  SportsSoccerRounded,
  StorefrontRounded,
  WorkRounded
} from '@mui/icons-material'
import moment from 'moment'
import maplibregl from 'maplibre-gl'
import getRelativeLuminance from 'get-relative-luminance/src'

export const detectIos = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

/** getDistanceFromLatLonInKm()
 * This function calculates distances between two points
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @returns {number}
 */

export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1) // deg2rad below
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c // Distance in km
}

export const pickTextColorBasedOnBgColor = (bgColor, lightColor, darkColor) => {
  const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
    ? darkColor
    : lightColor
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

export const getUrlRealTimeByCity = (id, params) => {
  switch (id) {
    case 1:
      return [`https://mrb.red-bus.com.ar/rest/horariosEstimados/${params.route_id}/${params.stop_code}`]
    case 30:
      return [`https://api.montevideo.gub.uy/transporteRest/siguientesParada/${params.stop_code}`]
    case 12:
      return [`https://ws.rosario.gob.ar/ubicaciones/public/cuandollega?parada=${params.stop_code}`]
    case 29:
      return [`https://mendotran.oba.visionblo.com/oba_api/api/where/arrivals-and-departures-for-stop/${params.stop_code}.json?platform=web&v=&minutesBefore=0&minutesAfter=65&version=1.0`]
    case 21:
      return [`https://apitransporte.buenosaires.gob.ar/colectivos/oba/arrivals-and-departures-for-stop/82_${params.stop_code}?minutesAfter=30&minutesBefore=0`, 'https://apitransporte.buenosaires.gob.ar/subtes/forecastGTFS?']
    case 22:
      return [`${process.env.REACT_APP_API_FEED}/customImpl/getEtaSantiago/${params.stop_code}/${params.route_id}`]
    default:
      return []
  }
}

export const handleClickRefillMarker = (marker, logEvent, userPosition, uid, cityConfig) => {
  // TODO: move this function to Redux action in map.js
  // TODO: set userPosition, logEvent and ui in Redux

  const eventParams = {
    address: marker.address,
    refill_spot_lat: marker.latitude,
    refill_spot_lng: marker.longitude,
    os: 'web',
    lat: userPosition ? userPosition.lat : null,
    lng: userPosition ? userPosition.lng : null,
    city_id: cityConfig?.city_id.toString(),
    user_id: uid
  }

  // Send events to analytics
  logEvent('recharge_kiosk_marker_click', eventParams)
}

/**
 * It returns an object with an icon and a color based on the categoryId
 * @param categoryId - The category of the place.
 * @returns An object with two properties: icon and color.
 */
export const getPlacesIcon = (categoryId) => {
  switch (categoryId) {
    case 'residential':
    case 'social_facility':
    case 'building':
    case 'place_of_worship':
    case 'government':
    case 'townhall':
    case 'house_number': {
      return { icon: <LocationCityRounded style={{ color: '#00a7bc' }}/>, color: '#d7f3f63a' }
    }
    case 'park': {
      return { icon: <NatureRounded style={{ color: '#99ca0d' }}/>, color: '#99ca0d3a' }
    }
    case 'pharmacy': {
      return { icon: <LocalPharmacyRounded style={{ color: '#F06292' }}/>, color: '#F062923a' }
    }
    case 'stadium':
    case 'recreation_ground':
    case 'sports_centre': {
      return { icon: <SportsSoccerRounded style={{ color: '#fece60' }}/>, color: '#fece603a' }
    }
    case 'fitness_centre': {
      return { icon: <FitnessCenterRounded style={{ color: '#fece60' }}/>, color: '#fece603a' }
    }
    case 'kiosk': {
      return { icon: <StorefrontRounded style={{ color: '#fece60' }}/>, color: '#fece603a' }
    }
    case 'school':
    case 'university': {
      return { icon: <SchoolRounded style={{ color: '#fece60' }}/>, color: '#fece603a' }
    }
    case 'cinema': {
      return { icon: <MovieRounded style={{ color: '#ff5252' }}/>, color: '#ff52523a' }
    }
    case 'confectionery':
    case 'cafe': {
      return { icon: <LocalCafeRounded style={{ color: '#fa7b17' }}/>, color: '#fa7b173a' }
    }
    case 'bus_stop': {
      return { icon: <DepartureBoardRounded style={{ color: '#fa7b17' }}/>, color: '#fa7b173a' }
    }
    case 'hospital': {
      return { icon: <LocalHospitalRounded style={{ color: '#F06292' }}/>, color: '#F062923a' }
    }
    case 'hotel': {
      return { icon: <LocalHotelRounded style={{ color: '#00a7bc' }}/>, color: '#d7f3f63a' }
    }
    case 'fast_food':
    case 'restaurant': {
      return { icon: <FastfoodRounded style={{ color: '#fece60' }}/>, color: '#fece603a' }
    }
    case 'museum': {
      return { icon: <MuseumRounded style={{ color: '#fece60' }}/>, color: '#fece603a' }
    }
    case 'parking': {
      return { icon: <LocalParkingRounded style={{ color: '#ff99cc00' }}/>, color: '#ff99cc3a' }
    }
    case 'mall':
    case 'retail':
    case 'furniture':
    case 'commercial': {
      return { icon: <LocalMallRounded style={{ color: '#F06292' }}/>, color: '#F062923a' }
    }
    case 'saved_place_home': {
      return { icon: <HomeRounded style={{ color: '#ff99cc00' }}/>, color: '#ff99cc3a' }
    }
    case 'saved_place_work': {
      return { icon: <WorkRounded style={{ color: '#00a7bc' }}/>, color: '#d7f3f63a' }
    }
    case 'saved_place': {
      return { icon: <FavoriteRounded style={{ color: '#F06292' }}/>, color: '#F062923a' }
    }
    case 'recent_place': {
      return { icon: <HistoryRounded style={{ color: '#00a7bc' }}/>, color: '#d7f3f63a' }
    }
    default: {
      return { icon: <LocationOnRounded style={{ color: '#00a7bc' }}/>, color: '#d7f3f63a' }
    }
  }
}

/**
 * It takes a point, a radio, a list of stations, a filter condition and a unique result flag and
 * returns the closest station to the point that meets the filter condition
 * @param point - The point from which you want to find the closest bike station.
 * @param radio - The radius in which you want to search for the closest bike station.
 * @param stations - the array of stations
 * @param availableConditionFilter - This is the name of the property that you want to filter by. For
 * example, if you want to filter by the number of available bikes, you would pass in 'availableBikes'.
 * @param [uniqueResult=true] - if true, it will return the closest bike station, if false, it will
 * return an array with all the bike stations within the radio.
 */
export const getClosetsBikeStation = (point, radio, stations, availableConditionFilter, uniqueResult = true) => {
  const bounds = new maplibregl.LngLat(point.lng, point.lat).toBounds(radio)
  const filteredStations = stations.filter(station => bounds.contains(new maplibregl.LngLat(station.longitude, station.latitude)) && station[availableConditionFilter] > 0)

  if (filteredStations.length > 0) {
    const bikeStationsDistances = filteredStations?.map(station => {
      return {
        ...station,
        distance: getDistanceFromLatLonInKm(Number(point.lat), Number(point.lng), station.latitude, station.longitude)
      }
    })

    return uniqueResult ? bikeStationsDistances?.reduce((prev, current) => (prev.distance < current.distance) ? prev : current) : bikeStationsDistances
  } else {
    return null
  }
}

/**
 * It takes an array of objects, each with a time property, and returns the index of the object with
 * the closest time to the current time
 * @param times - An array of objects that contain a time property.
 * @returns The index of the closest time to the current time
 */
export const getClosestTime = (times) => {
  const currentTime = moment()

  const futureObjects = times.filter(object => {
    // Convert the object's time to a moment object
    const objectMoment = moment(object.stop_times[0].arrival_time, 'HH:mm:ss')

    return objectMoment.isAfter(currentTime)
  })

  // Sort the objects by time
  futureObjects.sort((a, b) => {
    // Convert the object's time to moment objects
    const aMoment = moment(a.time, 'HH:mm:ss')
    const bMoment = moment(b.time, 'HH:mm:ss')

    // Compare the moment objects
    return aMoment.diff(currentTime) - bMoment.diff(currentTime)
  })

  // Find the closest object to the target time
  const closestObject = futureObjects[0]

  return times.indexOf(closestObject)
}

/**
 * Calculate contrast ratio
 */
const getContrastRatio = (color1, color2) => {
  const l1 = getRelativeLuminance(color1)
  const l2 = getRelativeLuminance(color2)

  const lightest = Math.max(l1, l2)
  const darkest = Math.min(l1, l2)

  const contrast = (lightest + 0.05) / (darkest + 0.05)

  return Math.floor(contrast * 100) / 100
}

/**
 * Calculate wich color in a array of colors gives the highest contrast to another color.
 * @param {String} background string A string that can be parsed as a CSS color
 * @param {Array} colors array An array of strings that can be parsed to CSS colors
 * @return string The color from colors that has the highest contrast to background
 */
export const getBestContrastColor = (background, colors = []) => (
  colors.reduce((acc, color) => (
    getContrastRatio(background, color) > getContrastRatio(background, acc)
      ? color
      : acc
  ))
)
