import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'
import {
  Address,
  AddressContainer,
  AlertContainer,
  AlertImageContainer,
  AlertInfoContainer,
  Description,
  IconImg,
  LinesScrollContainer,
  ShareButtonsContainer,
  SourceContainer,
  Title,
  TitleContainer,
  Username
} from './styles'
import { setCenter, setZoom } from '../../actions/map'
import { setLocalCurrentCenter } from '../../db/setLocalCurrentCenter'
import { setLocalCurrentZoom } from '../../db/setLocalCurrentZoom'
import {
  enabledAlertsAndNewsComponent,
  setCurrentCityConfig,
  setOpenAlertInformation,
  setUserReportDrawerRef
} from '../../actions/ui'
import ShareSmallButton from '../../uicomponents/ShareSmallButton'
import { getUserReportById } from '../../db/getUserReportById'
import { logEvent } from '../../firebase/firebase-config'
import { getCityConfig } from '../../db/getCityConfig'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme/theme'
import { Chip, SwipeableDrawer, useMediaQuery } from '@mui/material'
import { LinkRounded, LocationOnRounded } from '@mui/icons-material'
import maplibregl from 'maplibre-gl'

const Alert = ({ map }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const drawerRef = useRef(null)

  const mobile = useMediaQuery('(max-width:480px)')

  const language = window.navigator.language.toLowerCase()
  const uid = sessionStorage.getItem('uid')

  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector(state => state?.user?.auth)
  const [alertSelected, setAlertSelected] = useState()
  const [eventParams] = useState()
  const { alertsAndNews, alertInformation } = useSelector(state => state?.ui?.components)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const alertTypes = useSelector(state => state?.ui?.alertTypes)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userReportDrawerRef = useSelector(state => state?.ui?.userReportDrawerRef)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  useEffect(() => {
    dispatch(setUserReportDrawerRef(drawerRef))
    setAlertSelected(alertsAndNews?.current)
  }, [alertsAndNews])

  const id = pathname.split('/')[2]

  const urlParams = {
    pathname: pathname?.split('/')[1],
    alert_id: Number(pathname?.split('/')[2])
  }

  useEffect(() => {
    if (urlParams?.pathname === 'user_reports' && urlParams?.alert_id && userReportDrawerRef && !alertsAndNews?.current) {
      getUserReportById(id)
        .then((response) => {
          const objAlert = {
            id: response?.result?.id,
            cityId: response?.result?.city_id,
            typeName: language.includes('es')
              ? alertTypes?.filter((type) => type.id === response?.result?.report_type)[0]?.name_es
              : alertTypes?.filter((type) => type.id === response?.result?.report_type)[0]?.name,
            address: response?.result?.address,
            userNickname: response?.result?.user_nickname,
            date: response?.result?.created_at,
            icon: response?.result?.icon_url,
            title: response?.result?.report_text,
            affectedLines: response?.result?.affected_routes_json_array.map((line) => {
              return { name: line.route_short_name, color: line.color }
            }),
            isAlert: true,
            lat: response?.result?.lat,
            lng: response?.result?.lng,
            validate: response?.result?.status_id === 1,
            link: '',
            content: '',
            pictureUrl: response?.result?.report_picture_url,
            reportType: response?.result?.report_type
          }

          if (cityConfig?.city_id !== Number(objAlert.cityId)) {
            getCityConfig(Number(objAlert.cityId))
              .then(() => {
                localStorage.setItem(
                  'cityConfig',
                  JSON.stringify(response.result)
                )
                dispatch(setCurrentCityConfig(response?.result))
              })
          }

          dispatch(enabledAlertsAndNewsComponent({ enabled: false, current: objAlert }))
          dispatch(setOpenAlertInformation(true))

          // set data in LocalStorage
          setLocalCurrentZoom(20)
          setLocalCurrentCenter([objAlert.lat, objAlert.lng])

          // set data in Redux
          dispatch(setZoom(20))
          dispatch(setCenter([objAlert.lat, objAlert.lng]))

          map.flyTo({
            center: [objAlert.lng, objAlert.lat],
            speed: 0.5,
            padding: 18
          })

          const bounds = new maplibregl.LngLatBounds(new maplibregl.LngLat(to.latLng.lng, to.latLng.lat))

          map?.fitBounds(bounds, {
            padding: {
              bottom: userReportDrawerRef?.current?.offsetHeight
            }
          })
        })
    }
  }, [map, userReportDrawerRef])

  const handleClose = () => {
    if (alertSelected?.isAlert) {
      if (mobile) {
        history.push('/')
      } else {
        history.goBack()
      }
    } else {
      history.goBack()
    }
    dispatch(setOpenAlertInformation(!alertInformation))
  }

  const handleClickLink = (alert) => {
    logEvent('alert_link_clicked', {
      alert_id: alert.id,
      marker_lat: alert.lat,
      marker_lng: alert.lng,
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      user_id: uid,
      os: 'web',
      city_id: cityConfig?.city_id.toString(),
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null, // String
      from: null
    })
  }

  const handleOpen = () => {
    dispatch(enabledAlertsAndNewsComponent({ enabled: true }))
  }

  return (
    <SwipeableDrawer
      onClose={handleClose}
      disableDiscovery={true}
      disableSwipeToOpen={true}
      sx={{
        '& .MuiDrawer-paper': {
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0',
          backgroundColor: '#ffffff',
          borderRadius: '14px 14px 0 0',
          boxSizing: 'border-box'
        },
        '& .MuiDrawer-modal': {
          zIndex: '403 !important'
        }
      }}
      anchor="bottom"
      open={alertInformation}
      onOpen={handleOpen}
    >
      <AlertContainer ref={drawerRef}>
        <AlertImageContainer
          showShadow={alertSelected?.isAlert}
          backgroundImg={alertSelected?.pictureUrl || cityConfig?.map?.landscape_picture}
        >
          {(isIosApp && navigator?.canShare) || !isIosApp &&
            <ShareButtonsContainer>
              <ShareSmallButton
                eventName={alertSelected?.isAlert ? 'share_user_report' : 'alert_share_clicked'}
                eventParams={eventParams}
                params={
                  {
                    link: `${process.env.REACT_APP_HOST}/${alertSelected?.isAlert ? 'user_reports' : 'news'}/${alertSelected?.id}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=${alertSelected?.isAlert ? 'share_user_report' : 'alert_share_clicked'}`,
                    title: `${alertSelected?.isAlert ? `Reporte de ${alertSelected?.typeName} - ${alertSelected?.address}` : `Alerta de ${alertSelected?.typeName}`}`,
                    description: `${alertSelected?.isAlert ? `Reportado por ${alertSelected?.userNickname}` : alertSelected?.content}`,
                    picture: `${alertSelected?.isAlert ? alertSelected?.icon : alertSelected?.pictureUrl}`
                  }}
                shareText={t('share.title')}
                shareType={'share'}
                bgButtonColor={'rgba(251, 251, 251, 0.5)'}
                iconColor={'#222831'}
                buttonWidth={'24px'}
                buttonHeight={'24px'}
                iconWidth={'10px'}
              />

            </ShareButtonsContainer>
          }
          {alertSelected?.isAlert && (
            <AddressContainer>
              <LocationOnRounded
                sx={{ color: '#ffffff', marginRight: '6px' }}
              />
              <Address>{alertSelected?.address}</Address>
            </AddressContainer>
          )}
        </AlertImageContainer>
        <AlertInfoContainer>
          <TitleContainer>
            <IconImg src={alertSelected?.icon}/>
            <Title>
              {alertSelected?.isAlert
                ? alertSelected?.typeName
                : alertSelected?.title
              }
            </Title>
          </TitleContainer>
          <Description>
            {alertSelected?.isAlert
              ? alertSelected?.title
              : alertSelected?.content
            }
          </Description>
          {!alertSelected?.isAlert && alertSelected?.link &&
            <Chip
              component="a"
              href={alertSelected?.link}
              target="blank"
              icon={<LinkRounded style={{ color: '#ffffff' }}/>}
              clickable
              label={t('news.see')}
              style={{
                fontFamily: 'Visby Round',
                color: '#ffffff',
                margin: '0 10px 10px 0',
                backgroundColor: theme.palette.primary.main
              }}
              onClick={() => handleClickLink(alertSelected)}
            />
          }
          {alertSelected?.isAlert && alertSelected?.affectedLines?.length !== 0 &&
            <>
              <h2 style={{
                fontFamily: 'Visby Round',
                color: theme.text.secondary.main,
                fontSize: 18
              }}>{t('news.alerts.possible_affected_routes')}</h2>
              <LinesScrollContainer>
                {alertSelected?.affectedLines?.map((line) => {
                  return (
                    <Chip
                      clickable
                      key={line.name}
                      label={line.name}
                      style={{
                        fontFamily: 'Visby Round',
                        color: '#ffffff',
                        marginRight: '10px',
                        backgroundColor: `#${line?.color}`
                      }}
                    />
                  )
                })}
              </LinesScrollContainer>
            </>
          }
          <SourceContainer>
            {t('news.creation_date', { prop1: moment(alertSelected?.date).locale(i18n.language).fromNow() })}
            <Username>{alertSelected?.userNickname}</Username>
          </SourceContainer>
        </AlertInfoContainer>
      </AlertContainer>
    </SwipeableDrawer>
  )
}

export default Alert
