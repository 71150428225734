import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import SwipeableViews from 'react-swipeable-views'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { sendFeedback } from '../../db/sendFeedback'
import LoadingButton from '@mui/lab/LoadingButton'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme/theme'

const Alert = React.forwardRef(function Alert (props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const FeedbackForm = ({ question, handleClose, setSuccessModalOpen, feedbackObj, sendEvent, screenEventParams = {} }) => {
  const { t } = useTranslation()

  const [index, setIndex] = useState(0)
  const [value, setValue] = useState()
  const [feedbackText, setFeedbackText] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleChange = (event) => {
    const optionObj = question.options.find(option => option.option.option_id === Number(event.target.value))
    setValue(optionObj)
    if (!optionObj.action_id) {
      setCanSubmit(true)
    } else if (optionObj.action_id === 'TEXT_BOX') {
      setCanSubmit(false)
      setIndex(1)
    }
  }

  const handleBack = () => {
    setIndex(0)
    setValue()
    setCanSubmit(false)
    setFeedbackText('')
  }

  const handleTextChange = (event) => {
    setFeedbackText(event.target.value)
    if (event.target.value.length > 0) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }

  const handleSubmit = async () => {
    setCanSubmit(false)
    setLoading(true)

    const feedback = {
      option_id: value.option.option_id,
      feedback: feedbackText || null
    }

    sendFeedback(question.screen, { ...feedbackObj, ...feedback }).then((response) => {
      if (response) {
        setLoading(false)
        setIndex(0)
        setValue()
        setCanSubmit(false)
        setFeedbackText('')
        handleClose(false)
        setSuccessModalOpen(true)
        setTimeout(() => {
          setSuccessModalOpen(false)
        }, 3000)
      } else {
        setError(true)
        setLoading(false)
        setIndex(0)
        setValue()
        setCanSubmit(false)
        setFeedbackText('')
      }
    })
    const eventParams = {
      selectedOptionText: value.option.option,
      feedback: feedbackText || null,
      selectedOptionId: value.option.option_id,
      ...screenEventParams
    }
    sendEvent('feedback_sent', eventParams)
  }
  return (
        <>
            <Grid
                component='div'
                container
                sx={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  width: '100%',
                  flexDirection: 'column'
                }}
            >
                <Grid
                    component='div'
                    container
                    sx={{
                      display: 'flex',
                      boxSizing: 'border-box',
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      minHeight: '60px',
                      padding: '10px 0'
                    }}
                >
                    <Grid
                        item={true}
                        xs={2}
                    >
                        {index > 0 && (
                            <IconButton
                                onClick={() => handleBack()}
                            >
                                <ArrowBackRoundedIcon/>
                            </IconButton>
                        )}
                    </Grid>
                    <Grid
                        item={true}
                        xs={8}
                        sx={{
                          boxSizing: 'border-box'
                        }}
                    >
                        <Typography

                            variant='h6'
                            sx={{
                              fontSize: '18px',
                              fontWeight: 'bold',
                              textAlign: 'center',
                              margin: 0
                            }}
                        >{question.question}</Typography>
                    </Grid>
                </Grid>
                <Divider/>
                <SwipeableViews
                    index={index}
                    disabled={true}
                >
                    <FormControl
                        sx={{
                          padding: '20px 10px',
                          width: '100%',
                          boxSizing: 'border-box'
                        }}
                    >
                        <RadioGroup
                            aria-label='feedback'
                            name='feedback'
                            defaultValue={0}
                            value={value ? value?.option?.option_id : 0}
                            sx={{
                              gap: '5px'
                            }}
                            onChange={handleChange}
                        >
                            {question.options.map((option, index) => {
                              return (
                                    <FormControlLabel
                                        disabled={loading}
                                        sx={{
                                          borderBottom: `${index === question.options.length - 1 ? 'none' : '1px solid rgba(0,0,0,0.1)'}`,
                                          paddingBottom: `${index === question.options.length - 1 ? '0' : '5px'}`
                                        }}
                                        key={option.option.option_id}
                                        value={option.option.option_id}
                                        control={<Radio
                                            disabled={loading}
                                            sx={{
                                              color: theme.palette.primary.main,
                                              '&.Mui-checked': {
                                                color: theme.palette.primary.main
                                              }
                                            }}
                                        />}
                                        label={option.option.option}
                                    />
                              )
                            })}
                        </RadioGroup>
                    </FormControl>
                    <Box
                        sx={{
                          padding: '20px 10px',
                          width: '100%',
                          boxSizing: 'border-box'
                        }}
                    >
                        <TextField
                            disabled={loading}
                            onChange={handleTextChange}
                            multiline
                            rows={8}
                            variant="standard"
                            placeholder='Enter your feedback here'
                            value={feedbackText}
                            sx={{
                              width: '100%',
                              '& .MuiInput-underline:after': {
                                borderBottomColor: theme.palette.primary.main
                              }
                            }}
                        />
                    </Box>
                </SwipeableViews>

                <Grid component='div' container sx={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                    <Grid
                        item={true}
                        component='div'
                        xs={10}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                    >
                        <LoadingButton
                            loading={loading}
                            disabled={!canSubmit}
                            onClick={handleSubmit}
                            sx={{
                              width: '100%',
                              maxWidth: '300px',
                              height: '45px',
                              borderRadius: '30px',
                              textTransform: 'none',
                              backgroundColor: theme.palette.primary.main,
                              color: '#fff',
                              '&:hover': {
                                backgroundColor: theme.palette.primary.main
                              },
                              '&.Mui-disabled': {
                                backgroundColor: theme.palette.secondary.main
                              },
                              '&.MuiLoadingButton-loading': {
                                backgroundColor: `${theme.palette.secondary.main} !important`
                              },
                              fontSize: '16px'
                            }}
                        >
                            {t('feedback_form.button_text')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={error}
                autoHideDuration={3000}
                onClose={() => setError(false)}
            >
                <Alert
                    onClose={() => setError(false)}
                    severity="error"
                    sx={{
                      fontSize: '12px',
                      backgroundColor: 'rgb(255,85,85)',
                      borderRadius: '16px'
                    }}
                >
                    {t('feedback_form.general_error_message')}
                </Alert>
            </Snackbar>
        </>
  )
}

export default FeedbackForm
