import React, { useEffect, useState } from 'react'
import { Information, ResultsContainer, TripResultsContainer } from './styles'
import { Filters, TripPreview } from '../../DestinationPreview'
import { Results } from '../../../uicomponents/desktop/tripResults/Results'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ShareLargeButton from '../../../uicomponents/ShareLargeButton'
import { ShareButtonsContainer } from '../Home/styles'
import useQuery from '../../../hooks/useQuery'
import { useTripDirections } from '../../../hooks/useTripDirections'
import {
  getVehiclePositions,
  setAutocompleteOrientation,
  setShowFilters,
  setToPlacesSelected,
  setTripDetails,
  setTripSelected,
  setTripSelectedVehicles
} from '../../../actions/ui'
import FeedbackButton from '../../../uicomponents/FeedbackButton'
import { useTripSearch } from '../../../hooks/useTripSearch'
import { theme } from '../../../theme/theme'
import { getTripDetails } from '../../../db/getTripDetails'
import { Map } from '../../SearchResults'
import { useMediaQuery } from '@mui/material'

export const TripResults = (props) => {
  const query = useQuery()
  const { t } = useTranslation()
  const history = useHistory()
  const { arriveBy } = useTripSearch()
  const dispatch = useDispatch()

  const {
    handleLocationClick,
    handleExchangeTravelDirections,
    handleChangeTravelDirection
  } = useTripDirections()

  const mobile = useMediaQuery('(max-width:480px)')

  const searchFilters = useSelector(state => state?.user?.searchFilters)
  const from = useSelector(state => state?.ui?.places?.from)
  const to = useSelector(state => state?.ui?.places?.to)
  const userData = useSelector(state => state?.user?.userData)
  const auth = useSelector(state => state?.user?.auth)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const timestamp = useSelector(state => state?.ui?.dataTimeValue)
  const tripDetails = useSelector(state => state?.ui?.tripDetails)
  const tripSelectedVehicles = useSelector(state => state?.ui?.tripSelectedVehicles)

  const [searchResultsFeedbackObj, setSearchResultsFeedbackObj] = useState()
  const [searchDetailsFeedbackObj, setSearchDetailsFeedbackObj] = useState()
  const [openFilters, setOpenFilters] = useState(false)
  const [showBar, setShowBar] = useState(true)
  const [currentVehicles, setCurrentVehicles] = useState([])
  const [pathLegs, setPathLegs] = useState([])

  useEffect(() => {
    // TODO: set self_search
    const urlParams = {
      lat_from: from?.latLng?.lat,
      lng_from: from?.latLng?.lng,
      lat_to: to?.latLng?.lat,
      lng_to: to?.latLng?.lng,
      radius: searchFilters?.radius,
      self_search: false,
      transport_types: searchFilters?.transportation_types?.filter(t => t.checked).map(t => t.transport_type_id).toString(),
      locale: (window?.navigator?.language || navigator?.browserLanguage).substring(0, 2),
      arrive_by: arriveBy,
      timestamp: timestamp || null,
      transfer: query.get('transfer') === 'null' ? null : query.get('transfer') === 'true'
    }

    setSearchResultsFeedbackObj({
      city_id: parseInt(query.get('ciudad_id')),
      question_id: 2,
      search: {
        ...urlParams
      }
    })
  }, [from, to, searchFilters, arriveBy, timestamp, query.get('transfer')])

  useEffect(() => {
    if (tripSelected) {
      const itinerary = {
        duration: tripSelected.duration,
        start_time: tripSelected.startTime,
        end_time: tripSelected.endTime,
        walk_time: tripSelected.walkTime,
        transit_time: tripSelected.transitTime,
        waiting_time: tripSelected.waitingTime,
        walk_distance: tripSelected.walkDistance,
        transfers: tripSelected.transfers,
        fare_cents: tripSelected?.fare?.cents || null,
        fare_currency: tripSelected?.fare?.currency || null,
        fare_fraction_digits: tripSelected?.fare?.default_fraction_digits || null,
        legs: tripSelected.legs.map(leg => ({
          route_id: leg.routeId || null,
          route_type: leg.routeType || null,
          agency_id: leg.agencyId || null,
          shape_id: leg.shapeId || null,
          trip_id: leg.tripId || null,
          to_stop_id: leg.to.stopId || null,
          start_time: leg.startTime,
          end_time: leg.endTime,
          departure_delay: leg.departureDelay,
          arrival_delay: leg.arrivalDelay,
          realtime: leg.realTime,
          realtime_arrival: leg.realTimeArrival || null,
          distance: leg.distance,
          mode: leg.mode,
          from_name: leg.from.name,
          from_stop_id: leg.from.stopId || null,
          from_lon: leg.from.lon,
          from_lat: leg.from.lat,
          from_arrival: leg.from.arrival || null,
          from_departure: leg.from.departure,
          from_vertex_type: leg.from.vertexType,
          to_name: leg.to.name,
          to_lon: leg.to.lon,
          to_lat: leg.to.lat,
          to_arrival: leg.to.arrival,
          to_vertex_type: leg.to.vertexType,
          duration: leg.duration || null,
          points: leg.legGeometry?.points
        }))
      }

      setSearchDetailsFeedbackObj({
        city_id: cityConfig?.city_id,
        question_id: 1,
        itinerary
      })

      const legsNoWalk = tripSelected?.legs?.filter(leg => leg?.mode !== 'WALK')
      const legsNoWalkShapes = legsNoWalk?.map(leg => leg?.shapeId)

      if (!tripSelected || !tripSelected?.legs?.some(leg => leg.mode === 'BICYCLE' || leg.mode === 'CAR')) {
        setPathLegs([])

        getTripDetails(cityConfig?.city_id, legsNoWalkShapes)
          .then((response) => {
            const tripDetails = response?.result
            dispatch(setTripDetails(tripDetails))

            const legsNoWalk = tripSelected.legs.filter(leg => leg.mode !== 'WALK')

            const pathLegs = legsNoWalk
              ?.map(leg => {
                // the detail of the trip that coincides with the shape id of the current section is obtained
                const detail = tripDetails?.find(detail => detail.shape_id === leg.shapeId)

                const routeStops = [...detail?.stops.slice(leg.from.stopIndex, leg.to.stopIndex)]
                const stops = leg.mode !== 'WALK' && leg.mode !== 'CAR' ? detail?.stops || [] : detail?.stops?.length >= 1 ? detail?.stops.slice(leg.from.stopIndex, leg.to.stopIndex) : []
                leg.stops = stops.map(stop => {
                  return {
                    ...stop,
                    isOnRoute: routeStops.some(routeStop => routeStop.stop_lat === stop.stop_lat && routeStop.stop_lon === stop.stop_lon)
                  }
                })
                return leg
              })

            setPathLegs(pathLegs)
          })
          .catch(e => console.log(e))
      }
    } else {
      setPathLegs([])
    }
  }, [tripSelected])

  useEffect(() => {
    if (!tripSelected || tripDetails?.length === 0) return
    const interval = setInterval(() => getVehicles(tripSelected, tripDetails), 30000)

    return () => {
      clearInterval(interval)
    }
  }, [tripDetails])

  useEffect(() => {
    if (!currentVehicles || !tripSelectedVehicles) {
      return setCurrentVehicles([])
    }
    setCurrentVehicles([...currentVehicles, ...tripSelectedVehicles])
  }, [tripSelectedVehicles])

  /** getVehicles()
   * Obtains current vehicles with realtime
   */
  const getVehicles = (tripSelected, tripDetails) => {
    const routesSelected = tripSelected?.legs?.filter(leg => leg?.mode !== 'WALK' && leg?.mode !== 'BICYCLE' && leg?.mode !== 'WALK')
    setCurrentVehicles([])
    dispatch(setTripSelectedVehicles([]))
    // Gets current vehicle positions by every route
    routesSelected.forEach(routeSelected => {
      const directionId = tripDetails?.filter(item => item?.route_id === routeSelected.routeId)[0]?.direction_id
      dispatch(getVehiclePositions(routeSelected.routeId, directionId, routeSelected?.routeType, routeSelected?.routeShortName || routeSelected?.routeLongName))
    })
  }

  const handleBack = () => {
    dispatch(setAutocompleteOrientation('to'))
    dispatch(setTripSelected(null))
    history.goBack()
    dispatch(setShowFilters(false))
  }

  const handleClose = () => {
    dispatch(setShowFilters(false))
    dispatch(setAutocompleteOrientation('to'))
    dispatch(setTripSelected(null))

    dispatch(setToPlacesSelected({
      name: t('hint_location_B'),
      latLng: { lat: cityConfig.map.destination_marker_lat, lng: cityConfig.map.destination_marker_lng }
    }))

    history.push('/')
  }

  return (
    <TripResultsContainer>
      <ShareButtonsContainer>
        <ShareLargeButton
          eventName={'share'}
          eventParams={{
            user_id: auth?.uid,
            os: 'web',
            user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
            user_gender: userData ? userData?.gender : null,
            user_lat: userPosition ? userPosition.lat : null,
            user_lng: userPosition ? userPosition.lng : null,
            city_id: cityConfig?.city_id,
            origin_latitude: query.get('latd'),
            origin_longitude: query.get('lngd'),
            destination_latitude: query.get('lath'),
            destination_longitude: query.get('lngh'),
            radius: query.get('radio'),
            item_id: 'trip',
            content_type: 'text'

          }}
          params={
            {
              link: `${process.env.REACT_APP_HOST}/trip_share?ciudad_id=${query.get('ciudad_id')}&latd=${query.get('latd')}&lngd=${query.get('lngd')}&lath=${query.get('lath')}&lngh=${query.get('lngh')}&radio=${query.get('radio')}&invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share`,
              title: userData?.display_name ? t('share.user_has_sent_you_a_search', { prop1: userData?.display_name }) : t('share.they_sent_you_a_quest'),
              description: t('share.visualize_this_trip')
            }}
          shareText={t('share.title')}
          shareType={'share'}
        />
        <ShareLargeButton
          eventName={'send_to_phone'}
          eventParams={{
            user_id: auth?.uid,
            os: 'web',
            user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
            user_gender: userData ? userData?.gender : null,
            user_lat: userPosition ? userPosition.lat : null,
            user_lng: userPosition ? userPosition.lng : null,
            city_id: cityConfig?.city_id,
            origin_latitude: query.get('latd'),
            origin_longitude: query.get('lngd'),
            destination_latitude: query.get('lath'),
            destination_longitude: query.get('lngh'),
            radius: query.get('radio'),
            item_id: 'trip',
            content_type: 'text',
            from: 'search'
          }}
          params={
            {
              link: `${process.env.REACT_APP_HOST}/trip_share?ciudad_id=${query.get('ciudad_id')}&latd=${query.get('latd')}&lngd=${query.get('lngd')}&lath=${query.get('lath')}&lngh=${query.get('lngh')}&radio=${query.get('radio')}&invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share`,
              title: userData?.display_name ? t('share.user_has_sent_you_a_search', { prop1: userData?.display_name }) : t('share.they_sent_you_a_quest'),
              description: t('share.visualize_this_trip')
            }}
          notificationBody={{
            title: t('share.visualize_this_search'),
            content: t('share.search_from_to', { prop1: from?.name, prop2: to?.name })
          }}
          shareText={t('send_to_mobile.title')}
          shareType={'send-to-mobile'}
        />
        {tripSelected &&
          <FeedbackButton
            size='medium'
            iconColor={theme.palette.primary.main}
            collapsedMode={false}
            formContainer='modal'
            feedbackObj={searchDetailsFeedbackObj}
          />
        }
      </ShareButtonsContainer>

      <Map
        pathLegs={pathLegs}
        vehiclesRealTime={currentVehicles}
        attributionPosition={mobile ? 'top-right' : 'bottom-right'}
        showFromArrivalTime={true}
        radio={parseInt(query.get('radio'))}
      />

      {openFilters
        ? (
          <Filters
            setOpenFilters={setOpenFilters}
          />
          )
        : (
          <Information>
            <TripPreview
              handleBack={handleBack}
              handleClose={handleClose}
              showSearchButton={false}
              setOpenFilters={setOpenFilters}
              handleLocationClick={handleLocationClick}
              handleChangeTravelDirection={handleChangeTravelDirection}
              handleExchangeTravelDirections={handleExchangeTravelDirections}
            />
            <ResultsContainer>
              <Results
                showBar={showBar}
                searchResultsFeedbackObj={searchResultsFeedbackObj}
                setShowBar={setShowBar}
                {...props}
              />
            </ResultsContainer>
          </Information>
          )
      }
    </TripResultsContainer>
  )
}
