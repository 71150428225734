import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { theme } from '../../../theme/theme'
import { Button } from '@mui/material'
import { setLoading } from '../../../actions/ui'

export const SearchFiltersButton = ({ setOpenFilters }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)

  const {
    handleClickApplyChanges
  } = useFilters()

  const handleSearch = async () => {
    dispatch(setLoading(true))

    try {
      await handleClickApplyChanges()
      dispatch(setLoading(false))
      setOpenFilters(false)
    } catch (e) {
      console.log('Error getting trip results', e)
    }
  }

  return (
    <Button
      disabled={to?.name === t('hint_location_B') || from?.name === t('hint_location_A')}
      sx={{
        width: '220px',
        height: '40px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 500,
        backgroundColor: theme.palette.icons.primary.main,
        borderRadius: '8px',
        '&:focus': {
          backgroundColor: theme.palette.icons.primary.main
        },
        '&:hover': {
          backgroundColor: theme.palette.icons.primary.main
        },
        '&:disabled': {
          backgroundColor: '#A6A9AC'
        },
        '@media (max-width:480px)': {
          width: '100%'
        }
      }}
      onClick={handleSearch}
    >
      {t('search_preview.filters.apply_and_search')}
    </Button>
  )
}
