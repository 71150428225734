import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded'
import Badge from '@mui/material/Badge'
import { useFeedback } from '../../hooks/useFeedback'
import CircularProgress from '@mui/material/CircularProgress'
import { theme } from '../../theme/theme'
import FeedbackOnboardingModal from '../FeedbackOnboarding'
import FeedbackForm from '../FeedbackForm'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import SuccessFeedbackModal from '../SuccessFeedbackModal'
import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab'
import Collapse from '@mui/material/Collapse'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function FeedbackButton({
  feedbackObj,
  size = 'large',
  iconColor = theme.palette.secondary.light,
  collapsedMode = true,
  formContainer = 'drawer',
  color = 'rgba(0, 0, 0, 0.54)'
}) {
  const { t } = useTranslation()
  const {
    loading,
    questions,
    onboardingModalOpen,
    formOpen,
    successModalOpen,
    handleOpen,
    handleOnboardingClose,
    setFormOpen,
    setSuccessModalOpen,
    showBadge,
    sendEvent
  } = useFeedback(1, 'SEARCH_RESULT_DETAILS')

  const [buttonCollapse, setButtonCollapse] = useState(true)

  useEffect(() => {
    if (collapsedMode) {
      setTimeout(() => {
        setButtonCollapse(false)
      }, 2000)
    }
  }, [])

  const screenEventParams = {
    duration: feedbackObj?.itinerary?.duration,
    startTime: feedbackObj?.itinerary?.start_time,
    walkDistance: feedbackObj?.itinerary?.walk_distance,
    waitingTime: feedbackObj?.itinerary?.waiting_time,
    endTime: feedbackObj?.itinerary?.end_time,
    walkTime: feedbackObj?.itinerary?.walk_time,
    transfers: feedbackObj?.itinerary?.transfers,
    isRealtime: feedbackObj?.itinerary?.legs.find(leg => leg.mode !== 'WALK').realtime,
    transitTime: feedbackObj?.itinerary?.transit_time
  }

  return (
        <>
            <Badge
                color='error'
                variant='dot'
                invisible={!showBadge}
                sx={{
                  '.MuiBadge-dot': {
                    top: '7px',
                    right: '8px'
                  }
                }}
            >
                <Fab
                    variant={buttonCollapse ? 'extended' : 'medium'}
                    size={size}
                    onClick={() => handleOpen(screenEventParams)}
                    sx={{
                      zIndex: 1,
                      margin: '0 0 0 16px',
                      boxShadow: collapsedMode ? '0 3px 6px #2228311E' : 'none',
                      backgroundColor: '#fff',
                      '&:hover': {
                        backgroundColor: '#fff',
                        boxShadow: collapsedMode ? '0 3px 6px #2228311E' : 'none'
                      }
                    }}
                >
                    {loading
                      ? <CircularProgress
                            sx={{
                              color: iconColor,
                              mr: buttonCollapse ? 1 : 0
                            }}
                            size={24}
                        />

                      : <FeedbackRoundedIcon
                            sx={{
                              mr: buttonCollapse ? 1 : 0,
                              color: iconColor
                            }}
                        />
                    }
                    <Collapse
                        in={buttonCollapse}
                        orientation='horizontal'
                    >
                        <Typography
                            variant="h6"
                            sx={{
                              fontSize: '14px',
                              whiteSpace: 'nowrap',
                              textTransform: 'none',
                              color
                            }}
                        >
                            {t('trip_share.feedback.result_detail_button_text')}
                        </Typography>
                    </Collapse>
                </Fab>
            </Badge>
            <FeedbackOnboardingModal open={onboardingModalOpen} handleClose={handleOnboardingClose}/>
            {formContainer === 'drawer'
              ? <SwipeableDrawer
                    disableDiscovery={true}
                    disableSwipeToOpen={true}
                    anchor="bottom"
                    open={formOpen}
                    onClose={() => setFormOpen(false)}
                    onOpen={() => setFormOpen(true)}
                    sx={{
                      borderRadius: '14px 14px 0 0',
                      '.MuiDrawer-paper': {
                        borderRadius: '14px 14px 0 0',
                        padding: '10px'
                      }
                    }}
                >
                    <Grid
                        item={true}
                        component='div'
                        xs={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: '10px'
                        }}
                    >
            <span
                style={{
                  margin: 0,
                  padding: 0,
                  width: '20px',
                  height: '4px',
                  borderRadius: '2px',
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  boxSizing: 'border-box',
                  display: 'table'
                }}
            />
                    </Grid>
                    {questions &&
                        <FeedbackForm
                            feedbackObj={feedbackObj}
                            setSuccessModalOpen={setSuccessModalOpen}
                            handleClose={setFormOpen}
                            question={questions}
                            screenEventParams={screenEventParams}
                            sendEvent={sendEvent}
                        />
                    }

                </SwipeableDrawer>
              : <Modal
                    open={formOpen}
                    onClose={() => setFormOpen(false)}
                >
                    <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '100%',
                          maxWidth: useMediaQuery('(min-width:600px)') ? '400px' : '300px',
                          backgroundColor: '#fff',
                          borderRadius: '15px',
                          padding: '20px',
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <IconButton
                            onClick={() => setFormOpen(false)}
                            sx={{
                              position: 'absolute',
                              top: '28px',
                              right: '20px'
                            }}
                        >
                            <CloseRoundedIcon/>
                        </IconButton>
                        {questions &&
                            <FeedbackForm
                                question={questions}
                                handleClose={setFormOpen}
                                setSuccessModalOpen={setSuccessModalOpen}
                                feedbackObj={feedbackObj}
                                screenEventParams={screenEventParams}
                                sendEvent={sendEvent}
                            />
                        }
                    </Box>
                </Modal>
            }
            <SuccessFeedbackModal setSuccessModalOpen={setSuccessModalOpen} successModalOpen={successModalOpen}/>
        </>
  )
}
