import React from 'react'
import { LogoLoader, SkeletonContainer } from '../../GlobalStyles'
import logo from '../../img/ualabeelogo.svg'
import { Skeleton } from '@mui/material'

export const SkeletonApp = () => {
  return (
    <SkeletonContainer>
      <LogoLoader src={process.env.REACT_APP_HARDCODED_LOGO ? process.env.REACT_APP_HARDCODED_LOGO : logo}/>
      <Skeleton
        animation="wave"
        variant="rect"
        width={'100%'}
        height={'100vh'}
      />
    </SkeletonContainer>
  )
}
