export const getPlaceByLatLng = (params, cityConfig) => {
  const { lat, lng } = params

  let uriGeocoding = 'https://geocoding.ualabee.com/reverse?format=jsonv2'

  if (window.location.hostname !== 'ualabee.com' &&
    window.location.hostname !== 'www.ualabee.com') {
    uriGeocoding = 'https://geocoding.ualabee.dev/reverse?format=jsonv2'
  }

  if (cityConfig?.city_id === 28) {
    uriGeocoding = 'https://nominatim.openstreetmap.org/reverse?format=jsonv2'
  }

  if (lat && lng) {
    return fetch(`${uriGeocoding}&lat=${lat}&lon=${lng}`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(`No city found by lat:${lat} long: ${lng}`)
        }
      }).then(response => {
        if (response?.address?.road === undefined) {
          const address = response.address.pedestrian

          let houseNumber = 'S/N'

          if (response?.address?.house_number !== undefined) {
            houseNumber = response?.address?.house_number
          }

          return (
            {
              name: address + ' ' + houseNumber,
              latLng: { lat, lng }
            }
          )
        } else {
          const address = response?.address?.road

          let houseNumber = 'S/N'

          if (response?.address?.house_number !== undefined) {
            houseNumber = response?.address?.house_number
          }

          return (
            {
              name: address + ' ' + houseNumber,
              latLng: { lat: Number(lat), lng: Number(lng) }
            }
          )
        }
      })
  }
}
