import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { IconButton, Snackbar, SwipeableDrawer } from '@mui/material'
import { ArrowBackRounded } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import SwipeableBar from '../../uicomponents/SwipeableBar'
import TitleDrawer from '../../uicomponents/TitleDrawer'
import FilterDrawer from '../../uicomponents/FilterDrawer'
import ImageCard from '../../uicomponents/ImageCard'
import { CitiesListContainer } from './styles'
import {
  setAdsList,
  setCities,
  setCityAds,
  setCityIdentifiedDismissed,
  setCountrySelected,
  setCurrentCitySelected,
  setLoading,
  setOpenCities,
  setOpenCountries,
  setResults
} from '../../actions/ui'
import { getCityByLatLng } from '../../db/getCityByLatLng'
import { getDistanceFromLatLonInKm } from '../../utils'
import { getAdsByCity } from '../../db/ads'
import { logEvent } from '../../firebase/firebase-config'
import { unsubscribeToCityTopics } from '../../db/unsubscribeToCityTopics'
import { setViewState } from '../../actions/map'
import { setLocalCityConfig } from '../../db/setLocalCityConfig'

const CitiesDrawer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cities = useSelector(state => state?.ui?.latam?.cities)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const openCities = useSelector(state => state?.ui?.components.openCities)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const fcmToken = useSelector(state => state?.user?.fcmToken)

  const [currentCities, setCurrentCities] = useState([])
  const [value, setValue] = useState()
  const [snackbarCity, setSnackbarCity] = useState(false)

  useEffect(() => {
    if (cities && !currentCities?.length) {
      if (userPosition) {
        const currentCities = cities?.map((city) => {
          city.distance = parseFloat(getDistanceFromLatLonInKm(
            userPosition?.lat,
            userPosition?.lng,
            city?.map_center_lat,
            city?.map_center_lng
          ).toLocaleString(navigator?.language, {
            maximumFractionDigits: 2
          }))
          return city
        })

        const citiesOrderByDistance = _.orderBy(currentCities, ['distance'], ['asc'])
        setCurrentCities(citiesOrderByDistance)
      } else {
        const citiesOrderByName = _.orderBy(cities, ['name'], ['asc'])
        setCurrentCities(citiesOrderByName)
      }

      dispatch(setLoading(false))
    }
  }, [cities])

  const handleSearch = (e) => {
    const value = e.target.value

    const results = cities?.filter((city) =>
      city.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .includes(value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase())
    )

    setValue(value)
    setCurrentCities(results)
  }

  const handleClose = () => {
    if (cityConfig) {
      dispatch(setOpenCities(false))
    } else {
      dispatch(setOpenCities(false))
      setSnackbarCity(true)
      dispatch(setOpenCities(true))
    }

    setValue(undefined)
  }

  const handleSnackbarCityClose = () => {
    setSnackbarCity(false)
  }

  const handleCitySelect = async (city) => {
    const params = {
      lat: city?.map_center_lat,
      lng: city?.map_center_lng
    }

    try {
      const cityConfig = await getCityByLatLng(params)
      setLocalCityConfig(cityConfig)

      dispatch(setViewState({
        latitude: cityConfig.map.map_center_lat,
        longitude: cityConfig.map.map_center_lng,
        zoom: cityConfig.map.map_zoom_level
      }))

      dispatch(setOpenCities(false))
      dispatch(setResults(undefined))

      getAdsByCity(cityConfig?.city_id)
        .then((response) => {
          if (response?.rotationTime?.some(item => item.ad_type_id === 20)) { // it means that there are some Search results advertising to show
            dispatch(setCityAds(response))
          } else {
            dispatch(setAdsList([]))
          }
        })
        .catch(e => console.log(e))

      dispatch(setCurrentCitySelected(cityConfig))

      setCurrentCities(cities)
      setValue(undefined)

      dispatch(setCityIdentifiedDismissed(true))

      const eventParams = {
        lat: userPosition ? userPosition.lat : null,
        lng: userPosition ? userPosition.lng : null,
        last_city_id: cityConfig ? cityConfig?.city_id : -1,
        last_city_name: cityConfig ? cityConfig.name : null,
        city_id: city?.city_id.toString(),
        city_name: city?.name,
        city_distance: userPosition
          ? getDistanceFromLatLonInKm(
            userPosition.lat,
            userPosition.lng,
            city?.map_center_lat,
            city?.map_center_lng
          )
          : null,
        user_id: sessionStorage.getItem('uid')
      }

      logEvent('change_city', eventParams)

      unsubscribeToCityTopics(cityConfig?.city_id, city?.city_id, fcmToken)
        .catch(e => console.log(e))
    } catch (e) {
      console.log(e)
    }
  }

  const handleBack = () => {
    dispatch(setCountrySelected(undefined))
    dispatch(setOpenCities(false))
    dispatch(setCities(undefined))
    dispatch(setOpenCountries(true))
    setCurrentCities(undefined)
  }

  const handleOpen = () => {
    dispatch(setOpenCities(true))
  }

  return (
    <>
      <SwipeableDrawer
        disableDiscovery={true}
        disableSwipeToOpen={true}
        sx={{
          '& .MuiDrawer-paper': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '13px 16px 16px 16px',
            backgroundColor: '#ffffff',
            borderRadius: '10px 10px 0 0',
            boxSizing: 'border-box',
            maxHeight: '80vh'
          },
          '& .MuiDrawer-modal': {
            zIndex: '402 !important'
          }
        }}
        anchor="bottom"
        open={openCities}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <IconButton
          onClick={handleBack}
          sx={{
            padding: '10px',
            position: 'absolute',
            top: '5px',
            left: '5px'
          }}
          aria-label="search"
        >
          <ArrowBackRounded
            sx={{
              color: 'rgba(25,125,143,0.6)'
            }}
          />
        </IconButton>
        <SwipeableBar/>
        <TitleDrawer title={t('home.change_city.title')}/>
        <FilterDrawer
          placeholder={t('home.change_city.autocomplete.placeholder')}
          value={value}
          onChangeFunction={handleSearch}
        />
        <CitiesListContainer>
          {currentCities?.map((item) => {
            return (
              <ImageCard
                onClickFunction={() => handleCitySelect(item)}
                item={item}
                key={item?.city_id}
                backgroundImg={item.landscape_picture}
                title={item.name}
              />
            )
          })}
        </CitiesListContainer>
      </SwipeableDrawer>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarCity}
        onClose={handleSnackbarCityClose}
        message={t('home.change_city.alert')}
      />
    </>
  )
}

export default CitiesDrawer
