import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Box, Drawer, Fab, useMediaQuery } from '@mui/material'
import {
  AlternativeInfoContainer,
  IconContainer,
  IconImg,
  InfoData,
  InfoGroup,
  InfoItem,
  InfoTitle,
  MapArea,
  TransportationName,
  TripDetails
} from './styles'
import ResultItem from '../../uicomponents/ResultItem'
import { NavigationButton } from '../../uicomponents/tripResults/NavigationButton'
import NavBar from '../NavBar'
import FeedbackButton from '../../uicomponents/FeedbackButton'
import { ArrowBackRounded } from '@mui/icons-material'
import { setTripDetails } from '../../actions/ui'
import { SkeletonApp } from '../../uicomponents/SkeletonApp'
import { getTripDetails } from '../../db/getTripDetails'
import { theme } from '../../theme/theme'
import { Map } from '../SearchResults'
import maplibregl from 'maplibre-gl'
import { useMap } from 'react-map-gl'

export const ResultsRoutesAndTrajectory = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { map } = useMap()

  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const { zoom, center } = useSelector(state => state?.map)
  const tripDetails = useSelector(state => state?.ui?.tripDetails)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const to = useSelector(state => state?.ui?.places?.to)
  const from = useSelector(state => state?.ui?.places?.from)

  const [mode, setMode] = useState()
  const [pathLegs, setPathLegs] = useState([])
  const [feedback, setFeedback] = useState()
  const [transportType, setTransportType] = useState(null)

  const mobile = useMediaQuery('(max-width:480px)')

  useEffect(() => {
    if (tripSelected) {
      setMode(tripSelected?.legs?.find(leg => leg.mode !== 'WALK')?.mode)

      const itinerary = {
        duration: tripSelected.duration,
        start_time: tripSelected.startTime,
        end_time: tripSelected.endTime,
        walk_time: tripSelected.walkTime,
        transit_time: tripSelected.transitTime,
        waiting_time: tripSelected.waitingTime,
        walk_distance: tripSelected.walkDistance,
        transfers: tripSelected.transfers,
        fare_cents: tripSelected?.fare?.cents || null,
        fare_currency: tripSelected?.fare?.currency || null,
        fare_fraction_digits: tripSelected?.fare?.default_fraction_digits || null,
        legs: tripSelected.legs.map(leg => ({
          route_id: leg.routeId || null,
          route_type: leg.routeType || null,
          agency_id: leg.agencyId || null,
          shape_id: leg.shapeId || null,
          trip_id: leg.tripId || null,
          to_stop_id: leg.to.stopId || null,
          start_time: leg.startTime,
          end_time: leg.endTime,
          departure_delay: leg.departureDelay,
          arrival_delay: leg.arrivalDelay,
          realtime: leg.realTime,
          realtime_arrival: leg?.realTimeArrival || null,
          distance: leg.distance,
          mode: leg.mode,
          from_name: leg.from.name,
          from_stop_id: leg.from.stopId || null,
          from_lon: leg.from.lon,
          from_lat: leg.from.lat,
          from_arrival: leg.from.arrival || null,
          from_departure: leg.from.departure,
          from_vertex_type: leg.from.vertexType,
          to_name: leg.to.name,
          to_lon: leg.to.lon,
          to_lat: leg.to.lat,
          to_arrival: leg.to.arrival,
          to_vertex_type: leg.to.vertexType,
          duration: leg.duration || null,
          points: leg.legGeometry?.points
        }))
      }

      setFeedback({
        city_id: cityConfig?.city_id,
        question_id: 1,
        itinerary
      })

      const legsNoWalk = tripSelected?.legs?.filter(leg => leg?.mode !== 'WALK')
      const legsNoWalkShapes = legsNoWalk?.map(leg => leg?.shapeId)

      if (!tripSelected.legs.some(leg => leg.mode === 'BICYCLE' || leg.mode === 'CAR')) {
        getTripDetails(cityConfig?.city_id, legsNoWalkShapes)
          .then((response) => {
            dispatch(setTripDetails(response?.result))
          })
          .catch(e => console.log(e))
      }
    } else {
      history.goBack()
    }
  }, [])

  // When start application
  useEffect(() => {
    if (!tripSelected || !map) return

    const pointsCount = []

    pointsCount.push(new maplibregl.LngLat(from?.latLng?.lng, from?.latLng?.lat))

    tripSelected.legs.filter(leg => leg.mode !== 'WALK').forEach(leg => leg.points.forEach(point => {
      pointsCount.push(new maplibregl.LngLat(point[1], point[0]))
    }))

    pointsCount.push(new maplibregl.LngLat(to?.latLng?.lng, to?.latLng?.lat))

    const bounds = pointsCount.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(pointsCount[0], pointsCount[0]))

    map?.fitBounds(bounds, {
      padding: {
        top: 32,
        left: 32,
        right: 32,
        bottom: 150
      }
    })
  }, [tripSelected, map])

  const handleBackClick = () => {
    history.goBack()
  }

  useEffect(() => {
    if (!tripSelected) return
    setTransportType(
      cityConfig?.transport_types?.find(transport => parseInt(transport?.transport_type_id) === parseInt(tripSelected?.legs.find(leg => leg.mode !== 'WALK')?.transportTypeId))
    )
  }, [tripSelected])

  useEffect(() => {
    if (tripDetails) {
      // legs NOT walking are filtered and the stops are obtained
      const pathLegs = tripSelected?.legs
        ?.filter(leg => leg.mode !== 'WALK')
        ?.map(leg => {
          // the detail of the trip that coincides with the shape id of the current section is obtained
          const detail = tripDetails?.find(detail => detail.shape_id === leg.shapeId) || { stops: [] }
          //  Get the stops from the from and to
          const routeStops = [...detail?.stops.slice(leg.from.stopIndex, leg.to.stopIndex)]
          const stops = leg.mode !== 'WALK' && leg.mode !== 'CAR' ? detail?.stops || [] : detail?.stops?.length >= 1 ? detail?.stops.slice(leg.from.stopIndex, leg.to.stopIndex) : []
          leg.stops = stops.map(stop => {
            return {
              ...stop,
              isOnRoute: routeStops.some(routeStop => routeStop.stop_lat === stop.stop_lat && routeStop.stop_lon === stop.stop_lon)
            }
          })
          return leg
        })

      setPathLegs(pathLegs)
    }
  }, [tripDetails])

  if (!center || !zoom) {
    return <SkeletonApp/>
  }

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh'
      }}
    >
      <Box
        sx={{
          height: mode === 'BICYCLE' || mode === 'CAR' ? 'calc(100% - 10%)' : 'calc(100% - 194px)',
          width: '100%'
        }}
      >

        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            left: '16px',
            zIndex: 500,
            gap: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column'
          }}
        >
          <Fab
            size="medium"
            onClick={handleBackClick}
            sx={{
              boxShadow: '0 3px 6px #2228311E',
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#fff'
              }
            }}
          >
            <ArrowBackRounded variant="secondary.main"/>
          </Fab>
        </Box>
        {!isIosApp && transportType &&
          <NavigationButton
            styles={{
              bottom: mode === 'BICYCLE' || mode === 'CAR' ? '152px' : '250px'
            }}
            tripSelected={tripSelected}
            transportType={transportType}
            to={tripSelected?.link_to}
          />
        }

        <MapArea>

          <Map
            pathLegs={pathLegs}
            showDraggableDestinationMarkers={false}
            geoControlProps={{
              styles: {
                bottom: mode === 'BICYCLE' || mode === 'CAR' ? '217px' : '318px'
              }
            }}
            attributionPosition='top-right'
            showFromArrivalTime={true}
          />

        </MapArea>

      </Box>
      <Drawer
        variant="persistent"
        open={true}
        anchor={'bottom'}
        sx={{
          height: mode === 'BICYCLE' || mode === 'CAR' ? '10%' : '194px',
          width: '100%',
          backgroundColor: '#fff',
          borderRadius: '16px 16px 0 0',
          position: 'absolute',
          bottom: 0
        }}
      >
        {mode === 'BICYCLE' || mode === 'CAR'
          ? (
            <AlternativeInfoContainer>
              <IconContainer
                bgcolor={transportType?.color}
              >
                <IconImg
                  filter={'true'}
                  src={transportType?.flat_icon}/>
              </IconContainer>

              <TripDetails>
                <TransportationName>{transportType?.name}</TransportationName>
                <InfoGroup>
                  {mode === 'CAR' && tripSelected?.fare &&
                    <InfoItem>
                      <InfoTitle>{t('trip_share.search_result_fare_title')}</InfoTitle>
                      <InfoData>${Number((tripSelected?.fare?.cents / 10 ** tripSelected?.fare?.default_fraction_digits).toFixed(2))}</InfoData>
                    </InfoItem>
                  }{mode === 'BICYCLE' && tripSelected?.fromBikeStation &&
                  <InfoItem>
                    <InfoTitle>Available</InfoTitle>
                    <InfoData>{Number(tripSelected?.fromBikeStation?.free_bikes)}</InfoData>
                  </InfoItem>
                }
                  <InfoItem>
                    <InfoTitle>{t('trip_share.car_result_distance_title')}</InfoTitle>
                    <InfoData>{Number(tripSelected?.legs[1]?.distance / 1000).toFixed(2)} km</InfoData>
                  </InfoItem>
                  <InfoItem>
                    <InfoTitle>{t('trip_share.search_result_arrival_title')}</InfoTitle>
                    <InfoData>{moment(tripSelected?.endTime).format('HH:mm')}</InfoData>
                  </InfoItem>
                </InfoGroup>
              </TripDetails>
            </AlternativeInfoContainer>)
          : (
            <ResultItem
              style={{ padding: '25px' }}
              trip={tripSelected}
              border={false} icons={true}
            />
            )

        }

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {feedback && mobile &&
            <FeedbackButton
              feedbackObj={feedback}
              iconColor={theme.palette.primary.main}
              color={theme.palette.primary.main}
              size={'medium'}
              collapsedMode={false}
            />
          }
        </Box>
      </Drawer>
      {isIosApp &&
        <NavBar/>
      }
      {
        /*
    <SwipeableDrawer
      disableDiscovery
      disableSwipeToOpen
      disableEnforceFocus
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      anchor="bottom"
      sx={{
        borderRadius: '10px 10px 0 0',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': {
          padding: '10px 20px'
        }
      }}
    >
             <span
               style={{
                 width: '20px',
                 height: '4px',
                 backgroundColor: 'rgba(0, 0, 0, 0.2)',
                 borderRadius: '5px'
               }}
             ></span>

         <BikeStationData station={bikeStationSelected}/>

    </SwipeableDrawer>
            */
      }
    </Box>
  )
}
