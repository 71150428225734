import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getAuth } from 'firebase/auth'
import { Avatar, Badge, IconButton, Paper } from '@mui/material'
import { AccountCircleRounded } from '@mui/icons-material'
import { logEvent } from '../../firebase/firebase-config'
import { MenuIcon, Searcher } from './styles'
import { setOpenMenu } from '../../actions/ui'
import { Filters } from '../../uicomponents/mobile/home/Filters'

const Search = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const auth = getAuth()
  const user = auth.currentUser
  const { t } = useTranslation()

  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const showMenuNotification = useSelector(state => state?.ui?.components?.showMenuNotification)

  const handleMenuOpen = () => {
    localStorage.setItem('showMenuNotification', JSON.stringify(false))

    const eventParams = {
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: sessionStorage.getItem('uid'),
      city_id: cityConfig?.city_id?.toString()
    }

    logEvent('drawer_menu_open', eventParams)

    dispatch(setOpenMenu(true))
  }

  return (
    <Paper
      component="form"
      sx={{
        padding: '16px 0',
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100vw - 32px)',
        borderRadius: '14px',
        boxShadow: '0 0 4px 0 rgb(0 0 0 / 20%), 0 6px 12px 0 rgb(0 0 0 / 8%)',
        boxSizing: 'border-box',
        height: '52px',
        margin: '16px 16px 0 16px',
        position: 'relative'
      }}
    >
      {process.env.REACT_APP_SHOW_MENU === 'true' &&
        <IconButton
          onClick={handleMenuOpen}
          aria-label="menu"
        >
          <Badge overlap="rectangular" color='error' variant="dot" invisible={!showMenuNotification}>
            <MenuIcon/>
          </Badge>
        </IconButton>
      }
      <Searcher
        onFocus={() => history.push('/search')}
        sx={{
          marginLeft: '10px',
          flex: 1,
          fontFamily: 'Visby Round'
        }}
        placeholder={t('hint_location_B')}
      />
      {process.env.REACT_APP_SHOW_LOGIN !== 'false' &&

        <IconButton
          onClick={() => userData ? history.push(`/user/${user.uid}`) : history.push('/login')}
          aria-label="search"
        >
          {userData
            ? <Avatar
              src={userData?.photo_url}
              sx={{
                width: '28px',
                height: '28px'
              }}
            />
            : <AccountCircleRounded
              sx={{
                color: '#A6A9AC',
                fontSize: '28px'
              }}
            />
          }
        </IconButton>

      }

      {pathname !== '/search' &&
        <Filters/>
      }

    </Paper>
  )
}

export default Search
