export const getDynamicLinks = (params) => {
  const { link, title = '', description = '', picture = '' } = params

  const reqBody = {
    dynamicLinkInfo: {
      domainUriPrefix: process.env.REACT_APP_DOMAIN_URI_PREFIX,
      link,
      androidInfo: {
        androidPackageName: process.env.REACT_APP_ANDROID_PACKAGE_NAME
      },
      iosInfo: {
        iosBundleId: process.env.REACT_APP_IOS_BUNDLE_ID
      },
      socialMetaTagInfo: {
        socialTitle: title,
        socialDescription: description,
        socialImageLink: picture
      }

    }
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting dynamic link')
      }
    })
    .then((response) => response)
    .catch((err) => console.log(err))
}
