import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'

export const getVehiclePositionsByRoute = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  if (isNaN(parseInt(params.city_id))) return

  const reqBody = {
    city_id: parseInt(params?.city_id),
    route_id: params?.route_id,
    direction_id: params?.direction_id
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reqBody)
  }

  return (
    fetch(`${process.env.REACT_APP_API_FEED}/realtimeFeed/vehiclePositionsByRoute`, reqConfig)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error getting vehicle positions by route')
        }
      })
      .then((response) => response)
  )
}

getVehiclePositionsByRoute.propTypes = {
  city_id: PropTypes.string,
  route_id: PropTypes.string,
  direction_id: PropTypes.string
}
