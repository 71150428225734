import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getAuth, signOut } from 'firebase/auth'
import { ArrowBackRounded, ExitToAppRounded, MoreVertRounded, PlusOneRounded } from '@mui/icons-material'
import { Button, IconButton, List, ListItemButton, ListItemText, Modal, Skeleton, SwipeableDrawer } from '@mui/material'
import {
  MoreOptionsIcon,
  MoreOptionsIconContainer,
  NavigationBar,
  ProfileContainer,
  UserName,
  UserNameContainer,
  UserProfileContainer,
  UserStatsContainer
} from './styles'
import { getContributionHistory } from '../../db/getContributionHistory'
import SwipeableBar from '../../uicomponents/SwipeableBar'
import ConnectedPeople from '../../img/people_connected.svg'
import { ModalButtonsContainer, ModalContainer, ModalSubtitle, WorldIconContainer } from '../IdentifiedCityModal/styles'
import { setPlace, setUserContribution, setUserData } from '../../actions/user'
import UserProfileStats from '../UserProfileStats'
import UserProfileDesktop from '../Desktop/UserProfile'
import { logEvent, remoteConfig } from '../../firebase/firebase-config'
import { handleOpenAlert, setOpenLoginPopup } from '../../actions/ui'
import NavBar from '../NavBar'
import { getValue } from 'firebase/remote-config'
import { Layout } from '../../uicomponents/layouts/Layout'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { deleteAccount } from '../../db/deleteAccount'
import { getUser } from '../../helpers/getUser'

const UserProfile = () => {
  const history = useHistory()
  const urlParams = useParams()
  const mobileMode = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const { t } = useTranslation()

  const auth = getAuth()
  const user = auth.currentUser

  const dispatch = useDispatch()

  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const contributionHistory = useSelector(state => state?.user.userContributionHistory)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  const [moreOptionsDrawer, setMoreOptionsDrawer] = useState(false)
  const [logoutConfirmDialog, setLogoutConfirmDialog] = useState(false)
  const [deleteAccountFirstConfirmDialog, setDeleteAccountFirstConfirmDialog] = useState(false)
  const [deleteAccountSecondConfirmDialog, setDeleteAccountSecondConfirmDialog] = useState(false)
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false)

  useEffect(() => {
    if (cityConfig && !contributionHistory) {
      getContributionHistory(cityConfig?.city_id)
        .then((response) => {
          dispatch(setUserContribution(response))
        })
    }

    if (cityConfig) {
      const params = {
        os: 'web',
        lat: userPosition ? userPosition.lat : null,
        lng: userPosition ? userPosition.lng : null,
        city_id: cityConfig?.city_id.toString(),
        user_id: urlParams.uid,
        user_gender: userData?.gender,
        profile_user_id: urlParams.uid
      }
      // Send events to analytics
      logEvent('user_profile', params)
    }
  }, [cityConfig])

  useEffect(() => {
    if (userData && !userData.nickname) {
      (async () => {
        const firestoreUser = await getUser(user.uid)
        dispatch(setUserData({ ...firestoreUser, uid: firestoreUser.id }))
      })()
    }
  }, [userData])

  const handleMoreOptionsClose = () => {
    setMoreOptionsDrawer(false)
  }

  const handleBecomeAPartner = () => {
    const params = {
      os: 'web',
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: userData.uid,
      user_gender: userData.gender
    }

    // Send events to analytics
    logEvent('collab_cta_click', params)

    window.open('https://ualabee.com/colaboradores', '_blank').focus()
  }

  const handlePointsHelp = () => {
    const params = {
      os: 'web',
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: userData.uid,
      user_gender: userData?.gender ? userData?.gender : null
    }
    // Send events to analytics
    logEvent('points_help_button', params)

    try {
      const value = getValue(remoteConfig, 'points_help_url')
      const url = value?._value

      window.open(url, '_blank').focus()
    } catch (e) {
      console.log(e)
    }
  }

  const handleLogOut = async () => {
    setLogoutConfirmDialog(false)
    const params = {
      os: 'web', // String
      lat: userPosition ? userPosition.lat : null, // Double
      lng: userPosition ? userPosition.lng : null, // Double
      city_id: cityConfig?.city_id?.toString(), // String
      user_id: userData.uid, // String
      user_gender: userData.gender, // String
      user_birthday_timestamp: userData ? userData?.birthday?.long_value : null // Long
    }

    // Send events to analytics
    logEvent('logout', params)

    const auth = getAuth()
    await signOut(auth)
    dispatch(setOpenLoginPopup(false))
    dispatch(setUserContribution(undefined))
    dispatch(setUserData(undefined))
    dispatch(setPlace(undefined))
    history.push('/')
  }

  const handleDeleteAccount = async () => {
    setDeleteAccountSecondConfirmDialog(false)
    setDeleteAccountLoading(true)
    const params = {
      os: 'web',
      lat: userPosition ? userPosition.lat : null,
      lng: userPosition ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: userData.uid,
      user_gender: userData?.gender ? userData?.gender : null
    }
    logEvent('delete_account', params)

    const accountDeleted = await deleteAccount()
    if (accountDeleted) {
      // remove local user lines and recent places
      localStorage.removeItem('linesFavs')
      localStorage.removeItem('recentPlaces')
      setDeleteAccountLoading(false)
      await handleLogOut()
    } else {
      dispatch(handleOpenAlert({
        open: true,
        severity: 'error',
        duration: 3000,
        title: t('delete_account_failure.title'),
        message: t('delete_account_failure.message')
      }))
      setDeleteAccountLoading(false)
    }
  }

  return (
    <Layout>
      {mobileMode
        ? <ProfileContainer>
          <UserProfileContainer>
            <NavigationBar>
              <UserNameContainer>

                <IconButton
                  sx={{
                    padding: '5px',
                    marginRight: '18px',
                    color: '#ffffff'
                  }}
                  onClick={() => history.push('/')}
                >
                  <ArrowBackRounded/>
                </IconButton>
                {userData
                  ? <UserName>
                    {userData?.nickname ? userData?.nickname : userData?.display_name}
                  </UserName>

                  : <Skeleton variant='text' width={100} height={20} animation="wave"/>}
              </UserNameContainer>
              <IconButton
                onClick={() => setMoreOptionsDrawer(true)}
                sx={{
                  padding: '5px',
                  color: '#ffffff'
                }}
              >
                <MoreVertRounded/>
              </IconButton>
            </NavigationBar>
            {userData && !user?.isAnonymous &&
              <UserStatsContainer>
                <UserProfileStats/>
              </UserStatsContainer>
            }
          </UserProfileContainer>
          <SwipeableDrawer
            disableDiscovery={true}
            disableSwipeToOpen={true}
            sx={{
              '& .MuiDrawer-paper': {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '8px 0 0 0',
                backgroundColor: '#ffffff',
                borderRadius: '14px 14px 0 0',
                boxSizing: 'border-box',
                maxHeight: '80vh'
              },
              '& .MuiDrawer-modal': {
                zIndex: '402 !important'
              }
            }}
            anchor="bottom"
            open={moreOptionsDrawer}
            onClose={() => handleMoreOptionsClose()}
            onOpen={() => setMoreOptionsDrawer(true)}
          >
            <SwipeableBar/>
            <List
              component="nav"

              sx={{
                width: '100%'
              }}
            >
              {!isIosApp &&
                <ListItemButton
                  onClick={() => handleBecomeAPartner()}
                  sx={{
                    padding: '16px'
                  }}
                >
                  <MoreOptionsIconContainer bgColor='#F1F8E5'>
                    <MoreOptionsIcon
                      color='#AECC66'
                      url={ConnectedPeople}
                    />
                  </MoreOptionsIconContainer>
                  <ListItemText
                    primary={t('user.options.become_a_contributor')}
                    sx={{
                      color: '#222831',
                      margin: 0,
                      fontSize: '16px'
                    }}
                  />
                </ListItemButton>
              }

              <ListItemButton
                onClick={() => handlePointsHelp()}
                sx={{
                  padding: '16px'
                }}
              >
                <MoreOptionsIconContainer bgColor='#F8E5EE'>
                  <PlusOneRounded
                    sx={{
                      color: '#CC6696'

                    }}
                  />
                </MoreOptionsIconContainer>
                <ListItemText
                  primary={t('user.options.why_sum_points')}
                  sx={{
                    color: '#222831',
                    margin: 0,
                    fontSize: '16px'
                  }}
                />
              </ListItemButton>

              <ListItemButton
                onClick={() => setLogoutConfirmDialog(true)}
                sx={{
                  padding: '16px'
                }}
              >
                <MoreOptionsIconContainer bgColor='#E7E5F8'>
                  <ExitToAppRounded
                    style={{
                      color: '#61616A'

                    }}
                  />
                </MoreOptionsIconContainer>
                <ListItemText
                  primary={t('user.options.sign_out')}
                  sx={{
                    color: '#222831',
                    margin: 0,
                    fontSize: '16px'
                  }}
                />
              </ListItemButton>

              <ListItemButton
                onClick={() => setDeleteAccountFirstConfirmDialog(true)}
                sx={{
                  padding: '16px'
                }}
              >
                <MoreOptionsIconContainer bgColor='#E7E5F8'>
                  <DeleteRoundedIcon
                    style={{ color: '#61616A' }}
                  />
                </MoreOptionsIconContainer>
                <ListItemText
                  primary={t('user.options.delete_account')}
                  sx={{
                    color: '#222831',
                    margin: 0,
                    fontSize: '16px'
                  }}
                />
              </ListItemButton>
            </List>
          </SwipeableDrawer>
        </ProfileContainer>
        : <UserProfileDesktop
          contributionHistory={contributionHistory}
          userData={userData}
          handleBecomeAPartner={handleBecomeAPartner}
          handlePointsHelp={handlePointsHelp}
          setLogoutConfirmDialog={setLogoutConfirmDialog}
          setDeleteAccountFirstConfirmDialog={setDeleteAccountFirstConfirmDialog}/>
      }
      <Modal
        open={logoutConfirmDialog}
        onClose={() => setLogoutConfirmDialog(false)}
      >
        <ModalContainer>
          <WorldIconContainer>
            <ExitToAppRounded
              fontSize='large'
              sx={{
                color: '#A6A9AC',
                padding: '5px'
              }}
            />
          </WorldIconContainer>
          <ModalSubtitle>
            {t('logout_confirmation.title')}
          </ModalSubtitle>
          <ModalButtonsContainer>
            <Button
              onClick={() => setLogoutConfirmDialog(false)}
              sx={{
                color: '#dc5849',
                textTransform: 'none'
              }}
            >
              {t('logout_confirmation.no')}
            </Button>
            <Button
              onClick={() => handleLogOut()}
              sx={{
                color: '#5fa9be',
                textTransform: 'none'
              }}
            >
              {t('logout_confirmation.yes')}
            </Button>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal><Modal
      open={deleteAccountFirstConfirmDialog}
      onClose={() => setDeleteAccountFirstConfirmDialog(false)}
    >
      <ModalContainer>
        <WorldIconContainer>
          <DeleteRoundedIcon fontSize='large'/>
        </WorldIconContainer>
        <ModalSubtitle>
          {t('delete_account_first_confirmation.title')}
        </ModalSubtitle>
        <ModalButtonsContainer>
          <Button
            onClick={() => setDeleteAccountFirstConfirmDialog(false)}
          >
            {t('delete_account_first_confirmation.no')}
          </Button>
          <Button
            onClick={() => {
              setDeleteAccountFirstConfirmDialog(false)
              setDeleteAccountSecondConfirmDialog(true)
            }}
          >
            {t('delete_account_first_confirmation.yes')}
          </Button>
        </ModalButtonsContainer>
      </ModalContainer>
    </Modal>
      <Modal
        open={deleteAccountSecondConfirmDialog}
        onClose={() => setDeleteAccountSecondConfirmDialog(false)}
      >
        <ModalContainer>
          <WorldIconContainer>
            <DeleteRoundedIcon fontSize='large'/>
          </WorldIconContainer>
          <ModalSubtitle>
            {t('delete_account_second_confirmation.title')}
          </ModalSubtitle>
          <ModalButtonsContainer>
            <Button
              onClick={() => setDeleteAccountSecondConfirmDialog(false)}
            >
              {t('delete_account_second_confirmation.no')}
            </Button>
            <Button
              onClick={() => handleDeleteAccount()}
            >
              {t('delete_account_second_confirmation.yes')}
            </Button>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Backdrop
        open={deleteAccountLoading}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress color='inherit'/>
      </Backdrop>
      {isIosApp &&
        <NavBar/>
      }
    </Layout>
  )
}

export default UserProfile
