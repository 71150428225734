import { getAuth } from 'firebase/auth'

export const getUserReportById = async (id) => {
  const reqBody = {
    id: Number(id)
  }

  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/userReports/getById`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting user report by id')
      }
    })
    .then((response) => response)
    .catch((err) => console.log(err))
}
