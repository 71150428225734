import React from 'react'
import { Skeleton } from '@mui/material'
import { CircleContainer, SkeletonContainer, TextContainer } from './styles'

export const AlertsSkeleton = () => {
  return (
    <SkeletonContainer>
      <CircleContainer>
        <Skeleton variant="circular" width={30} height={30}/>
      </CircleContainer>
      <TextContainer>
        <Skeleton variant="text" width={150} height={30}/>
      </TextContainer>
    </SkeletonContainer>
  )
}
