import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleClickWebToAppButton, setStopImage, setStopPictures } from '../../../../actions/ui'
import { useLocation } from 'react-router-dom'
import { Box, IconButton, Typography, Dialog, Slide } from '@mui/material'
import { AccountCircleRounded, ArrowBackRounded, FlagRounded, ThumbUpAltRounded } from '@mui/icons-material'
import { logEvent } from '../../../../firebase/firebase-config'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const Photo = ({ openPhoto, handleClosePhoto }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]),
    stop_id: pathname?.split('/')[3]
  }

  const photo = useSelector(state => state?.ui?.components?.stopImage)
  const pictures = useSelector(state => state?.ui?.components?.openMoreStopPictures?.pictures)
  const openMoreStopPictures = useSelector(state => state?.ui?.components?.openMoreStopPictures?.enabled)
  const stopSelected = useSelector(state => state?.ui?.stopSelected)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const user = useSelector(state => state?.user?.auth)

  const handleClose = () => {
    handleClosePhoto()
    dispatch(setStopImage(null))

    if (openMoreStopPictures) {
      dispatch(setStopPictures({ enabled: true, pictures }))
    }
  }

  const handleReport = () => {
    dispatch(handleClickWebToAppButton(true, {
      from: 'stop_picture_report',
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: user?.uid,
      os: 'web',
      city_id: urlParams?.city_id?.toString(),
      link_to: `${process.env.REACT_APP_HOST}/stops/${urlParams?.city_id}/${urlParams?.stop_id}`
    }, logEvent))
  }

  return (
    <Dialog
      sx={{ zIndex: 450 }}
      fullScreen
      open={openPhoto}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box
        sx={{
          backgroundImage: `url(${photo?.stop_picture_uri})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '100vh',
          position: 'relative'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'linear-gradient(rgba(0, 0, 0, 0.73) 0%, rgba(255, 255, 255, 0) 100%)',
            height: '60px',
            padding: '0',
            boxSizing: 'border-box'
          }}
        >
          <IconButton
            onClick={handleClose}
          >
            <ArrowBackRounded sx={{ color: '#fff' }}/>
          </IconButton>
          <Typography
            sx={{
              fontSize: '20px',
              width: 'calc(100% - 80px)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '@media (max-width:480px)': {
                color: '#fff',
                whiteSpace: 'nowrap',
                '@keyframes scroll-left': {
                  '0%': {
                    transform: ' translateX(100%)'
                  },
                  '100%': {
                    opacity: 1,
                    transform: ' translateX(-100%)'
                  }
                }
              }
            }}
          >
            {stopSelected?.stop_name}
          </Typography>
          <IconButton onClick={handleReport}>
            <FlagRounded sx={{ color: '#fff' }}/>
          </IconButton>
        </Box>
        <Box
          sx={{
            background: 'linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.73) 100%)',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bottom: 0,
            width: '100%',
            padding: '8px',
            boxSizing: 'border-box',
            borderTop: '1px solid #808080'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            <IconButton>
              <AccountCircleRounded sx={{ color: '#fff' }}/>
            </IconButton>
            <Typography sx={{ color: '#fff' }}>
              {photo?.user_nickname}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px'
            }}
          >
            <ThumbUpAltRounded sx={{ color: '#fff' }}/>
            <Typography sx={{ color: '#fff', margin: '0px 0px 0px 10px' }}>{photo?.likes_count}</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}
