import { getAuth } from 'firebase/auth'
import { getValue } from 'firebase/remote-config'
import { remoteConfig } from '../firebase/firebase-config'
import PropTypes from 'prop-types'

/** getContributionHistory()
 * @description Gets the contribution history for the current user
 * @param cityId
 * @returns {Promise<Response>}
 */

export const getContributionHistory = async (cityId) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  let value = 10

  try {
    value = getValue(remoteConfig, 'contribution_history_count')
  } catch (e) {
    console.log(e)
  }

  const reqBody = {
    city_id: cityId,
    user_id: user?.uid,
    contribution_limit: Number(value._value)
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/users/getProfile`, reqConfig)
    .then(response => {
      if (response.ok) {
        return response.json()
      }
    })
    .then((response) => response?.result)
    .catch((error) => {
      console.log('Error getting user profile: ' + error)
    })
}

getContributionHistory.propTypes = {
  cityId: PropTypes.string.isRequired
}
