import { getAuth } from 'firebase/auth'

export const getTripDetails = async (cityId, legsNoWalk) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqBody = {
    city_id: cityId,
    shapes: legsNoWalk
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/tripPlanner/getTripsDetails`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    })
    .then((response) => response)
    .catch((err) => console.log(err))
}
