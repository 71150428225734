import { getAuth } from 'firebase/auth'

/** getByCity()
 * @description Returns the current ads for the city based on the value of the current city id and the value of the REACT_APP_VARIANT_ID environment variable
 * @param {number} cityId - city_id property value from current city
 */

export const getAdsByCity = async (cityId) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const variantId = parseInt(process.env.REACT_APP_VARIANT_ID)

  if (isNaN(parseInt(cityId))) {
    return console.log('Error getting ads by city - city id must be an integer')
  }

  if (isNaN(parseInt(variantId))) {
    return console.log('Error getting ads by city - variant id must be an integer')
  }

  const reqBody = {
    city_id: cityId,
    variant_id: variantId
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/ads/getByCity`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('An error occurred while trying to get the advertisements of the current city')
      }
    })
    .then((response) => response?.result)
    .catch((error) => {
      console.log(error)
    })
}
