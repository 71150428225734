import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  height: ${process.env.REACT_APP_SHOW_NAVBAR !== 'false' ? 'calc(100vh - 56px)}' : '100vh'};
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  flex-direction: column;
`

export const ModalContainer = styled.div`
    display: flex;
    position: relative;
    top: 50%;
    left: 50%;
    width: 75%;
    max-width: 350px;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    background-color: #ffffff;
    padding: 25px 20px 10px 20px;
    border: none;
    box-sizing: border-box;
    :focus {
        outline: none;
    }
   
`

export const WorldIconContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
padding: 3px;
border-radius: 100%;
background-color: #ffffff;
position: absolute;
top: 0px;
right: 50%;
transform: translate(50%,-50%);
`

export const ModalTitle = styled.h2`
font-family: 'Visby Round';
  font-size: 16px;
  text-align: center;
  margin: 0;
  color: #000000;
  margin-bottom: 10px;
`

export const ModalSubtitle = styled.h3`
font-family: 'Visby Round';
  font-size: 12px;
  text-align: center;
  margin: 0;
  color: #000000;
   margin-bottom: 15px;
   font-weight: 300;
`

export const ModalButtonsContainer = styled.div`
display: flex;
width: 100%;
flex-direction: row;
box-sizing: border-box;
justify-content: space-between;
align-items: center;
`

export const ModalIcon = styled.img`
width: 40px;
`
