import { types } from '../types/types'

export const userReducer = (state = {
  lines: {
    linesFav: JSON.parse(localStorage.getItem('linesFavs')) || [],
    transportationType: 0
  },
  userPosition: undefined
}, action) => {
  switch (action.type) {
    case types.linesFav:
      return {
        ...state,
        lines: {
          ...state.lines,
          linesFav: action.payload
        }
      }
    case types.transportationType:
      return {
        ...state,
        lines: {
          ...state?.lines,
          transportationType: action.payload
        }
      }
    case types.lineSearchResult:
      return {
        ...state,
        lines: {
          ...state.lines,
          lineSearchResult: action.payload
        }
      }
    case types.userPosition:
      return {
        ...state,
        userPosition: action.payload
      }
    case types.auth:
      return {
        ...state,
        auth: action.payload
      }
    case types.userData:
      return {
        ...state,
        userData: action.payload
      }
    case types.fcmToken:
      return {
        ...state,
        fcmToken: action.payload
      }
    case types.savedPlaces:
      return {
        ...state,
        savedPlaces: action.payload
      }
    case types.searchFilters:
      return {
        ...state,
        searchFilters: action.payload
      }
    case types.userContribution:
      return {
        ...state,
        userContributionHistory: action.payload
      }
    default:
      return state
  }
}
