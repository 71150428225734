import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonFilterIcon } from './styles'
import AllInclusiveRoundedIcon from '@mui/icons-material/AllInclusiveRounded'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme/theme'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'

const LinesFilterNavBar = ({ onClickFunction }) => {
  const { t } = useTranslation()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const [value, setValue] = useState(null)

  const handleSelect = (transportTypeId) => {
    onClickFunction(transportTypeId)
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      sx={{
        display: 'flex',
        width: '100%',
        boxSizing: 'border-box',
        zIndex: 403,
        position: 'fixed',
        bottom: 0,
        borderRadius: '10px 10px 0 0',
        boxShadow: '0px -5px 20px 0px rgb(0 0 0 / 20%)',
        '& .MuiBottomNavigationAction-label': {
          fontFamily: 'Visby Round !important'
        }
      }}
    >
      {cityConfig?.transport_types.filter(type => !type.third_party_search).map((type, index) => {
        return (
          <BottomNavigationAction
            key={index}
            label={type.name}
            value={index}
            onClick={() => handleSelect(type?.transport_type_id)}
            sx={{
              '& .MuiBottomNavigationAction-root': {
                padding: '6px',
                color: `${theme.palette.icons.primary.main}bd`,
                '&.Mui-selected': {
                  color: theme.palette.icons.primary.main
                }
              },
              '& .MuiBottomNavigationAction-label': {
                fontSize: '12px',
                color: value === index ? theme.palette.primary.main : '#A6A9AC'
              }
            }}
            icon={
              <ButtonFilterIcon
                isSelected={value === index}
                src={type.flat_icon}
                alt={type.name}
              />
            }
          />
        )
      })}

      <BottomNavigationAction
        label={t('lines.transport_types.all')}
        value={null}
        onClick={() => handleSelect(0)}
        sx={{
          '& .MuiBottomNavigationAction-root': {
            padding: '6px',
            color: `${theme.palette.icons.primary.main}bd`,
            '&.Mui-selected': {
              color: theme.palette.icons.primary.main
            }
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '12px',
            color: value === null ? theme.palette.primary.main : '#A6A9AC'
          }
        }}
        icon={
          <AllInclusiveRoundedIcon
            sx={{ color: value === null ? theme.palette.primary.main : '#A6A9AC' }}
          />
        }
      />

    </BottomNavigation>
  )
}

export default LinesFilterNavBar
