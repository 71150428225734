import React from 'react'
import { useHistory } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { theme } from '../../theme/theme'
import { Box, IconButton } from '@mui/material'
import { ArrowBackRounded } from '@mui/icons-material'
import { Input } from './styles'

const FilterDrawer = ({
  placeholder,
  value,
  onChangeFunction,
  backButton,
  containerPadding,
  autoFocus = false
}) => {
  const history = useHistory()

  return (
    <Box
      component="form"
      sx={{
        position: 'relative',
        width: '100%',
        padding: containerPadding || '0px',
        boxSizing: 'border-box'
      }}
      noValidate
    >

      {backButton &&
                <IconButton
                  onClick={() => history.push('/')}
                  size={'medium'}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 4
                  }}
                >
                  <ArrowBackRounded
                    sx={{ color: theme.palette.icons.secondary.main }}
                  />
                </IconButton>
      }

      <Input
        backbutton={backButton || undefined}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChangeFunction(e)}
        type='text'
        autoFocus={autoFocus}
        endAdornment={
          <InputAdornment position="end">
            <SearchRoundedIcon style={{ color: process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR }}/>
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default FilterDrawer
