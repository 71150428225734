/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c2571187-f4aa-4a47-8404-f8fe612a9406",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_koxYQJ8Vw",
    "aws_user_pools_web_client_id": "1jfn21ihaj90fq69t7r5kq0poj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "search_indices": {
                "items": [
                    "placeindexb54b4979-prod"
                ],
                "default": "placeindexb54b4979-prod"
            }
        }
    }
};


export default awsmobile;
