import React from 'react'
import { IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useHistory } from 'react-router-dom'
import ShareIcon from '@mui/icons-material/Share'
import { BackAndTitleContainer, Title, TopBarContainer } from './styles'

const TitleTopBar = ({ title, backPath = '/', bgColor, showShareButton }) => {
  const history = useHistory()

  const handleBack = () => {
    if (backPath) {
      history.push(backPath)
    } else {
      history.goBack()
    }
  }

  return (

    <TopBarContainer bgColor={bgColor}>
      <BackAndTitleContainer>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon/>
        </IconButton>
        <Title>{title}</Title>
      </BackAndTitleContainer>
      {showShareButton &&
        <IconButton>
          <ShareIcon/>
        </IconButton>
      }
    </TopBarContainer>

  )
}

export default TitleTopBar
