import styled from 'styled-components/macro'
export const PreviewTileLayer = styled.div`
    position: absolute;
    z-index: 400;
    top: 5px;
    right: 5px;
    font-family: 'Visby Round';
    font-weight: 400;
    padding: 0 5px;
    margin: 5px 10px;
    font-size: 12px;
    background-color: #ffffff75;
    a {
        color: #0078A8;
        text-decoration: none;
    }
}
`

export const ResultsRoutesAndTrayectoryContainer = styled.div`
    width: 100vw;
    height: ${window.innerHeight}px; 
`

export const MapArea = styled.div`
    height: 100%; 
    position: relative;
`

export const AlternativeInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
`

export const IconContainer = styled.div`
    width: 49px;
    height: 49px; 
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.bgcolor};
    border-radius: 8px;
    margin-right: 24px;
`

export const IconImg = styled.img`
    width: 100%;
    border-radius: 8px;
`

export const TripDetails = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: flex-start;
`

export const TransportationName = styled.h2`
    font-family: 'Visby Round';
    margin: 0;
    color: ${props => props.theme.palette.text.primary.main}; 
    font-size: 16px;
    margin-bottom: 4px;
`

export const InfoGroup = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
`

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    margin-right: 22px;
    :last-child {
        margin-right: 0;
    };
`

export const InfoTitle = styled.h3`
    font-family: 'Visby Round';
    margin: 0;
    color: ${props => props.theme.palette.text.secondary.main};
    font-size: 10px;
    margin-bottom: 4px;
`

export const InfoData = styled.span`
    font-family: 'Visby Round';
    margin: 0;
    color: ${props => props.theme.palette.text.primary.main}; 
    font-size: 12px;
`
