import { createTheme } from '@mui/material/styles'
import visbyRoundFont from '../fonts/visby_round_cf_regular.otf'

/**
 Theme configuration file:
    - The theme is created with the values obtained from the environment variables.
      These are:
        - process.env.REACT_APP_PALLET_PRIMARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_PRIMARY_LIGHT_COLOR
        - process.env.REACT_APP_PALLET_SECONDARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_SECONDARY_LIGHT_COLOR
        - process.env.REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR
        - process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR
        - process.env.REACT_APP_PALLET_BUTTONS_PRIMARY_MAIN_COLOR
        - process.env.REACT_APP_PALLET_BUTTONS_SECONDARY_MAIN_COLOR
 **/

export const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_PALLET_PRIMARY_MAIN_COLOR,
      light: process.env.REACT_APP_PALLET_PRIMARY_LIGHT_COLOR
    },
    secondary: {
      main: process.env.REACT_APP_PALLET_SECONDARY_MAIN_COLOR
    },
    icons: {
      primary: {
        main: process.env.REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR,
        light: process.env.REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR
      },
      secondary: {
        main: process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR
      }
    },
    outline: {
      primary: {
        main: process.env.REACT_APP_PALLET_OUTLINE_PRIMARY_MAIN_COLOR
      },
      secondary: {
        main: process.env.REACT_APP_PALLET_OUTLINE_SECONDARY_MAIN_COLOR,
        light: process.env.REACT_APP_PALLET_OUTLINE_SECONDARY_LIGHT_COLOR
      }
    },
    error: {
      main: '#ff4b55'
    }
  },
  text: {
    primary: {
      main: process.env.REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR
    },
    secondary: {
      main: process.env.REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR,
      dark: process.env.REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                @font-face {
                    font-family: 'Visby Round';
                    src: local('Visby'), url(${visbyRoundFont}) format('truetype');
                }
            `
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { variant: 'primary.main' },
          style: {
            color: process.env.REACT_APP_PALLET_ICONS_PRIMARY_MAIN_COLOR
          }
        },
        {
          props: { variant: 'primary.light' },
          style: {
            color: process.env.REACT_APP_PALLET_ICONS_PRIMARY_LIGHT_COLOR
          }
        },
        {
          props: { variant: 'secondary.main' },
          style: {
            color: process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR
          }
        }
      ]
    },
    MuiDrawer: {
      styleOverrides: {
        zIndex: 403,
        paper: {
          backgroundColor: '#fff',
          borderRadius: '14px 14px 0 0',
          boxSizing: 'border-box',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '0'
        }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h2' },
          style: {
            fontSize: '18px'
          }
        }
      ],
      styleOverrides: {
        color: process.env.REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR
      }
    },
    MuiButtonBase: {
      label: {
        textTransform: 'none'
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none'
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Visby Round'
  }
})
