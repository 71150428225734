import styled from 'styled-components/macro'
import { Button } from '@mui/material'

export const LandingContainer = styled.div`
    height: ${window.innerHeight}px; 
    width: 100vw;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
`

export const Container = styled.div`
`

export const LogoContainer = styled.div`
`

export const LogoImg = styled.img`
    width: 200px;
`

export const Title = styled.h1`
    font-family: 'Visby Round';
    color: #222831;
    font-weight: 500; 
`

export const Subtitle = styled.p`
    font-family: 'Visby Round';
    color: #222831;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
`

export const DownloadButton = styled(Button)`
    
`
