import React, { useState, useEffect, useRef } from 'react'
import { Form, Buttons, Create } from './styles'
import './styles.css'
import { useHistory } from 'react-router-dom'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { handleOpenAlert } from '../../actions/ui'
import { useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'

export const CodeVerification = ({ confirmationAuthResult, setFormMode, phoneNumber }) => {
  const fieldsetRef = useRef(null)
  const history = useHistory()
  const auth = getAuth()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [verifiying, setVerifiying] = useState(false)
  const [timer, setTimer] = useState(20)

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier('resend-code-button', {
      size: 'invisible'
    }, auth)
  }, [])

  useEffect(() => {
    if (fieldsetRef) {
      const fields = document.querySelectorAll('.field')

      function handleInputField(event) {
        if (event.inputType === 'deleteContentBackward') {
          event.preventDefault()
        }
        const { value, maxLength } = event.target
        const newValue = event.target.value.slice(0, 1)
        event.target.value = newValue

        if (value.length === 6) {
          // it is because the user pasted the code
          fields.forEach((field, index) => {
            field.value = value[index]
          })
          // focus on the last field
          fields[5].focus()
        } else if (value.length === maxLength) {
          const parentEl = event.target.parentElement
          const nextEl = parentEl.nextElementSibling

          if (nextEl) {
            nextEl.children[0].focus()
          }
        } else if (value.length === 0) {
          const parentEl = event.target.parentElement
          const prevEl = parentEl.previousElementSibling

          if (prevEl) {
            prevEl.children[0].focus()
          }
        }

        const code = [...fields].map((field) => field.value).join('')
        if (code.length === 6) {
          // remove focus from all fields
          fields.forEach((field) => {
            field.blur()
          })
          handleSubmit(code)
        } else {
          setSuccess(false)
          setError(false)
          setVerifiying(false)
          setLoading(false)
        }
      }
      fields.forEach((field) => {
        field.addEventListener('input', handleInputField)
      })
    }
  }, [fieldsetRef])

  const handleSubmit = (code) => {
    setVerifiying(true)
    confirmationAuthResult.confirm(code).then(res => {
      setSuccess(true)
      setTimeout(() => {
        if (!res.user.displayName) {
          setFormMode('name-form')
        } else {
          history.push('/')
        }
      }, 1000)
    }).catch(error => {
      console.log('error', error)
      setLoading(false)
      setVerifiying(false)
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 750)
    })
  }

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  const resendCode = () => {
    // clean fields
    fieldsetRef.current.querySelectorAll('.field').forEach(field => {
      field.value = ''
    })
    setLoading(true)
    const appVerifier = window.recaptchaVerifier

    signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier).then(confirmationResult => {
      dispatch(handleOpenAlert({
        open: true,
        severity: 'success',
        duration: 3000,
        title: t('login.resend_code_success_alert_title', {
          prop1: `+${phoneNumber}`
        })
      }))
      setLoading(false)
      setTimer(20)
    })
  }

  return (
    <>
      <Form>
      <div
      ref={fieldsetRef}
      className={`fieldset ${success ? 'animate-success' : error ? 'animate-failure' : ''}`}
      onKeyDown={e => {
        if (e.key === 'Backspace') {
          e.preventDefault()
          if (e.target.value !== '') {
            e.target.value = ''
          } else {
            const parentEl = e.target.parentElement
            const prevEl = parentEl.previousElementSibling
            if (prevEl) {
              prevEl.children[0].focus()
              prevEl.children[0].value = ''
            }
          }
        }
      }}
      >
        <label className="box"><input disabled={verifiying} maxLength='1' min='0' max='9' className="field" type="number" placeholder="•" /></label>
        <label className="box"><input disabled={verifiying} maxLength='1' min='0' max='9' className="field" type="number" placeholder="•" /></label>
        <label className="box"><input disabled={verifiying} maxLength='1' min='0' max='9' className="field" type="number" placeholder="•" /></label>
        <label className="box"><input disabled={verifiying} maxLength='1' min='0' max='9' className="field" type="number" placeholder="•" /></label>
        <label className="box"><input disabled={verifiying} maxLength='1' min='0' max='9' className="field" type="number" placeholder="•" /></label>
        <label className="box"><input disabled={verifiying} maxLength='1' min='0' max='9' className="field" type="number" placeholder="•" /></label>
    </div>
  </Form>
  <Buttons>
  {verifiying && <CircularProgress />}
  {!verifiying &&
    <>
      {t('login.countdown_text', {
        prop1: timer
      })}
      <Create
        loading={loading}
        id="resend-code-button"
        disabled={timer > 0}
        onClick={resendCode}
      >
        {!loading && t('login.resend_code_button')}
      </Create>
    </>
  }
    </Buttons>
</>
  )
}
