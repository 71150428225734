import React from 'react'
import { Skeleton } from '@mui/material'
import { Container } from './styles'

export const FiltersSkeleton = () => {
  return (
    <>
      <Container>
        <Skeleton style={{
          marginRight: 5
        }} variant={'circular'} width={24} height={24}/>
        <Skeleton variant={'text'} width={40} height={21}/>
      </Container>

      <Container>
        <Skeleton style={{
          marginRight: 5
        }} variant={'circular'} width={24} height={24}/>
        <Skeleton variant={'text'} width={40} height={21}/>
      </Container>

      <Container>
        <Skeleton style={{
          marginRight: 5
        }} variant={'circular'} width={24} height={24}/>
        <Skeleton variant={'text'} width={40} height={21}/>
      </Container>

      <Container>
        <Skeleton style={{
          marginRight: 5
        }} variant={'circular'} width={24} height={24}/>
        <Skeleton variant={'text'} width={40} height={21}/>
      </Container>

      <Container>
        <Skeleton style={{
          marginRight: 5
        }} variant={'circular'} width={24} height={24}/>
        <Skeleton variant={'text'} width={40} height={21}/>
      </Container>
    </>
  )
}
