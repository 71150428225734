import * as React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { theme } from '../../theme/theme'
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded'
import { useTranslation } from 'react-i18next'

/**
 * It's a modal that shows up when the user clicks on the feedback button.
 * @param {boolean} open - Whether the modal is open or not.
 * @param {Object} handleClose - Function to close the modal.
 * @returns {JSX.Element} - The modal.
 * @constructor A modal component that is used to ask the user for feedback.
 */
export default function FeedbackOnboardingModal ({ open = false, handleClose }) {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: useMediaQuery('(min-width:600px)') ? '400px' : '300px',
          backgroundColor: '#fff',
          borderRadius: '15px',
          padding: '20px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.primary.main,
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '20px',
            textAlign: 'center',
            lineHeight: '1.4'
          }}
        >
          {t('feedback_on_boarding_modal.title')}
        </Typography>
        <Typography
          variant='p'
          sx={{
            color: theme.palette.text.primary.main,
            fontSize: '14px',
            textAlign: 'center',
            lineHeight: '1.4'
          }}>
          {t('feedback_on_boarding_modal.description_1')}
        </Typography>
        <Typography
          variant='p'
          sx={{
            color: theme.palette.text.primary.main,
            fontSize: '14px',
            textAlign: 'center',
            lineHeight: '1.4'
          }}>
          {t('feedback_on_boarding_modal.description_2')}
        </Typography>

        <FeedbackRoundedIcon sx={{
          color: theme.palette.primary.main,
          margin: '20px 0'
        }}/>

        <Button
          onClick={handleClose}
          sx={{
            color: theme.palette.primary.main,
            fontSize: '16px',
            textAlign: 'center',
            textTransform: 'capitalize',
            fontWeight: '600'
          }}
        >
          {t('feedback_on_boarding_modal.button_text')}
        </Button>

      </Box>
    </Modal>
  )
}
