import { getAuth } from 'firebase/auth'

export const setPointsOfInterest = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(params)
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/pointsOfInterest`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
    }).catch((err) => {
      console.log(err)
    })
}
