import styled from 'styled-components/macro'

export const UserProfileContainer = styled.div`
display: grid;
grid-template-columns: 300px auto;
column-gap: 16px;
row-gap: 16px;
width: 100%;
box-sizing: border-box;
padding: 16px;
background-color: #FAFAFA;
min-height: 100vh;
`

export const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
    max-height: calc(100vh - 32px);
    overflow: hidden;
    border-radius: 16px;
`

export const InfoCard = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: ${props => props.padding ? props.padding : '24px'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    box-sizing: border-box;
    background-color: #ffffff;
    border-radius: 16px;
    margin: ${props => props.margin ? props.margin : '0 0 16px 0'};
    height: ${props => props.height ? props.height : 'unset'};
    overflow: ${props => props.overflow ? props.overflow : 'unset'};
    box-sizing: border-box;
`

export const UserInfoContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
box-sizing: border-box;
padding:47px;
`

export const SectionTitle = styled.h3`
font-family: 'Visby Round';
font-size: 16px;
margin: 0;
color: #222831;
margin-bottom: 24px;
`

export const UalabeeLogo = styled.img`
    width: 94px;
    margin-bottom: 30px;
`

export const FormContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
box-sizing: border-box;
padding: 8px;
`

export const InputRowContainer = styled.div`
width: 80%;
max-width: 600px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
box-sizing: border-box;
margin-bottom: 12px;
`

export const InputName = styled.h4`
font-family: 'Visby Round';
font-size: 16px;
margin: 0;
color: #909397;
`
