export const getLocalCityConfig = () => {
  try {
    if (!localStorage.getItem('cityConfig') || localStorage.getItem('cityConfig') === 'undefined' || typeof {} !== 'object' || Object.keys(localStorage.getItem('cityConfig')).length === 0) return null

    return localStorage.getItem('cityConfig')
      ? JSON.parse(localStorage.getItem('cityConfig'))
      : null
  } catch (e) {
    console.log(e)
  }
}
