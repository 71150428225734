import React, { useEffect, useState } from 'react'
import {
  Affected,
  Buttons,
  Container,
  Information,
  LinesAffected,
  Name,
  Picture,
  ReportButton,
  ReportContainer,
  ReportIcon,
  ReportImage,
  ReportInformation,
  ShareButton,
  ShareIcon,
  Text,
  Time,
  Type,
  UserInformation,
  Vows,
  VowsContainer,
  VowsIcon
} from './styles'
import moment from 'moment'
import { handleClickWebToAppButton } from '../../../../actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalUserUid } from '../../../../db/getLocalUserUid'
import { useLocation } from 'react-router-dom'
import { loadStopAlertTypes } from '../../../../helpers/loadStopAlertTypes'
import { logEvent } from '../../../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@mui/material'

export const Report = ({ report }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const uid = getLocalUserUid()
  const { t } = useTranslation()

  const urlParams = {
    city_id: Number(pathname?.split('/')[2]),
    stop_id: pathname?.split('/')[3]
  }

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const mode = useSelector(state => state?.ui?.mode)

  const a = moment(report?.created_at).locale('es')
  const b = moment(new Date()).locale('es')

  const [alertType, setAlertType] = useState(null)

  useEffect(() => {
    loadStopAlertTypes()
      .then((types) => {
        const current = types?.filter((type) => type?.id === report?.report_type)[0]
        setAlertType(current)
      })
  }, [])

  const handleWebToApp = (from) => {
    dispatch(handleClickWebToAppButton(true, {
      from,
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: uid,
      os: 'web',
      city_id: cityConfig?.city_id.toString() || urlParams?.city_id.toString(),
      link_to: `${process.env.REACT_APP_HOST}/stops/${urlParams?.city_id}/${urlParams?.stop_id}`
    }, logEvent))
  }

  return (
    <ReportContainer mode={mode}>
      <ReportInformation>
        <Container>
          <UserInformation>
            <Avatar
              sx={{ margin: ' 0 16px 0 0' }}
              alt={report?.user_nickname}
              src={report?.user_photo_url}
            />
            <Name>{report?.user_nickname} · <Time>{a?.from(b)}</Time></Name>
          </UserInformation>
          <ReportButton edge='end' onClick={() => handleWebToApp('stop_flag_stop_report')}>
            <ReportIcon/>
          </ReportButton>
        </Container>

        <Information>

          {alertType
            ? <Type label={alertType?.name_es}/>
            : <Type label={t('loading')}/>
          }

          <LinesAffected>
            {report?.affected_routes_json_array?.map((affected) => {
              return (
                <Affected
                  color={
                    affected?.color?.includes('#')
                      ? affected?.color
                      : `#${affected?.color}`
                  }
                  key={affected?.id}
                  label={affected?.route_short_name || affected?.route_long_name}
                />
              )
            })}
          </LinesAffected>

          {mode && report?.report_picture_url &&
            <ReportImage
              src={report?.report_picture_url}
              alt='stop report picture'
            />
          }

          <Text mode={mode}>
            {report?.report_text}
          </Text>

          <Buttons>
            <VowsContainer>
              <VowsIcon/>
              <Vows>1</Vows>
            </VowsContainer>
            <ShareButton
              sx={{
                backgroundColor: '#cd5c90 !important',
                color: 'white !important',
                '&:hover': {
                  backgroundColor: '#cd5c90'
                },
                '&:focus': {
                  backgroundColor: '#cd5c90'
                }
              }}
              clickable
              icon={<ShareIcon sx={{ color: '#ffffff' }} fontSize='small'/>}
              label={t('share.title')}
              onClick={() => handleWebToApp('stop_share_stop_report')}
            />
            {!mode && report?.report_picture_url &&
              <Picture
                src={report?.report_picture_url}
                alt='stop report picture'
              />
            }
          </Buttons>

        </Information>

      </ReportInformation>
    </ReportContainer>
  )
}
