import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden auto;
`

export const MapContainerLine = styled.div`
  position: relative;
  display: ${props => props?.mode ? 'flex' : 'block'};
  min-height: ${props => props?.mode ? '50%' : '100%'};
  height: ${props => props?.mode ? 'auto' : '100%'};
  width: ${props => props?.mode ? '100%' : '100%'};
`

export const StopsContainerLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 401;
  border-radius: ${props => props?.offsetTop <= 10 ? 'unset' : '10px 10px 0 0'};
  background-color: ${props => props.theme.palette.primary.main};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
`

export const LineNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
`

export const LineName = styled.h1`
  font-family: "Visby Round";
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
`

export const RouteLineName = styled.h2`
  font-family: "Visby Round";
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
`

export const TimelineContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 20px 0 0 0;
`

export const StopContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-left: 8px;
  height: 44px;
`

export const RouteStopNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
`

export const RouteStopName = styled.h2`
  font-family: "Visby Round";
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin: 0;
  text-align: start;
`

export const RoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  padding-top: 24px;
`

export const RouteName = styled.h3`
  font-family: "Visby Round";
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => {
    return props?.selected ? props => props.theme.palette.primary.main : '#000000'
  }};
  margin: 0;
`

export const ModalContainer = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  min-height: 100px;
  width: 95%;
  padding: 20px;
  border-radius: 20px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  box-shadow: inset 0 0 15px rgba(55, 84, 170, 0),
  inset 0 0 20px rgba(255, 255, 255, 0), 7px 7px 15px rgba(55, 84, 170, 0.15),
    -7px -7px 20px rgba(255, 255, 255, 1),
  inset 0px 0px 4px rgba(255, 255, 255, 0.2);

  :focus {
    outline: unset;
  }
`

export const CombinationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
`

export const ShareButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 401;
`

export const LineContainer = styled.div`
  height: ${window.innerHeight}px;
  width: 100%;
  overflow: auto;
`
