import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useMap } from 'react-map-gl'
import {
  Container,
  HeaderContainer,
  LineContainer,
  LineName,
  LineNameContainer,
  RouteLineName,
  RouteName,
  RoutesContainer,
  RouteStopName,
  RouteStopNameContainer,
  ShareButtonContainer,
  StopContainer,
  TimelineContainer
} from './styles'
import { Line as LineDesktop } from '../Desktop/Line'
import { handleClickWebToAppButton, setShowLines, verifyLine } from '../../actions/ui'
import DataProviderModal from '../../uicomponents/DataProviderModal'
import { SwipeableBar } from '../Search/styles'
import TitleDrawer from '../../uicomponents/TitleDrawer'
import { detectIos, pickTextColorBasedOnBgColor } from '../../utils'
import ShareSmallButton from '../../uicomponents/ShareSmallButton'
import { logEvent } from '../../firebase/firebase-config'
import SendToPhoneModal from '../SendToPhoneModal'
import ShareModal from '../ShareModal'
import { handleStopClick } from '../../actions/map'
import 'moment/locale/es'
import NavBar from '../NavBar'
import { SkeletonApp } from '../../uicomponents/SkeletonApp'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../uicomponents/mobile/Loading'
import { Box, Chip, IconButton, SwipeableDrawer, useMediaQuery } from '@mui/material'
import { theme } from '../../theme/theme'
import { Layout } from '../../uicomponents/layouts/Layout'
import { ArrowBackRounded, InfoRounded, MoreHorizRounded, ScheduleRounded } from '@mui/icons-material'
import {
  Skeleton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator
} from '@mui/lab'
import { SkeletonContainer } from '../../GlobalStyles'
import _ from 'lodash'
import {
  CustomMarker,
  getVehiclesOfRealtimeHub,
  getVehiclesOfThirdParties,
  MapContainer,
  PolylineWithSenseArrow,
  VehicleRealtime
} from '../../uicomponents/Map'
import maplibregl from 'maplibre-gl'
import realtimeIcon from '../../img/real-time-badge.svg'

const Line = () => {
  const headerRef = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const params = useParams()

  const { map } = useMap()

  const isIos = detectIos()
  const lineSelected = useSelector(state => state?.ui?.lineSelected)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const routes = useSelector(state => state?.ui?.routes)
  const userData = useSelector(state => state?.user?.userData)
  const user = useSelector(state => state?.user?.auth)

  const userPosition = useSelector(state => state?.user?.userPosition)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const mode = useSelector(state => state?.ui?.mode)

  const [openRoutesList, setOpenRoutesList] = useState(false)
  const [providerModal, setProviderModal] = useState(false)
  const [routeSelected, setRouteSelected] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentVehicles, setCurrentVehicles] = useState([])

  useEffect(() => {
    if (routeSelected) {
      getVehicles()

      const interval = setInterval(getVehicles, 30000)

      return () => {
        clearInterval(interval)
      }
    }
  }, [routeSelected])

  const getVehicles = async () => {
    try {
      let result = []

      if (cityConfig?.config.realtime_hub_enabled) {
        result = await getVehiclesOfRealtimeHub(cityConfig.city_id, routeSelected?.route_id, routeSelected?.direction_id)
      } else if (cityConfig?.config.realtime_arrivals_enabled) {
        result = await getVehiclesOfThirdParties(cityConfig.city_id, lineSelected?.route_type, lineSelected?.route_short_name || lineSelected?.route_long_name, routeSelected?.direction_id)
      }

      setCurrentVehicles(result)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (user) {
      dispatch(verifyLine(cityConfig, {
        cityId: pathname.split('/')[2],
        routeId: pathname.split('/')[3],
        shapeId: pathname.split('/')[4]
      }, setRouteSelected))
    }
  }, [user])

  useEffect(() => {
    if (lineSelected?.trips?.length >= 1 && routeSelected) {
      if (routeSelected.shape_id !== pathname.split('/')[4]) {
        const currentTrip = lineSelected.trips.find(t => t.shape_id === pathname.split('/')[4])

        if (currentTrip) {
          setRouteSelected(currentTrip)
        }
      }
    }
  }, [pathname, map, lineSelected])

  const handleRouteSelect = (route) => {
    setCurrentVehicles([])

    const eventParams = {
      city_id: cityConfig?.city_id?.toString(), // String
      os: 'web', // String
      lat: userPosition?.lat || null, // Double
      lng: userPosition?.lng || null, // Double
      user_id: user.uid, // String
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null, // String
      line_id: lineSelected?.route_id, // String
      route_id: parseInt(route.shape_id), // Int
      route_name: route?.route_id, // String
      line_color: lineSelected?.route_color, // String
      line_name: lineSelected?.route_short_name || lineSelected?.route_long_name // String
    }

    // Send events to analytics
    logEvent('switch_route', eventParams)

    setOpenRoutesList(false)
    setAnchorEl(null)

    const currentTrip = lineSelected.trips.find(t => t.shape_id === route?.shape_id)

    if (!currentTrip) return

    history.replace(
      `/routes/${cityConfig?.city_id}/${route?.route_id}/${route?.shape_id}`,
      {
        cityId: cityConfig?.city_id,
        lineId: route?.route_id,
        routeId: route?.shape_id
      }
    )
  }

  const handleClickGoBack = () => {
    history.goBack()
  }

  const handleClickStop = (stop) => {
    dispatch(setShowLines(false))

    const transportType = cityConfig?.transport_types?.find(transport => transport?.route_type === lineSelected?.route_type)

    dispatch(handleStopClick({
      ...stop, transport_type_id: transportType?.transport_type_id
    }, userPosition, logEvent, user.uid, history))
  }

  const handleScheduleClick = (stop) => {
    logEvent('line_stop_details_arrival_time_click', {
      user_id: user?.uid,
      lat: userPosition?.lat || null,
      lng: userPosition?.lng || null,
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null, // String
      city_id: cityConfig?.city_id || null, // Int
      line_id: routeSelected?.route_id || null, // String
      line_color: lineSelected?.route_color || null, // String
      line_name: routeSelected.route_short_name || routeSelected.route_long_name, // String
      line_pathway: '',
      stop_lat: stop?.stop_lat || null, // Double
      stop_lng: stop?.stop_lon || null, // Double
      stop_color: lineSelected?.route_color, // String
      stop_id: stop?.stop_id || null, // String
      is_affected: stop?.affected_stop // Boolean
    })
    history.push(`/stop_times/${cityConfig?.city_id}/${routeSelected?.shape_id}/${stop.stop_id}`)
  }

  const icon = cityConfig?.transport_types?.filter((type) => {
    return lineSelected?.route_type === type?.route_type
  })[0]?.flat_icon

  if (!user) {
    return <SkeletonApp/>
  }

  return (
    <Layout
      title={routes?.length >= 1 && lineSelected && routeSelected ? `${t('routes.line_route', { prop1: lineSelected?.route_short_name || lineSelected?.route_long_name })} | ${routeSelected?.trip_headsign}` : null}
    >
      {routeSelected?.shape_id !== params.routeId && <Loading/>}
      {mode
        ? (<LineContainer>
            {routes?.length >= 1 && lineSelected
              ? (
                <>
                  <Container>
                    <IconButton
                      onClick={handleClickGoBack}
                      sx={{
                        position: 'absolute',
                        top: '16px',
                        left: '16px',
                        backgroundColor: theme.palette.icons.primary.main,
                        zIndex: 401,
                        '&:focus': {
                          backgroundColor: theme.palette.icons.primary.main
                        },
                        '&:hover': {
                          backgroundColor: theme.palette.icons.primary.main
                        },
                        '&.Mui-disabled': {
                          backgroundColor: '#A6A9AC'
                        }
                      }}
                      size="large"
                      disabled={routeSelected?.shape_id !== pathname.split('/')[4]}
                    >
                      <ArrowBackRounded sx={{ color: '#fff' }}/>
                    </IconButton>
                    {(isIosApp && navigator?.canShare) || !isIosApp &&
                      <ShareButtonContainer>
                        <ShareSmallButton
                          eventName={'path_share_clicked'}
                          eventParams={{
                            user_id: user?.uid,
                            os: 'web',
                            user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                            user_gender: userData ? userData?.gender : null,
                            user_lat: userPosition ? userPosition?.lat : null,
                            user_lng: userPosition ? userPosition?.lng : null,
                            city_id: cityConfig?.city_id,
                            path_id: routeSelected?.shape_id,
                            line_id: lineSelected?.route_id,
                            line_name: lineSelected?.route_short_name || lineSelected?.route_long_name,
                            going: routeSelected?.direction_id
                          }}
                          params={
                            {
                              link: `${process.env.REACT_APP_HOST}/lines/${cityConfig?.city_id}/${lineSelected?.route_id}/${routeSelected?.shape_id}?invitedby=${user?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=path_share_clicked`,
                              title: `${lineSelected?.route_short_name || lineSelected?.route_long_name}`,
                              description: t('share_route'),
                              picture: `${icon}`
                            }}
                          shareText={t('share.title')}
                          shareType={'share'}
                          bgButtonColor={'#ffffff'}
                          buttonSize={'large'}
                        />
                      </ShareButtonContainer>
                    }
                    <Box
                      sx={{
                        position: 'relative'
                      }}
                    >
                      <Map
                        map={map}
                        height={'50vh'}
                        width={'100vw'}
                        routeSelected={routeSelected}
                        handleClickStop={handleClickStop}
                        setProviderModal={setProviderModal}
                        currentVehicles={currentVehicles}
                      />
                      <DataProvider
                        setProviderModal={setProviderModal}
                        lineSelected={lineSelected}
                      />
                    </Box>
                    <HeaderContainer ref={headerRef}>
                      <LineNameContainer>

                        {lineSelected?.route_short_name || lineSelected?.route_long_name
                          ? (
                            <LineName>
                              {t('routes.line_route', { prop1: lineSelected?.route_short_name || lineSelected?.route_long_name })}
                            </LineName>
                            )
                          : (
                            <Skeleton
                              style={{ borderRadius: '5px' }}
                              variant={'text'}
                              width={'60%'}
                            />
                            )
                        }

                        {routeSelected?.trip_headsign
                          ? (
                            <RouteLineName>{routeSelected?.trip_headsign}</RouteLineName>
                            )
                          : (
                            <Skeleton
                              style={{ borderRadius: '5px' }}
                              variant={'text'}
                              width={'90%'}
                            />
                            )
                        }
                      </LineNameContainer>
                      <IconButton onClick={() => setOpenRoutesList(true)}>
                        <MoreHorizRounded sx={{ color: '#fff' }}/>
                      </IconButton>
                    </HeaderContainer>

                    <TimelineContainer>

                      <Timeline align="left" sx={{ width: '100%' }}>
                        {routeSelected?.stops.map((stop, index) => {
                          return (
                            <TimelineItem
                              key={index}
                              sx={{
                                width: '100%',
                                '&:before': {
                                  display: 'none'
                                }
                              }}
                            >
                              <TimelineSeparator>
                                <TimelineDot
                                  sx={{
                                    margin: 0
                                  }}
                                  style={{ border: `5px solid #${lineSelected?.route_color}` }}
                                  variant="outlined"
                                />
                                {routeSelected?.stops.length !== index + 1 &&
                                  <TimelineConnector
                                    sx={{
                                      backgroundColor: `#${lineSelected?.route_color}`,
                                      width: '5px'
                                    }}
                                  />
                                }
                              </TimelineSeparator>
                              <TimelineContent
                                sx={{
                                  position: 'absolute',
                                  width: '100%',
                                  top: '-20px',
                                  boxSizing: 'border-box'
                                }}
                              >

                                <StopContainer
                                  style={{
                                    width: '100%',
                                    paddingLeft: '10px'
                                  }}
                                >
                                  <RouteStopNameContainer
                                    onClick={() => dispatch(handleStopClick(stop, userPosition, logEvent, user?.uid, history))}>
                                    <RouteStopName>{stop?.stop_name?.replace('Avenida', 'Av.')}</RouteStopName>
                                    <StopTransfers transfers={stop?.transfers || []}/>
                                  </RouteStopNameContainer>

                                  {!isIos && <div style={{
                                    width: '40%',
                                    position: 'relative'
                                  }}>
                                    <Chip
                                      sx={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '-12px',
                                        color: '#fff',
                                        backgroundColor: theme.palette.primary.main,
                                        fontWeight: 400,
                                        lineHeight: 'normal',
                                        '& .MuiChip-label': {
                                          paddingRight: '10px'
                                        },
                                        '& .MuiSvgIcon-root': {
                                          color: '#fff'
                                        },
                                        '&:hover': {
                                          backgroundColor: theme.palette.primary.main
                                        },
                                        '&:focus': {
                                          backgroundColor: theme.palette.primary.main
                                        }
                                      }}
                                      size="small"
                                      icon={
                                        <ScheduleRounded sx={{
                                          color: '#fff'
                                        }}/>
                                      }
                                      label={t('routes.times')}
                                      onClick={() => dispatch(handleClickWebToAppButton(true, {
                                        from: 'route_schedules',
                                        lat: userPosition ? userPosition?.lat : null,
                                        lng: userPosition ? userPosition?.lng : null,
                                        user_id: user?.uid,
                                        os: 'web',
                                        city_id: cityConfig?.city_id?.toString(),
                                        link_to: `${process.env.REACT_APP_HOST}/stop_times/${cityConfig?.city_id}/${stop.stop_id}`
                                      }, logEvent))}
                                    />
                                  </div>}
                                </StopContainer>

                              </TimelineContent>
                            </TimelineItem>
                          )
                        })}
                      </Timeline>
                    </TimelineContainer>

                  </Container>
                  <SwipeableDrawer
                    disableDiscovery={true}
                    disableSwipeToOpen={true}
                    sx={{
                      '& .MuiDrawer-paper': {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: '#ffffff',
                        borderRadius: '10px 10px 0 0',
                        boxSizing: 'border-box',
                        padding: '13px 16px 13px 16px'
                      }
                    }}
                    anchor="bottom"
                    open={openRoutesList}
                    onClose={() => setOpenRoutesList(false)}
                    onOpen={() => setOpenRoutesList(true)}
                  >
                    <SwipeableBar/>
                    <TitleDrawer title={t('routes.choose_direction')}/>

                    <RoutesContainer>
                      {routes.map((route, index) => {
                        return (
                          <Link
                            to={`/routes/${cityConfig?.city_id}/${lineSelected?.route_id}/${routes[index]?.shape_id}`}
                            key={index}
                            style={{
                              textDecoration: 'none',
                              margin: ' 0px 0px 40px 0px'
                            }}
                            onClick={() => handleRouteSelect(route)}
                          >
                            <RouteName
                              selected={pathname.split('/')[4] === route?.shape_id}
                            >
                              {route.trip_headsign}
                            </RouteName>
                          </Link>
                        )
                      })}
                    </RoutesContainer>
                  </SwipeableDrawer>
                  <DataProviderModal
                    open={providerModal}
                    setOpen={setProviderModal}
                    description={t('routes.data_source_notes')}
                    provider={lineSelected?.agency?.provider}
                  />
                </>
                )
              : (
                <SkeletonContainer>
                  <Skeleton animation="wave" variant="rect" width={'100%'} height={'50%'}/>
                  <Skeleton
                    animation="wave"
                    width={'90%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'60%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'70%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'50%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'80%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'90%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'60%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'70%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                  <Skeleton
                    animation="wave"
                    width={'50%'}
                    height={'5%'}
                    style={{ marginBottom: '2px', borderRadius: '5px' }}
                  />
                </SkeletonContainer>
                )}
            {isIosApp &&
              <NavBar/>
            }
          </LineContainer>
          )
        : (
          <LineDesktop
            routeSelected={routeSelected}
            map={map}
            setProviderModal={setProviderModal}
            handleRouteSelect={handleRouteSelect}
            openRoutesList={openRoutesList}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleScheduleClick={handleScheduleClick}
            currentVehicles={currentVehicles}
            setCurrentVehicles={setCurrentVehicles}
          />
          )}
      <SendToPhoneModal/>
      <ShareModal/>
    </Layout>
  )
}

export default Line

export const StopTransfers = ({ transfers = [] }) => {
  if (transfers?.length === 0) return <></>

  const [stopTransfers, setStopTransfers] = useState([])

  useEffect(() => {
    const routesToTransfers = transfers?.map(transfer => transfer?.routes_to_transfer).flat()

    const groupedByRouteId = _.groupBy(routesToTransfers, 'route_id')

    const stopGropedByRouteId = Object?.keys(groupedByRouteId)?.map((key) => groupedByRouteId[key][0])

    setStopTransfers(stopGropedByRouteId)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: '15px',
        overflow: 'auto'
      }}
    >
      {stopTransfers?.map((route, index) => {
        const color = pickTextColorBasedOnBgColor(`#${route?.route_color}`, '#FFFFFF', '#000000')
        return (
          <Chip
            sx={{
              marginRight: '10px',
              fontWeight: 600,
              height: '28px',
              boxShadow: '0px 0px 18px -7px rgba(0, 0, 0, .30)',
              '& .MuiChip-label': {
                padding: '0 10px'
              },
              backgroundColor: `#${route?.route_color}`,
              color: color || '#fff'
            }}
            key={index}
            label={`${route?.route_short_name || route?.route_long_name}`}
          />
        )
      })}
    </Box>
  )
}

export const Map = ({
  map,
  routeSelected,
  handleClickStop,
  width = '100vw',
  height = '100vh',
  currentVehicles = []
}) => {
  const mobile = useMediaQuery('(max-width:480px)')
  const lineSelected = useSelector(state => state?.ui?.lineSelected)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  useEffect(() => {
    if (!routeSelected || !map) return

    const coordinates = routeSelected.points.map(point => new maplibregl.LngLat(point[1], point[0]))

    const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new maplibregl.LngLatBounds(coordinates[0], coordinates[0]))

    map.fitBounds(bounds, {
      padding: {
        top: mobile ? 16 : 96,
        left: mobile ? 16 : 486,
        right: 16,
        bottom: 16
      }
    })
  }, [routeSelected, map])

  return (
    <MapContainer
      width={width}
      height={height}
      showStopsNearby={false}
      showUserPosition={true}
      showMapDevices={true}
      geoControlProps={{
        styles: {
          bottom: mobile ? '46px' : '177px'
        }
      }}
    >
      <>
        {routeSelected &&
          <PolylineWithSenseArrow
            lineCoordinates={routeSelected?.points?.map((point) => [point[1], point[0]])}
            color={`#${lineSelected?.route_color}`}
          />
        }

        {routeSelected?.stops?.map((stop, index) => {
          return (
            <CustomMarker
              key={index}
              lng={stop?.stop_lon}
              lat={stop?.stop_lat}
              onClick={() => handleClickStop(stop)}
              icon={
                <div style={{
                  width: '14px',
                  height: '14px',
                  backgroundColor: '#fff',
                  borderRadius: '100%',
                  border: `3px solid #${lineSelected?.route_color}`
                }}/>
              }
            />
          )
        })}

        {routeSelected && lineSelected &&
          <>
            {currentVehicles.map((vehicle, index) => (
              <VehicleRealtime
                key={index}
                lat={vehicle.coordinates[1]}
                lng={vehicle.coordinates[0]}
                timestamp={vehicle.timestamp}
                icon={
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <img
                      style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '-10px',
                        width: '12px',
                        height: '12px'
                      }}
                      src={realtimeIcon}
                      alt='realtime icon'
                    />
                    <img
                      style={{
                        width: '25px',
                        height: '25px'
                      }}
                      src={cityConfig?.transport_types?.find(transport => transport.route_type === lineSelected?.route_type)?.icon}
                      alt='realtime vehicle icon'/>
                  </div>
                }
              />
            ))}
          </>
        }
      </>
    </MapContainer>
  )
}

const DataProvider = ({
  setProviderModal,
  lineSelected
}) => {
  const { t } = useTranslation()

  return (
    <Chip
      sx={{
        display: 'none',
        position: 'absolute',
        bottom: '16px',
        left: '16px',
        color: '#00aadc',
        zIndex: 401,
        backgroundColor: 'rgba(255,255,255,0.6)',
        '&:focus': {
          backgroundColor: 'rgba(255,255,255,0.6)'
        },
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.6)'
        },
        '@media (max-width:480px)': {
          display: 'flex'
        }
      }}
      size="small"
      clickable
      onClick={() => setProviderModal(true)}
      icon={<InfoRounded style={{ color: '#00aadc' }}/>}
      label={t('routes.data_provider', { prop1: lineSelected?.agency?.provider })}
    />
  )
}
