import { subscribeToTopic } from './subscribeToTopic'
import { unsubscribeToTopic } from './unsubscribeToTopic'

export const unsubscribeToCityTopics = async (oldCity, newCity, fcmToken) => {
  await unsubscribeToTopic(`city_${oldCity}`, fcmToken)
  await unsubscribeToTopic(`web_city_${oldCity}`, fcmToken)
  await unsubscribeToTopic(`city_${oldCity}_variant_${process.env.REACT_APP_VARIANT_ID}`, fcmToken)
  await unsubscribeToTopic(`web_city_${oldCity}_variant_${process.env.REACT_APP_VARIANT_ID}`, fcmToken)
  await unsubscribeToTopic(`alerts_${oldCity}`, fcmToken)
  await unsubscribeToTopic(`web_alerts_${oldCity}`, fcmToken)
  await unsubscribeToTopic(`ack_city_${oldCity}`, fcmToken)
  await unsubscribeToTopic(`web_ack_city_${oldCity}`, fcmToken)

  if (fcmToken) {
    await subscribeToTopic(`city_${newCity}`, fcmToken)
    await subscribeToTopic(`web_city_${newCity}`, fcmToken)
    await subscribeToTopic(`city_${newCity}_variant_${process.env.REACT_APP_VARIANT_ID}`, fcmToken)
    await subscribeToTopic(`web_city_${newCity}_variant_${process.env.REACT_APP_VARIANT_ID}`, fcmToken)
    await subscribeToTopic(`alerts_${newCity}`, fcmToken)
    await subscribeToTopic(`web_alerts_${newCity}`, fcmToken)
    await subscribeToTopic(`ack_city_${newCity}`, fcmToken)
    await subscribeToTopic(`web_ack_city_${newCity}`, fcmToken)
  }

  if (localStorage.getItem('linesFavs') && fcmToken) {
    const oldLines = JSON.parse(localStorage.getItem('linesFavs')).filter(line => line.cityId === oldCity)
    const newLines = JSON.parse(localStorage.getItem('linesFavs')).filter(line => line.cityId === newCity)
    if (oldLines.length !== 0) {
      oldLines.map((line) => {
        unsubscribeToTopic(`city_${oldCity}_line_${line.lineId}`, fcmToken)
        unsubscribeToTopic(`web_city_${oldCity}_line_${line.lineId}`, fcmToken)
      })
    }

    if (newLines.length !== 0) {
      newLines.map((line) => {
        subscribeToTopic(`city_${newCity}_line_${line.lineId}`, fcmToken)
        subscribeToTopic(`web_city_${newCity}_line_${line.lineId}`, fcmToken)
      })
    }
  }
} // unsubscribe current city to topics
