import { addDoc, collection } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'
import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'

/** addUserLine()
 * @description Add a new user line to the database
 * @param {object} lineData
 * @returns {Promise<*&{id: string}>}
 */

export const addUserLine = async (lineData) => {
  const auth = getAuth()
  const user = auth.currentUser

  if (lineData) {
    try {
      const docRef = await addDoc(collection(db, `users/${user?.uid}/lines`), lineData)
      console.log('Line was added successfully!', docRef.id)
      return {
        ...lineData,
        id: docRef.id
      }
    } catch (error) {
      alert('Se produjo un error al agregar la linea')
      console.log('Error adding document: ' + error)
    }
  } else {
    console.log('Error adding line: No line data provided')
  }
}

addUserLine.propTypes = {
  lineData: PropTypes.object.isRequired
}
