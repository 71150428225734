import { useLocation } from 'react-router-dom'

function useQuery () {
  return new URLSearchParams(useLocation().search)
}

export default useQuery

const searchParams = new URLSearchParams(window.location.search)

export const updateQueryParams = (name, value) => {
  searchParams.set(name, value)
  const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
  history.pushState(null, '', newRelativePathQuery)
}
