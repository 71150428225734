import styled from 'styled-components/macro'
import Box from '@mui/material/Box'

export const ShareContentContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
box-sizing: border-box;
padding: 32px 24px;
`

export const QRCodeContainer = styled.div`
position: relative;
margin-right: 24px;
`

export const QRCodeHelpContainer = styled.div`

`
export const QRCodeLogo = styled.img`
width: 25px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`

export const HelpText = styled.p`
font-family: 'Visby Round';
font-size: 14px;
line-height: 17px;
letter-spacing: 0.02em;
color: #525253;
margin: 0;
margin-bottom: 16px;
`

export const ModalContainer = styled(Box)`
   position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 528px;
    background-color: #ffffff;
    border-radius: 14px;
    box-sizing: border-box;
`

export const FooterText = styled.p`
font-family: 'Visby Round';
font-size: 12px;
letter-spacing: 0.02em;
color: #525253;
margin: 0;
margin-bottom: 24px;
margin-left: 24px; 
`

export const SendToMyPhoneContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
box-sizing: border-box;
padding: 20px 24px;
`

export const DividerContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
box-sizing: border-box;
padding: 0px 24px;
`
