import React from 'react'
import { BlogIcon, CityIcon, CompanyIcon, LogOutIcon, UserIcon } from '../components/Desktop/Menu/styles'
import logOutIcon from '../img/desktop/logoutIcon.svg'
import { useTranslation } from 'react-i18next'
import { Facebook, Instagram, Twitter } from '@mui/icons-material'
import { logEvent } from '../firebase/firebase-config'
import { useSelector } from 'react-redux'

export const useMenu = () => {
  const { t } = useTranslation()

  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const menuItems = [
    {
      text: t('home.menu.items.our_company'),
      icon: <CompanyIcon variant='secondary.main '/>,
      link: 'https://ualabee.com/empresa',
      type: 'menu',
      enabled: JSON.parse(process.env.REACT_APP_SHOW_MENU_ITEMS || 'true')
    },
    {
      text: t('home.menu.items.become_a_contributor'),
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.951" viewBox="0 0 25 24.951">
        <g id="people" transform="translate(0 -0.501)">
          <path id="Trazado_562" data-name="Trazado 562" className="cls-1"
                d="M169.619,10.708h7.131a.7.7,0,0,0,.7-.7V9.238a3.74,3.74,0,0,0-3.774-3.748,2.508,2.508,0,1,0-.985,0,3.74,3.74,0,0,0-3.774,3.748V10A.7.7,0,0,0,169.619,10.708Z"
                transform="translate(-160.67)"
                fill={process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR}/>
          <path id="Trazado_563" data-name="Trazado 563" className="cls-1"
                d="M52.815,112.036a.73.73,0,0,0,.851-.591,9.773,9.773,0,0,1,2.785-5.216.733.733,0,0,0-1.015-1.058,11.244,11.244,0,0,0-3.209,6.01.734.734,0,0,0,.589.854Z"
                transform="translate(-49.665 -99.364)"
                fill={process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR}/>
          <path id="Trazado_564" data-name="Trazado 564" className="cls-1"
                d="M371.016,108.573a9.647,9.647,0,0,1,1.014,2.87.732.732,0,1,0,1.44-.263,11.244,11.244,0,0,0-3.209-6.01.729.729,0,0,0-1.034.019.737.737,0,0,0,.019,1.039A9.631,9.631,0,0,1,371.016,108.573Z"
                transform="translate(-351.003 -99.362)"
                fill={process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR}/>
          <path id="Trazado_565" data-name="Trazado 565" className="cls-1"
                d="M183.463,363.961a9.514,9.514,0,0,1-2.956.475h-.04a9.566,9.566,0,0,1-2.9-.444.734.734,0,0,0-.441,1.4,11.014,11.014,0,0,0,3.339.512h.04a11,11,0,0,0,3.412-.549.734.734,0,0,0-.457-1.4Z"
                transform="translate(-167.993 -345.677)"
                fill={process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR}/>
          <path id="Trazado_566" data-name="Trazado 566" className="cls-1"
                d="M4.759,307.431a2.508,2.508,0,1,0-.985,0A3.74,3.74,0,0,0,0,311.179v.767a.7.7,0,0,0,.7.7H7.831a.7.7,0,0,0,.7-.7v-.767a3.739,3.739,0,0,0-3.774-3.749Z"
                transform="translate(0 -287.198)"
                fill={process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR}/>
          <path id="Trazado_567" data-name="Trazado 567" className="cls-1"
                d="M342.016,307.431a2.508,2.508,0,1,0-.985,0,3.739,3.739,0,0,0-3.774,3.748v.767a.7.7,0,0,0,.7.7h7.131a.7.7,0,0,0,.7-.7v-.767A3.74,3.74,0,0,0,342.016,307.431Z"
                transform="translate(-320.789 -287.198)"
                fill={process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR}/>
        </g>
      </svg>,
      link: 'https://ualabee.com/colaboradores',
      sendEvent: () => {
        const params = {
          os: 'web',
          lat: userPosition?.lat ? userPosition.lat : null,
          lng: userPosition?.lng ? userPosition.lng : null,
          city_id: cityConfig?.city_id?.toString(),
          user_id: userData.uid,
          user_gender: userData.gender
        }

        // Send events to analytics
        logEvent('collab_cta_click', params)
      },
      type: 'menu',
      enabled: JSON.parse(process.env.REACT_APP_SHOW_MENU_ITEMS || 'true')
    },
    {
      text: t('home.menu.items.blog'),
      icon: <BlogIcon variant='secondary.main '/>,
      link: 'https://ualabee.com/comunidad',
      type: 'menu',
      enabled: JSON.parse(process.env.REACT_APP_SHOW_MENU_ITEMS || 'true')
    },
    {
      text: t('home.menu.items.your_city'),
      icon: <CityIcon variant='secondary.main '/>,
      link: 'https://ualabee.com/index',
      type: 'menu',
      enabled: JSON.parse(process.env.REACT_APP_SHOW_MENU_ITEMS || 'true')
    },
    {
      icon: <Facebook sx={{ color: 'primary.main ' }}/>,
      link: process.env.REACT_APP_CUSTOM_MENU_FACEBOOK_LINK || 'https://www.facebook.com/ualabee',
      type: 'social-media',
      enabled: true
    },
    {
      icon: <Instagram sx={{ color: 'primary.main ' }}/>,
      link: process.env.REACT_APP_CUSTOM_MENU_INSTAGRAM_LINK || 'https://www.instagram.com/ualabee_latam',
      type: 'social-media',
      enabled: true
    },
    {
      icon: <Twitter sx={{ color: 'primary.main ' }}/>,
      link: process.env.REACT_APP_CUSTOM_MENU_TWITTER_LINK || 'https://twitter.com/ualabee_ar',
      type: 'social-media',
      enabled: true
    }
  ]

  const accountItems = [
    {
      text: 'Mi cuenta',
      icon: <UserIcon/>
    },
    {
      text: 'Cerrar sesión',
      icon: <LogOutIcon src={logOutIcon}/>
    }
  ]

  return {
    menuItems,
    accountItems
  }
}
