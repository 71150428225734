import { getApps, initializeApp } from 'firebase/app'
import { getAnalytics, logEvent as logEvents, setUserProperties as setFirebaseUserProperties } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { getFirestore } from 'firebase/firestore'
import { getMessaging, isSupported } from 'firebase/messaging'
import { getRemoteConfig } from 'firebase/remote-config'
import amplitude from 'amplitude-js'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/messaging'

// https://firebase.google.com/docs/web/setup#available-libraries

// W web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

let app = null

if (getApps().length === 0) {
  app = initializeApp(firebaseConfig)
  firebase.initializeApp(firebaseConfig)
}
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app)

// Initialize Performance Monitoring and get a reference to the service
const performance = getPerformance(app)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

// Initialize Firebase Cloud Messaging and get a reference to the service
let messaging = null

isSupported()
  .then((canInit) => {
    if (canInit) {
      messaging = getMessaging(app)
    }
  })
  .catch(() => {
    console.log('messaging not supported')
  })

// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app)

const showSmartBanners = !process.env.REACT_APP_WHITE_LABEL && !navigator?.userAgent?.includes('UalabeeLite=true') && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

remoteConfig.defaultConfig = {
  show_captive_portal: showSmartBanners,
  smart_banner_top_cta_url: 'https://links.ualabee.com/l/webToApp',
  smart_banner_bottom_cta_url: 'https://links.ualabee.com/l/webToApp',
  captive_portal_cta_url: 'https://links.ualabee.com/l/webToApp',
  show_smart_banner_bottom: showSmartBanners,
  show_smart_banner_top: showSmartBanners,
  places_autocomplete_provider: 'photon_ualabee',
  places_autocomplete_provider_limit: '[{"id":"photon_ualabee","limit":5}]',
  contribution_history_count: 10
}

remoteConfig.settings.minimumFetchIntervalMillis = 3600000

// Initialize Amplitude
amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY)
const identify = new amplitude.Identify()
amplitude.getInstance().identify(identify)

const setUserProperties = (props) => {
  setFirebaseUserProperties(analytics, props)
  amplitude.getInstance().setUserProperties(props)
}

const logEvent = (eventName, params) => {
  const isIosApp = navigator.userAgent.includes('UalabeeLite=true')

  const eventParams = {
    ...params,
    os: isIosApp ? 'ios' : 'web'
  }

  logEvents(analytics, eventName, eventParams)
  amplitude.getInstance().logEvent(eventName, eventParams)
}

export {
  analytics,
  db,
  setUserProperties,
  logEvent,
  performance,
  messaging,
  remoteConfig,
  app
}
