import React from 'react'
import './firebase/firebase-config'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components'
import { theme } from './theme/theme'
import './index.css'
import { CssBaseline } from '@mui/material'
import Amplify from '@aws-amplify/core'
import awsExports from './aws-exports'
import Auth from '@aws-amplify/auth'
import { App } from './App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://d4168672af3b4e73aa06a395fe56d211@o4504459030691840.ingest.sentry.io/4504459115298816',
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT
})

Amplify.configure(awsExports)
Auth.configure({ mandatorySignIn: false })

ReactDOM.render(
  <ThemeProvider theme={{
    ...theme,
    palette: {
      ...theme.palette,
      text: {
        primary: {
          main: process.env.REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR
        },
        secondary: {
          main: process.env.REACT_APP_PALLET_TEXT_SECONDARY_MAIN_COLOR,
          dark: process.env.REACT_APP_PALLET_TEXT_SECONDARY_DARK_COLOR
        }
      }
    }
  }}>
    <CssBaseline/>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <App/>
      </Provider>
    </MuiThemeProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
