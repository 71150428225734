import styled from 'styled-components'
import { styled as materialStyled } from '@mui/material/styles'
import { Button as ButtonBase, TextField } from '@mui/material'
import { ErrorRounded } from '@mui/icons-material'
import { theme } from '../../theme/theme'
import LoadingButton from '@mui/lab/LoadingButton'

export const Container = styled.div`  
    width: 352px;
    background: #FAFAFA;
    border-radius: 12px;
    margin: 40px;
`

export const Content = styled.div`
    padding: 32px;
    min-height: 320px;
    box-sizing: border-box;
`

export const Title = styled.h3`    
    font-style: normal;
    font-weight: 500;
    font-size: 16px; 
    color: #222831; 
    margin: 0;
    text-align: center;
    height: 10%;
`

export const Form = styled.form`   
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 90%;
`

export const Icon = styled.img`    
    width: 18px;
    height: 18px; 
    margin: 0 12px 0 0;
`

export const Text = styled.p`   
    margin: 0;  
`

export const Inputs = styled.div` 
    margin-bottom: 20px;  
`

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    text-align: center;
    font-family: ${theme.typography.fontFamily};
`

export const Input = styled(TextField)({
  width: '100%',
  margin: '5px 0 0 0',
  '& label': {
    color: '#8C8C8C'
  },
  '& .MuiInput-root:before': {
    borderBottomColor: '#8C8C8C'
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#F46262'
  },
  '&:hover': {
    border: 'none'
  },
  '& label.Mui-focused': {
    color: '#8C8C8C'
  },
  '& label.Mui-error': {
    color: '#8C8C8C !important'
  },
  input: {
    color: '#222831',
    fontSize: 16
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#E8E8E8 !important',
    fontSize: 14,
    input: {
      padding: 0
    },
    '&:hover:not($disabled):not($focused):not($error):before': {
      borderBottom: '2px solid red'
    }
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: '#E8E8E8 !important'
  },
  '& .MuiTextField-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'green'
    }
  }
})

export const Button = materialStyled(ButtonBase)({
  fontSize: 14,
  width: 288,
  height: 40,
  backgroundColor: '#FAFAFA',
  border: '1px solid #E8E8E8',
  borderRadius: 14,
  color: '#222831',
  margin: '0 0 20px 0',
  '&:hover': {
    backgroundColor: '#FAFAFA'
  },
  '&:last-child': {
    margin: 0
  }
})

export const Create = materialStyled(LoadingButton)({
  fontSize: 14,
  width: 128,
  height: 40,
  backgroundColor: theme.palette.primary.main,
  border: '1px solid #E8E8E8',
  borderRadius: 14,
  color: '#ffffff',
  margin: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  },
  '&.Mui-disabled': {
    backgroundColor: '#F2F2F2',
    color: '#BCBEC0'
  }
})

export const Cancel = materialStyled(ButtonBase)({
  fontSize: 14,
  height: 40,
  backgroundColor: 'none',
  border: '1px solid #E8E8E8',
  borderRadius: 14,
  color: theme.palette.primary.main,
  margin: '0 20px 0 0',
  '&:hover': {
    backgroundColor: 'none'
  }
})

export const Error = materialStyled(ErrorRounded)({
  color: '#F46262'
})

export const ErrorMessage = styled.span`
font-size: 12px;
color: #F46262;
margin: 0 0 0 10px;
`
