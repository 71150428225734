import styled from 'styled-components/macro'
import { Button, IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'

export const TitleContainer = styled.div`   
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Title = styled.h1`  
    margin: 0;
    color: ${props => props.theme.palette.primary.main};
    font-size: 18px;
    font-weight: 500; 
    font-family: 'Visby Round';
    &:first-letter {
        text-transform: uppercase;
    }
`

export const CloseButton = styled(IconButton)`
`

export const CloseIcon = styled(CloseRounded)`
    color: ${props => props.theme.palette.icons.secondary.main}; 
`

export const ContentContainer = styled.div`
    display: flex;
    padding-top: 16px;
`

export const Image = styled.div`
    background-image: url(${props => props?.src && props?.src}); 
    width: 64px;
    min-width: 64px;
    height: 64px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 8px;
`

export const Content = styled.p` 
    margin: 0; 
    color: ${props => props.theme.palette.text.primary.main};
    font-family: 'Visby Round';
    &:first-letter {
        text-transform: uppercase;
    }
`

export const ContentButton = styled(Button)`
    border-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.text.primary.main};
`

export const CreationDate = styled.p` 
    margin: 0;
    color: ${props => props.theme.palette.text.secondary.main};
    text-align: end;
    font-size: 12px;
    text-align: end;
    font-family: 'Visby Round';
    width: 100%;

    &:first-letter {
        text-transform: uppercase;
    }
`

export const UserName = styled.span`
    color: ${props => props.theme.palette.text.primary.main};
    font-size: 12px;
    text-align: end;
    font-family: 'Visby Round';
    text-transform: capitalize;
`

export const ActionButtonsContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
box-sizing: border-box;
`
