import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'
import { getAuth } from 'firebase/auth'

/** getUserSavedPlaces()
 * @description Get the user saved places
 * @returns {Promise<*[]>}
 */

export const getUserSavedPlaces = async () => {
  const auth = getAuth()
  const user = auth.currentUser

  const savedPlaces = []

  if (user) {
    const q = query(collection(db, '/global/users/saved_places/'), where('user_id', '==', user?.uid))

    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      savedPlaces.push({
        id: doc.id,
        ...doc.data()
      })
    })
  } else {
    console.log('Error getting user saved places. User not logged in')
  }

  return savedPlaces
}
