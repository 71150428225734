import styled from 'styled-components/macro'

export const ResultsContainer = styled.div`
    height: 100%;
    overflow: auto;
    padding: 16px 16px 0 16px;
    border-radius: 16px; 
    background-color: #fff;
`

export const Container = styled.div` 
`

export const Title = styled.p`
    padding: 16px 6px 16px 0;
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Visby Round';
    color: ${props => props.theme.palette.text.primary.main};
    border-bottom: 1px solid #E8EDEE;
    &:first-child {
        padding-top: 0;
    }
`
