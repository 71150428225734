import React from 'react'
import { Box, Button } from '@mui/material'
import { getAuth, sendEmailVerification } from 'firebase/auth'
import { handleOpenAlert } from '../../actions/ui'
import { useDispatch } from 'react-redux'

const ResendVerificationEmail = () => {
  const dispatch = useDispatch()
  const auth = getAuth()
  const user = auth.currentUser

  const handleSendVerificationEmail = () => {
    sendEmailVerification(user)
      .then(() => {
        dispatch(handleOpenAlert({
          open: true,
          severity: 'success',
          duration: 3000,
          title: `Email sent to ${user.email}`
        }))
      }).catch(error => {
        dispatch(handleOpenAlert({
          open: true,
          severity: 'error',
          duration: 3000,
          title: error.message
        }))
      })
  }

  return (
    user?.email && !user?.emailVerified
      ? <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '24px',
        backgroundColor: '#F5F7FA',
        borderRadius: '14px',
        fontFamily: 'Visby Round',
        padding: '8px'
      }}
      >
        Your email is not verified.
        <Button
          onClick={handleSendVerificationEmail}
        >Resend email</Button>
      </Box>
      : null
  )
}

export default ResendVerificationEmail
