import { useEffect, useState } from 'react'
import { getQuestion } from '../db/getQuestion'
import { useDispatch, useSelector } from 'react-redux'
import { logEvent } from '../firebase/firebase-config'
import { handleOpenAlert } from '../actions/ui'
import { useTranslation } from 'react-i18next'

export const useFeedback = (questionId, screen, closeBarFunction) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const timestamp = useSelector(state => state?.ui?.timestamp)
  const uid = useSelector(state => state?.user?.auth?.uid)

  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState(null)
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [showBadge, setShowBadge] = useState(false)

  /* It's checking if the user has seen the onboarding modal before, and if they haven't, it shows the badge. */
  useEffect(() => {
    const showFeedbackOnboarding = localStorage.getItem('showFeedbackOnboarding')
    if (showFeedbackOnboarding === 'false') {
      setShowBadge(false)
    } else {
      setShowBadge(true)
    }
  }, [])

  const getFormQuestions = async () => {
    const params = {
      cityId: cityConfig?.city_id,
      questionId
    }
    return await getQuestion(params)
  }

  /**
     * When the feedback button is clicked, we send an event, then we get the form questions from the backend,
     * then we check if the user has seen the onboarding modal before, and if they haven't, we show the onboarding modal
     * @param eventParams - This is an object with the event parameters
     */
  const handleOpen = async (eventParams) => {
    setLoading(true)
    sendEvent('feedback_button_clicked', eventParams)
    try {
      const questions = await getFormQuestions()
      setQuestions(questions)
      const showFeedbackOnboarding = localStorage.getItem('showFeedbackOnboarding')
      if (showFeedbackOnboarding === 'false') {
        setOnboardingModalOpen(false)
        if (questions) {
          setLoading(false)
          setFormOpen(true)
        }
      } else {
        setLoading(false)
        setOnboardingModalOpen(true)
      }
    } catch (error) {
      console.log('error', error)
      setLoading(false)
      dispatch(handleOpenAlert({
        open: true,
        severity: 'error',
        duration: 3000,
        title: t('feedback_form.no_questions_error_title'),
        message: t('feedback_form.no_questions_error_message')
      }))
      if (closeBarFunction) {
        closeBarFunction()
      }
    }
  }

  const handleOnboardingClose = () => {
    setOnboardingModalOpen(false)
    localStorage.setItem('showFeedbackOnboarding', 'false')
    setShowBadge(false)
    setFormOpen(true)
  }

  /**
     * It sends an event to Firebase Analytics with the event name, screen name, and unique params
     * @param eventName - The name of the event you want to send.
     * @param eventParams - an object with the params you want to send.
     */
  const sendEvent = (eventName, eventParams) => {
    const services = {
      SEARCH_RESULT_DETAILS: 'SearchResultDetails',
      SEARCH_RESULTS: 'SearchResults',
      STOP: 'Stop'
    }
    // event name, screen name, unique params

    const params = {
      feedback_button_clicked: {
        SEARCH_RESULT_DETAILS: {
          duration: eventParams?.duration,
          start_time: eventParams?.startTime,
          walk_distance: eventParams?.walkDistance,
          waiting_time: eventParams?.waitingTime,
          end_time: eventParams?.endTime,
          walk_time: eventParams?.walkTime,
          transfers: eventParams?.transfers,
          is_realtime: eventParams?.isRealtime,
          transit_time: eventParams?.transitTime,
          search_uuid: uid + '_' + `${timestamp || new Date().getTime()}`
        },
        SEARCH_RESULTS: {
          search_uuid: uid + '_' + `${timestamp || new Date().getTime()}`
        },
        STOP: {
          stop_id: eventParams?.stopId,
          stop_code: eventParams?.stopCode,
          stop_lat: eventParams?.stopLat,
          stop_lon: eventParams?.stopLon,
          stop_name: eventParams?.stopName,
          trips_count: eventParams?.tripsCount
        },
        all: {
          from: services[screen],
          is_first_time_clicked: localStorage.getItem('showFeedbackOnboarding') !== 'false'
        }
      },
      close_feedback_button: {
        SEARCH_RESULT_DETAILS: {},
        SEARCH_RESULTS: {},
        STOP: {},
        all: {
          from: services[screen]
        }
      },
      feedback_sent: {
        SEARCH_RESULT_DETAILS: {
          duration: eventParams?.duration,
          start_time: eventParams?.startTime,
          walk_distance: eventParams?.walkDistance,
          waiting_time: eventParams?.waitingTime,
          end_time: eventParams?.endTime,
          walk_time: eventParams?.walkTime,
          transfers: eventParams?.transfers,
          is_realtime: eventParams?.isRealtime,
          transit_time: eventParams?.transitTime,
          search_uuid: uid + '_' + `${timestamp || new Date().getTime()}`
        },
        SEARCH_RESULTS: {
          search_uuid: uid + '_' + `${timestamp || new Date().getTime()}`
        },
        STOP: {
          stop_id: eventParams?.stopId,
          stop_code: eventParams?.stopCode,
          stop_lat: eventParams?.stopLat,
          stop_lon: eventParams?.stopLon,
          stop_name: eventParams?.stopName,
          trips_count: eventParams?.tripsCount
        },
        all: {
          selected_option_text: eventParams?.selectedOptionText,
          feedback: eventParams?.feedback,
          selected_option_id: eventParams?.selectedOptionId,
          from: services[screen]
        }
      }
    }

    const userParams = {
      lat: userPosition?.lat || null,
      lon: userPosition?.lng || null,
      user_gender: userData?.gender || null,
      user_birthday_timestamp: userData?.birthday?.long_value || null,
      user_id: uid,
      city_id: cityConfig?.city_id.toString()
    }

    const paramsObj = {
      ...params[eventName][screen],
      ...params[eventName].all,
      ...userParams
    }

    logEvent(eventName, paramsObj)
  }

  return {
    loading,
    questions,
    onboardingModalOpen,
    formOpen,
    setFormOpen,
    successModalOpen,
    setSuccessModalOpen,
    handleOpen,
    handleOnboardingClose,
    showBadge,
    sendEvent
  }
}
