import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Chip, useMediaQuery, IconButton, Skeleton
} from '@mui/material'
import { CloseRounded, LocationOnRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { handleCloseAd } from '../../../actions/ui'

/** Ad
 * @description The component is displayed when the user click on an ad marker in the map
 *
 * Initial state:
 *    - isBackgroundImageLoadad: false
 *
 * Methods:
 *    - onLoadBackgroundImage(): It executes when img element has been loaded and the user can see the ad background image. Otherwise, the skeleton is displayed.
 *    - handleLearnMore(): It executes the user click on "Learn more" button. It's opens the link action_uri obtained from ad object.
 *    - handleClose(): It closes the ad dialog and sets the state isBackgroundImageLoadad in false again.
 * @returns {JSX.Element}
 * @constructor
 */
export const Ad = () => {
  const { t } = useTranslation()
  const mobile = useMediaQuery('(max-width:480px)')
  const dispatch = useDispatch()

  const open = useSelector(state => state?.ui?.ads?.openAdSelected)
  const ad = useSelector(state => state?.ui?.ads?.adSelected)

  const [isBackgroundImageLoadad, setIsBackgroundImageLoaded] = useState(false)

  // onLoadBackgroundImage function executes when img element has been loaded and the user can see the ad background image. Otherwise, the skeleton is displayed.
  const onLoadBackgroundImage = () => {
    setIsBackgroundImageLoaded(true)
  }

  // handleLearnMore function executes the user click on "Learn more" button. It's opens the link action_uri obtained from ad object.
  const handleLearnMore = () => {
    // TODO: send event
    window.open(ad.action_uri, mobile ? '_self' : '_blank')
    dispatch(handleCloseAd())
  }

  // handleClose function closes the ad dialog and sets the state isBackgroundImageLoadad in false again.
  const handleClose = () => {
    // TODO: send event
    dispatch(handleCloseAd())
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        dispatch(handleCloseAd())
        setIsBackgroundImageLoaded(false)
      }}
      PaperProps={{
        style: {
          borderRadius: '16px',
          width: '80%'
        }
      }}
    >

      <Box
        sx={{
          position: 'relative',
          height: '40%',
          width: '100%',
          minHeight: '200px'
        }}
      >
        <img
          src={ad?.background_picture_uri}
          alt={'ad background image'}
          onLoad={onLoadBackgroundImage}
          style={{
            height: '100%',
            minHeight: '200px',
            width: '100%'
          }}
        />

        {!isBackgroundImageLoadad &&
                    <Skeleton
                      sx={{
                        position: 'absolute',
                        top: 0,
                        borderRadius: '8px 8px 0 0',
                        height: '100%',
                        width: '100%'
                      }}
                      variant="rectangular"
                    />
        }

      </Box>

      {!mobile &&
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px'
                  }}
                  onClick={handleClose}
                >
                  <CloseRounded variant={'secondary.main'}/>
                </IconButton>
      }
      <DialogTitle sx={{ textAlign: 'center' }}>{ad?.marker?.name}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '16px 16px 0 16px'
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {ad?.marker?.description}
        </DialogContentText>
        <Chip
          sx={{
            fontSize: '16px',
            backgroundColor: '#ffbb2c',
            color: '#fff',
            margin: '16px 16px 0 16px',
            '& .MuiChip-icon': {
              color: '#fff'
            }
          }}
          label={ad?.marker?.address}
          icon={<LocationOnRounded sx={{ marginRight: '8px' }}/>}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px'
        }}
      >
        <Button onClick={handleLearnMore} autoFocus>
          {t('learn_more')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
