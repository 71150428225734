import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ButtonBase, IconButton, Typography } from '@mui/material'
import { ArrowBackRounded } from '@mui/icons-material'
import 'firebaseui/dist/firebaseui.css'
import Logo from '../../img/ualabeelogo.svg'
import './login.css'
import { ButtonsContainer, DisclaimerContainer, LegalLink, LoginContainer, LogoUalabee } from './styles'
import NavBar from '../NavBar'
import { Layout } from '../../uicomponents/layouts/Layout'
import { a, useSpring } from '@react-spring/web'
import {
  applyActionCode,
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider
} from 'firebase/auth'
import { startLoginWithProvider } from '../../actions/user'
import { EmailSignIn } from '../EmailSignIn'
import { PhoneSignIn } from '../PhoneSignIn'
import { createContact } from '../../db/createContact'
import { handleOpenAlert } from '../../actions/ui'
import CircularProgress from '@mui/material/CircularProgress'

const Login = () => {
  function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const auth = getAuth()
  const { t } = useTranslation()

  const userData = useSelector(state => state?.user?.userData)

  const [loginButtons, setLoginButtons] = useState(true)
  const [formMode, setFormMode] = useState('')
  const [loading, setLoading] = useState(false)
  const [buttonSelected, setButtonSelected] = useState('')

  useEffect(() => {
    const mode = query.get('mode')
    const actionCode = query.get('oobCode')
    const email = query.get('email')

    if (mode === 'verifyEmail' && actionCode && email) {
      applyActionCode(auth, actionCode).then(() => {
        dispatch(handleOpenAlert({
          open: true,
          severity: 'success',
          duration: 3000,
          title: t('login.email_verification_success_title'),
          message: t('login.email_verification_success_message')
        }))
        createContact({ email }).then(() => {
          history.push('/')
        })
      }).catch((error) => {
        console.log(error)
        dispatch(handleOpenAlert({
          open: true,
          severity: 'error',
          duration: 3000,
          title: t('login.email_verification_failure_title'),
          message: t('login.email_verification_failure_message')
        }))
        history.push('/login')
      })
    } else if (!auth?.currentUser?.isAnonymous && !loading && auth?.currentUser?.displayName) {
      history.push('/')
    }
  }, [userData])

  useEffect(() => {
    const provider = query.get('provider')
    if (provider && auth) {
      setLoading(true)
      setButtonSelected(provider)
      getRedirectResult(auth)
        .then(() => {
          history.push('/')
        }).catch((error) => {
          setLoading(false)
          setButtonSelected('')
          dispatch(handleOpenAlert({
            open: true,
            severity: 'error',
            duration: 3000,
            title: error.message
          }))
        })
    }
  }, [auth])

  const { transform, opacity } = useSpring({
    opacity: loginButtons ? 1 : 0,
    transform: `perspective(600px) rotateX(${loginButtons ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  })

  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  const buttons = [
    {
      icon: '/img/google-icon.svg',
      text: t('login.social_button_title', { prop1: 'Google' }),
      function: () => {
        setLoading(true)
        history.push('/login?provider=auth-google')
        const provider = new GoogleAuthProvider()
        dispatch(startLoginWithProvider(provider, history, setLoading))
      },
      id: 'auth-google',
      bgColor: '#e75a37',
      color: '#fff',
      enabled: true
    },
    {
      icon: '/img/facebook-icon.svg',
      text: t('login.social_button_title', { prop1: 'Facebook' }),
      function: () => {
        setLoading(true)
        history.push('/login?provider=auth-facebook')
        const provider = new FacebookAuthProvider()
        dispatch(startLoginWithProvider(provider, history, setLoading))
      },
      id: 'auth-facebook',
      bgColor: '#3B5998',
      color: '#fff',
      enabled: !isIosApp
    },
    {
      icon: '/img/apple-icon.svg',
      text: t('login.social_button_title', { prop1: 'Apple' }),
      function: () => {
        setLoading(true)
        history.push('/login?provider=auth-apple')
        const provider = new OAuthProvider('apple.com')
        provider.addScope('email')
        provider.addScope('name')
        dispatch(startLoginWithProvider(provider, history, setLoading))
      },
      id: 'auth-apple',
      bgColor: '#000000',
      color: '#fff',
      enabled: true
    },
    {
      icon: '/img/phone-icon.svg',
      text: t('login.phone_button_title'),
      function: () => {
        setFormMode('phone')
        setLoginButtons(false)
      },
      id: 'auth-phone',
      bgColor: '#15a806',
      color: '#fff',
      enabled: true
    },
    {
      icon: '/img/mail-icon.svg',
      text: t('login.email_button_title'),
      function: () => {
        setFormMode('email')
        setLoginButtons(false)
      },
      id: 'auth-email',
      bgColor: '#565555',
      color: '#fff',
      enabled: true
    }
  ]

  const handleClick = (button) => {
    setButtonSelected(button.id)
    button.function()
  }

  return (
    <Layout>
      <LoginContainer>
        <IconButton
          onClick={() => history.push('/')}
          sx={{
            position: 'absolute',
            top: '16px',
            left: '16px',
            backgroundColor: 'transparent',
            zIndex: 401
          }}
        >
          <ArrowBackRounded style={{ color: '#A6A9AC' }}/>
        </IconButton>
        <LogoUalabee src={process.env.REACT_APP_HARDCODED_LOGO || Logo} alt='Ualabee'/>

        <ButtonsContainer>
          <Typography
            variant='h1'
            sx={{
              fontFamily: 'Visby Round',
              fontSize: '24px',
              color: '#000000',
              margin: '0',
              marginBottom: '16px'
            }}
          >{t('login.title')}</Typography>
          {loginButtons
            ? (
              <a.div
                style={{
                  opacity,
                  transform,
                  rotateX: '180deg',
                  width: '352px',
                  minHeight: '320px',
                  background: '#FAFAFA',
                  borderRadius: '12px',
                  padding: '16px'
                }}
              >
                {buttons.map(button => {
                  if (button.enabled) {
                    return (
                      <ButtonBase
                        loading={loading && buttonSelected === button.id}
                        onClick={() => handleClick(button)}
                        key={button.id}
                        sx={{
                          padding: '0 16px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          fontSize: 14,
                          width: '100%',
                          height: 40,
                          backgroundColor: loading ? '#d7d7d7' : button.bgColor,
                          border: '1px solid #E8E8E8',
                          borderRadius: 14,
                          color: '#222831',
                          margin: '0 0 20px 0',
                          gap: '10px',
                          '&:hover': {
                            backgroundColor: loading ? '#d7d7d7' : button.bgColor,
                            opacity: 0.8
                          },
                          '&:last-child': {
                            margin: 0
                          }
                        }}>
                        <>
                          {loading && buttonSelected === button.id
                            ? <CircularProgress size={20} style={{ color: '#fff' }}/>
                            : <img alt={button.id} src={button.icon} width='20px' height='auto'/>
                          }
                          <Typography
                            sx={{
                              color: button.color,
                              fontSize: 14,
                              fontWeight: 600,
                              fontFamily: 'Visby Round'
                            }}
                          >
                            {button.text}
                          </Typography>
                        </>
                      </ButtonBase>
                    )
                  }
                })}
              </a.div>
              // eslint-disable-next-line
            )
            : (
              <a.div
                style={{
                  opacity: opacity.to(o => 1 - o),
                  transform,
                  width: '352px',
                  minHeight: '320px',
                  background: '#FAFAFA',
                  borderRadius: '12px',
                  padding: '16px'
                }}
              >
                {formMode === 'email' && <EmailSignIn setLoginButtons={setLoginButtons}/>}
                {formMode === 'phone' && <PhoneSignIn setLoginButtons={setLoginButtons} setLoading={setLoading}/>}
              </a.div>
              // eslint-disable-next-line
            )
          }

          <DisclaimerContainer>
            {t('login.accept')}
            <LegalLink
              href="https://ualabee.com/docs/tos.pdf"
              target="blank">{t('login.terms_and_conditions')}
            </LegalLink>{t('login.and_our')}<LegalLink
            href="https://ualabee.com/docs/privacy_policy.pdf"
            target="blank">{t('login.privacy_policy')}</LegalLink>
          </DisclaimerContainer>
        </ButtonsContainer>
        {isIosApp &&
          <NavBar/>
        }
      </LoginContainer>
    </Layout>
  )
}

export default Login
