import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import SquareIcon from '../../../img/square_icon.png'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCityConfig } from '../../../actions/ui'
import useQuery from '../../../hooks/useQuery'

export const Layout = ({
  children,
  title,
  description
}) => {
  const { t } = useTranslation()
  const { cityId = null } = useParams()
  const dispatch = useDispatch()
  const query = useQuery()

  const user = useSelector(state => state?.user?.auth)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  useEffect(() => {
    if (!user || cityConfig) return
    dispatch(setCityConfig(userPosition, query.has('ciudad_id') ? query.get('ciudad_id') : cityId))
  }, [user])

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        overflow: 'auto auto'
      }}
    >
      <Helmet>
        <title>{title || process.env.REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: 'Ualabee' })}</title>
        <meta
          name="title"
          content={title || process.env.REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: 'Ualabee' })}
        />
        <meta
          name="theme-color"
          content={process.env.REACT_APP_PALLET_PRIMARY_MAIN_COLOR}
        />
        <meta
          name='description'
          content={description || t('metadata.description')}
        />
        <link rel="alternate" hrefLang="en" href={`${process.env.REACT_APP_HOST}/?hl=en`}/>
        <link rel="alternate" hrefLang="es" href={`${process.env.REACT_APP_HOST}/?hl=es`}/>
        <link rel="alternate" hrefLang="es-CL" href={`${process.env.REACT_APP_HOST}/?hl=es-CL`}/>
        <link rel="alternate" hrefLang="x-default" href={process.env.REACT_APP_HOST}/>
        <link
          rel="icon"
          href={process.env.REACT_APP_HARDCODED_FAVICON ? process.env.REACT_APP_HARDCODED_FAVICON : SquareIcon}
        />
        <link
          rel="apple-touch-icon"
          href={process.env.REACT_APP_HARDCODED_FAVICON ? process.env.REACT_APP_HARDCODED_FAVICON : SquareIcon}
        />
        {/* Open Graph / Facebook */}
        <meta property="og:title"
              content={title || process.env.REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: 'Ualabee' })}/>
        <meta
          property="og:description"
          content={description || t('metadata.description')}
        />
        {/* <meta property="og:image" content=""/> */}
        {/* Twitter */}
        <meta property="twitter:title"
              content={title || process.env.REACT_APP_HARDCODED_TITLE || t('metadata.title', { company: 'Ualabee' })}/>
        <meta
          property="twitter:description"
          content={description || t('metadata.description')}
        />
        {/* <meta property="twitter:image" content=""/> */}
      </Helmet>
      {children}
    </Box>
  )
}
