import styled from 'styled-components/macro'
import { ArrowBackRounded, ArrowForwardIosRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material'
import { Button } from '@mui/material'

export const SearchResultsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;
  margin-bottom: 10px;
  box-shadow: 0 0 4px 4px #2228311E;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
`

export const Title = styled.h1`
  font-size: 18px;
  margin: 0;
  font-family: 'Visby Round', serif;
  color: ${props => props.theme.palette.text.primary.main};
`

export const BackIcon = styled(ArrowBackRounded)`
  color: ${props => props.theme.palette.icons.secondary.main};
`

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #E8EDEE;
  background-color: white;
`

export const Subtitle = styled.h2`
  font-size: 14px;
  margin: 0;
  font-family: 'Visby Round', serif;
  color: ${props => props.theme.palette.primary.main};
`

export const NoResultsText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 25px;
  background-color: #ECECEC;
  color: #000000;
  font-size: 14px;
  text-align: center;
  font-family: 'Visby Round', serif;
  width: ${props => props?.width ? props.width : '95%'};
`

export const NoResultsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`

export const IconContainer = styled.div`
  width: 49px;
  height: 49px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props?.bgcolor};
  border-radius: 8px;
  margin-right: 24px;
`

export const ShowMoreContainer = styled(Button)`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${props => props?.mode ? '18px 0' : '7px 0'};
  cursor: pointer;
  border-bottom: ${props => props?.mode ? 'none' : '1px solid #E8EDEE'};
`

export const ShowMoreText = styled.h4`
  font-family: 'Visby Round', serif;
  margin: 0;
  color: ${props => props.theme.palette.text.primary.main};
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
  @media (max-width: 480px) {
    font-size: 12px;
    font-weight: bold;
  }
`

export const ShowMoreIcon = styled(ExpandMoreRounded)`
  color: ${props => props.theme.palette.icons.secondary.main};
`

export const ShowLessIcon = styled(ExpandLessRounded)`
  color: ${props => props.theme.palette.icons.secondary.main};
`

export const ArrowIcon = styled(ArrowForwardIosRounded)`
  color: ${props => props.theme.palette.icons.secondary.main};
`
