import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import moment from 'moment'
import { Label } from './styles'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/en'
import { Button, Menu, MenuItem, TextField } from '@mui/material'
import { AccessTimeRounded } from '@mui/icons-material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { setDataTimeValue, setSelectDateTimeLabel, setTripResponse, setTripSelected } from '../../actions/ui'
import useQuery from '../../hooks/useQuery'
import { theme } from '../../theme/theme'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'

export const TripOptions = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [showDataTimePicker, setShowDataTimePicker] = useState(false)
  const { selectLabel } = useSelector(state => state?.ui)

  // TODO: REVIEW
  const [clearedDate] = useState()

  const [value, setValue] = useState(dayjs())

  const urlParams = {
    city_id: parseInt(query.get('ciudad_id')),
    lat_from: query.get('latd'),
    lng_from: query.get('lngd'),
    lat_to: query.get('lath'),
    lng_to: query.get('lngh'),
    radius: Number(query.get('radio')),
    self_search: query.get('self_search') === 'true',
    transport_types: query.get('transport_types'),
    locale: query.get('locale'),
    arrive_by: query.get('arrive_by') === 'true',
    invited_by: query.get('invitedby'),
    referrer_name: query.get('referrerName'),
    timestamp: clearedDate?._d?.getTime(),
    transfer: query.get('transfer')
  }

  moment.locale(i18n.language)
  dayjs.locale(i18n.language)

  const selectRef = useRef(null)
  const date = Number(query.get('timestamp'))

  useEffect(() => {
    if (date && !selectLabel) {
      const d = new Date(date)

      const selectLabel = moment(d).format('ddd, HH:mm')
      dispatch(setSelectDateTimeLabel(selectLabel))
    }
  }, [date, selectLabel])

  useEffect(() => {
    if (clearedDate) {
      dispatch(setDataTimeValue(clearedDate._d.getTime()))
    }
  }, [clearedDate])

  const handleClick = (ref) => {
    setAnchorEl(ref.current)
  }

  const handleClose = (option) => {
    setAnchorEl(null)
    setValue(dayjs())

    if (option === t('search_preview.arrival_time_second_option')) {
      setShowDataTimePicker(true)
    } else if (option === t('search_preview.arrival_time_first_option')) {
      dispatch(setDataTimeValue(null))
      dispatch(setSelectDateTimeLabel(t('search_preview.arrival_time_first_option')))
      if (pathname.includes('trip_share')) {
        dispatch(setTripResponse(undefined))
        dispatch(setTripSelected(undefined))
        history.push(`/trip_share?ciudad_id=${urlParams?.city_id}&latd=${urlParams.lat_from}&lngd=${urlParams.lng_from}&lath=${urlParams?.lat_to}&lngh=${urlParams?.lng_to}&radio=${urlParams.radius}&invitedby=${urlParams.invited_by}&referrerName=${urlParams.referrer_name}&logEvent=${'share'}&transport_types=${urlParams.transport_types}&locale=${urlParams.locale}&self_search=${urlParams.self_search}&transfer=${urlParams.transfer}&arrive_by=${urlParams.arrive_by}&timestamp=${null}`)
      }
    }
  }

  return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>

            <Button
                ref={selectRef}
                styles={showDataTimePicker ? { backgroundColor: 'red' } : {}}
                onClick={() => handleClick(selectRef)}
                startIcon={<AccessTimeRounded variant="secondary.main"/>}
            >
                <Label>{selectLabel || t('search_preview.arrival_time_first_option')}</Label>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    sx={{ color: theme.text.primary.main }}
                    onClick={() => handleClose(t('search_preview.arrival_time_first_option'))}
                >
                    {t('search_preview.arrival_time_first_option')}
                </MenuItem>
                <MenuItem
                    style={{ color: theme.text.primary.main }}
                    onClick={() => handleClose(t('search_preview.arrival_time_second_option'))}
                >
                    {t('search_preview.arrival_time_second_option')}
                </MenuItem>
            </Menu>

            <MobileDateTimePicker
                toolbarTitle={t('search_preview.arrival_time_second_option')}
                views={['day', 'hours', 'minutes']}
                ampm={false}
                open={showDataTimePicker}
                value={value}
                closeOnSelect={false}
                disablePast
                onAccept={() => {
                  const dateTimeValue = dayjs(value).valueOf()

                  dispatch(setTripSelected(undefined))
                  if (pathname?.includes('trip_share')) {
                    history.push(`/trip_share?ciudad_id=${urlParams?.city_id}&latd=${urlParams.lat_from}&lngd=${urlParams.lng_from}&lath=${urlParams?.lat_to}&lngh=${urlParams?.lng_to}&radio=${urlParams.radius}&invitedby=${urlParams.invited_by}&referrerName=${urlParams.referrer_name}&logEvent=${'share'}&transport_types=${urlParams.transport_types}&locale=${urlParams.locale}&self_search=${urlParams.self_search}&transfer=${urlParams.transfer}&arrive_by=${urlParams.arrive_by}&timestamp=${dateTimeValue}`)
                  }

                  dispatch(setTripResponse(undefined))
                  dispatch(setTripSelected(undefined))
                  const selectLabel = moment(dateTimeValue).format('ddd, HH:mm')
                  dispatch(setSelectDateTimeLabel(selectLabel))
                  dispatch(setDataTimeValue(dateTimeValue))
                }}
                onChange={(newValue) => {
                  setValue(newValue)
                }}
                renderInput={(params) => <TextField sx={{ display: 'none' }} {...params} />}
                onClose={() => {
                  setShowDataTimePicker(false)
                  setValue(dayjs())
                }}
            />

        </LocalizationProvider>
  )
}
