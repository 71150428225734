import styled from 'styled-components/macro'
import { IconButton } from '@mui/material'
import { ArrowForwardIosRounded, Close } from '@mui/icons-material'

export const CityInfoContainer = styled.div` 
    width: 100%; 
    background-color: #fff;
    border-radius: 16px;
`

export const Container = styled.div` 
`

export const InfoContainer = styled.div`
    height: ${props => props?.height};
    overflow: auto;
    
    ::-webkit-scrollbar { 
      width: 6px; 
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      border-radius: 7px;
      background-color: #E0E8F7;  
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 164px;
    }
`

export const ItemSelectedNavbar = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const ItemSelectedContent = styled.div`
    height: 345px;
    overflow: auto; 
    
    ::-webkit-scrollbar { 
      width: 6px;
      height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      border-radius: 7px;
      background-color: #E0E8F7;  
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 164px;
    }
`

export const InfoSelectedTitle = styled.p`
    margin: 0;
    font-family: 'Visby Round';
    color: ${props => props.theme.palette.primary.main};
    font-size: 18px;
    font-weight: 600;
    margin-left: 12px;
`

export const InfoSelectedContainer = styled.div`
    padding: 12px;  
`

export const InfoSelectedCloseButton = styled(IconButton)` 
`

export const InfoSelectedCloseIcon = styled(Close)` 
    color: ${props => props.theme.palette.icons.secondary.main};
`

export const InfoText = styled.p`
    margin: 0;
    font-size: 18px;
    color: ${props => props?.isSelected ? props => props.theme.palette.primary.main : process.env.REACT_APP_PALLET_TEXT_PRIMARY_MAIN_COLOR};
    font-family: 'Visby Round'; 
`

export const InfoIcon = styled(ArrowForwardIosRounded)`  
    color: ${props => props?.isSelected ? props => props.theme.palette.primary.main : process.env.REACT_APP_PALLET_ICONS_SECONDARY_MAIN_COLOR};
    &:hover {
        color: ${props => props.theme.palette.icons.primary.main};
    }
`

export const InfoItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px;
   
    &:hover ${InfoText} {
        color: ${props => props.theme.palette.primary.main};    
    }
    
    &:hover ${InfoIcon} {
        color: ${props => props.theme.palette.primary.main};     
    }
`
