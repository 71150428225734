import styled from 'styled-components/macro'
import { CheckCircleOutlineRounded, ErrorRounded } from '@mui/icons-material'
import { Button } from '@mui/material'

export const Container = styled(Button)`
  display: flex;
  flex-direction: row;
  width: calc(100% - 4px);
  height: 48px;
  padding: 12px 0 12px 10px;
  background-color: #ffffff;
  border-radius: 10px !important;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  margin: 10px 16px 0 0 !important;
  box-sizing: border-box;
  position: relative !important;
  z-index: 0 !important;
  line-height: normal !important;
  @media (max-width: 480px) {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin: 0 0 5px 0;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 32px;
  @media (max-width: 480px) {
    height: 38px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`

export const LineNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`

export const LineName = styled.h3`
  font-family: 'Visby Round', serif;
  font-size: 18px;
  margin: 0 0 5px 0;
  color: ${props => props.theme.palette.text.primary.main};
  font-weight: 400;
  height: 18px;
  @media (max-width: 480px) {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    height: auto;
  }
`

export const RouteName = styled.h4`
  font-family: 'Visby Round', serif;
  font-size: 14px;
  margin: 0;
  color: ${props => props.theme.palette.text.secondary.main};
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: initial;
  overflow: hidden;
  @media (max-width: 480px) {
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
  }
`

export const StatusLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
`

export const AffectedIcon = styled(ErrorRounded)`
  color: #FFBB33 !important;
  margin-right: 40px;
`

export const NotAffectedIcon = styled(CheckCircleOutlineRounded)`
  color: #5DCD8E !important;
  margin-right: 40px;
`
