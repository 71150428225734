import React from 'react'
import { ArrivalsContainer, Container, Header, LoadingContainer, RefreshButton, RefreshIcon, Title } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { Arrival } from '../Arrival'
import { getLocalUserUid } from '../../../../db/getLocalUserUid'
import { logEvent } from '../../../../firebase/firebase-config'
import { loadCurrentStopArrivals } from '../../../../actions/map'
import { Loading } from '../../../Loading'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../../../theme/theme'
import FeedbackBar from '../../../FeedbackBar'

export const Arrivals = ({ showBar, feedbackObj, setShowBar }) => {
  const uid = getLocalUserUid()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const arrivalsGrouped = useSelector(state => state?.ui?.arrivals)
  const stopSelected = useSelector(state => state?.ui?.stopSelected)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const showETA = useSelector(state => state.ui.showETA)

  const mobile = useMediaQuery('(max-width:480px)')

  const handleRefreshArrivals = async () => {
    const transportType = arrivalsGrouped?.length >= 1 ? arrivalsGrouped[0]?.arrival?.trip?.route?.transport_type : null

    //  stop_details_refresh event is sent when the refresh button is selected.
    logEvent('stop_details_refresh', {
      user_id: uid,
      city_id: cityConfig?.city_id?.toString(), // String
      os: 'web', // String
      lat: userPosition?.lat || null, // Double
      lng: userPosition?.lng || null, // Double
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null, // String
      stop_lat: stopSelected?.stop_lat, // Double
      stop_lng: stopSelected?.stop_lon, // Double
      stop_color: transportType?.color, // String
      city_name: cityConfig?.name, // String
      from: null, // String
      stop_address: stopSelected?.stop_name, // String
      stop_transportation_type_id: transportType?.transport_type_id, // Int
      stop_transportation_type_name: transportType?.name, // String
      stop_code: stopSelected?.stop_id // String
    })

    const urlParams = {
      city_id: Number(pathname?.split('/')[2]),
      stop_id: decodeURI(pathname?.split('/')[3])
    }

    dispatch(loadCurrentStopArrivals(urlParams, cityConfig))
  }

  return (
    <ArrivalsContainer showETA={showETA}>
      <Header>
        <Title>{t('stop.arrivals.title')}</Title>
        <RefreshButton onClick={handleRefreshArrivals}>
          <RefreshIcon/>
        </RefreshButton>
      </Header>
      {stopSelected?.stop_id
        ? (
          <Container
            showETA={showETA}
            showBar={showBar}
          >  {arrivalsGrouped
            ? (
              <>
                {arrivalsGrouped?.length > 0
                  ? (
                    <>
                      {arrivalsGrouped?.map((group) => {
                        return (
                          <Arrival
                            key={group?.id}
                            arrival={group?.arrival}
                            stopSelected={stopSelected}/>
                        )
                      })}
                      <FeedbackBar
                        feedbackObj={feedbackObj}
                        top='0'
                        showBar={showBar}
                        setShowBar={setShowBar}
                        questionId={3}
                        screen='STOP'
                        barTittle={t('stop.feedback.bar_title')}
                        formContainer={mobile ? 'drawer' : 'modal'}
                      />
                    </>
                    )
                  : (<Box
                      sx={{
                        padding: '0 16px',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography
                        variant='h6'
                        sx={{
                          fontSize: '16px',
                          textAlign: 'center',
                          color: theme.text.primary.main
                        }}
                      >
                        {t('stop.arrivals_unavailable')}❗
                      </Typography>
                    </Box>
                    )
                }
              </>
              )

            : (
              <LoadingContainer>
                <Loading/>
              </LoadingContainer>
              )
          }
          </Container>)
        : (
          <Container showBar={true} showETA={false}>
            <LoadingContainer>
              <Loading/>
            </LoadingContainer>
          </Container>
          )
      }
    </ArrivalsContainer>
  )
}
