import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFilters } from '../../../hooks/useFilters'
import { FilterContainer, FilterOptionGroup, FilterTitle } from '../../../GlobalStyles'
import { Box, Slider, SliderThumb } from '@mui/material'
import walkIcon from '../../../img/walkIcon.png'

export const Walk = () => {
  const { t } = useTranslation()

  const {
    setWalkSetting
  } = useFilters()

  const filters = useSelector(state => state?.user?.searchFilters)

  return (
    <FilterContainer>
      <FilterTitle>
        {t('search_preview.filters.items.walk')}
      </FilterTitle>
      <FilterOptionGroup
      >
        <Slider
          components={{ Thumb: WalkSliderIcon }}
          sx={{
            '& .MuiSlider-thumb': {
              position: 'relative'
            }
          }}
          defaultValue={5}
          value={filters?.walks}
          max={10}
          min={0}
          onChange={setWalkSetting}
        />
      </FilterOptionGroup>
    </FilterContainer>
  )
}

const WalkSliderIcon = (props) => {
  const { t } = useTranslation()

  const { children, ...other } = props

  const filters = useSelector(state => state?.user?.searchFilters)

  return (
    <SliderThumb {...other}>
      {children}
      <img alt='Icono de caminata' style={{ width: 28, height: 28, position: 'absolute' }} src={walkIcon}/>
      <Box
        sx={{
          width: '80px',
          boxShadow: 'rgb(65 115 205 / 16%) 0px 1px 2px',
          position: 'absolute',
          zIndex: 600,
          top: '24px',
          borderRadius: '8px',
          padding: '2px'
        }}
      >
        <p
          style={{
            fontSize: '12px',
            margin: 0,
            textAlign: 'center'
          }}
        >
          {filters?.walks} {t('search_preview.filters.items.blocks')}
        </p>
      </Box>
    </SliderThumb>
  )
}
