import React from 'react'
import { Skeleton } from '@mui/material'
import { InfoIcon } from '../../../../components/Desktop/CityInfo/styles'
import { Container, Item } from './styles'

export const CityInfoSkeleton = () => {
  return (
    <Container>
      <Item>
        <Skeleton variant='text' width={53} height={21}/>
        <InfoIcon fontSize={'small'}/>
      </Item>
      <Item>
        <Skeleton variant='text' width={70} height={21}/>
        <InfoIcon fontSize={'small'}/>
      </Item>
      <Item>
        <Skeleton variant='text' width={192} height={21}/>
        <InfoIcon fontSize={'small'}/>
      </Item>
      <Item>
        <Skeleton variant='text' width={155} height={21}/>
        <InfoIcon fontSize={'small'}/>
      </Item>
      <Item>
        <Skeleton variant='text' width={34} height={21}/>
        <InfoIcon fontSize={'small'}/>
      </Item>
      <Item>
        <Skeleton variant='text' width={110} height={21}/>
        <InfoIcon fontSize={'small'}/>
      </Item>
    </Container>
  )
}

export const InfoItemSkeleton = () => {
  return (
        <div style={{
          width: '100%'
        }}>
            <div style={{
              height: 49,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}>
        <Skeleton variant='text' width={50} height={21}/>
      </div>
      <div style={{
        marginTop: 35
      }}>
        <Skeleton style={{
          margin: '12px 0'
        }} variant='text' width={60} height={21}/>

        <Skeleton style={{
          margin: '12px 0'
        }} variant='text' width={150} height={21}/>

        <Skeleton style={{
          margin: '24px 0'
        }} variant='text' width={'100%'} height={10}/>

        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'30%'} height={10}/>

        <Skeleton style={{
          margin: '13px 0'
        }} variant='text' width={'100%'} height={10}/>

        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'60%'} height={10}/>

        <Skeleton style={{
          margin: '12px 0'
        }} variant='text' width={150} height={21}/>

        <Skeleton style={{
          margin: '24px 0'
        }} variant='text' width={'100%'} height={10}/>

        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'30%'} height={10}/>

        <Skeleton style={{
          margin: '13px 0'
        }} variant='text' width={'100%'} height={10}/>

        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'100%'} height={10}/>
        <Skeleton variant='text' width={'60%'} height={10}/>
      </div>
    </div>
  )
}
