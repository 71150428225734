import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EmojiEmotions, EmojiEvents, RocketLaunchRounded, SettingsRounded } from '@mui/icons-material'
import { Avatar, Button, Divider, Skeleton } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
import {
  ActivityTitle,
  BadgeButton,
  BadgeIconContainer,
  BadgesContainer,
  BadgeTitle,
  ComponentContainer,
  DisplayName,
  StatData,
  StatItem,
  StatsContainer,
  StatTitle,
  UserInfoContainer
} from '../UserProfile/styles'
import UserActivity from '../../uicomponents/UserActivity'
import { detectIos } from '../../utils'
import { handleClickWebToAppButton } from '../../actions/ui'
import { logEvent } from '../../firebase/firebase-config'
import { NoResultsContainer, NoResultsText } from '../SearchResults/styles'
import EmptyBox from '../../animations/empty_box.json'
import { getAuth } from 'firebase/auth'
import { theme } from '../../theme/theme'
import ResendVerificationEmail from '../ResendVerificationEmail'

const UserProfileStats = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const auth = getAuth()
  const user = auth.currentUser

  const mode = useSelector(state => state?.ui?.mode)
  const isIos = detectIos()

  const showBadges = !isIos && mode || !mode

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const contributionHistory = useSelector(state => state?.user.userContributionHistory)

  const handleConfigClick = () => {
    history.push(`/user/${user.uid}`)
  }

  return (
        <ComponentContainer>
            <UserInfoContainer>
                <Avatar
                    sx={{
                      width: '72px',
                      height: '72px',
                      position: 'unset'
                    }}
                    src={userData?.photo_url}
                />
                <>
                    {userData
                      ? <>
                            <DisplayName>
                                {userData?.display_name || user?.displayName}
                            </DisplayName>
                            {!mode &&
                                <Button
                                    onClick={() => handleConfigClick()}
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                      backgroundColor: 'transparent',
                                      border: '1px solid #E0E8F7',
                                      borderRadius: '8px',
                                      boxShadow: 'unset',
                                      padding: '0px 30px',
                                      fontSize: '14px',
                                      fontFamily: 'Visby Round',
                                      color: '#909397',
                                      textTransform: 'none',
                                      marginBottom: '26px',
                                      '&:hover': {
                                        backgroundColor: 'transparent',
                                        boxShadow: 'unset',
                                        border: '1px solid #E0E8F7'
                                      }
                                    }}
                                    startIcon={<SettingsRounded sx={{ color: '#909397', fontSize: '14px' }}/>}>
                                    {t('user.settings')}
                                </Button>
                            }
                        </>
                      : <Skeleton
                            variant='text'
                            width={100}
                            height={20}
                            animation="wave"
                            style={{
                              marginTop: '8px',
                              marginBottom: '26px'
                            }}
                        />
                    }
                </>
                <StatsContainer>
                    <StatItem>
                        {contributionHistory
                          ? <StatData>{contributionHistory?.level_name}</StatData>
                          : <Skeleton variant='text' width={60} height={20} animation="wave" style={{
                            marginBottom: '8px'
                          }}/>}
                        <StatTitle>{t('user.level')}</StatTitle>
                    </StatItem>
                    <Divider orientation="vertical" flexItem/>
                    <StatItem>
                        {userData
                          ? <StatData>{userData?.score || 0}</StatData>
                          : <Skeleton
                                variant='text'
                                width={60}
                                height={20}
                                animation="wave"
                                style={{ marginBottom: '8px' }}
                            />
                        }
                        <StatTitle>{t('user.scores')}</StatTitle>
                    </StatItem>
                </StatsContainer>
                {showBadges && // Displayed only in desktop mode or in mobile mode when it isn't Ios.
                    <BadgesContainer>
                        <BadgeButton
                            sx={{
                              fontWeight: 400,
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              padding: '4px 8px',
                              borderRadius: '14px'
                            }}
                            onClick={() => dispatch(handleClickWebToAppButton(true, {
                              from: 'ranking',
                              lat: userPosition ? userPosition?.lat : null,
                              lng: userPosition ? userPosition?.lng : null,
                              user_id: user?.uid,
                              os: 'web',
                              city_id: cityConfig?.city_id?.toString(),
                              link_to: `${process.env.REACT_APP_HOST}/users_ranking`
                            }, logEvent))}
                        >
                            <BadgeIconContainer>
                                <EmojiEvents style={{ color: theme.palette.primary.main, width: '100%' }}/>
                            </BadgeIconContainer>
                            <BadgeTitle>{t('user.ranking')}</BadgeTitle>
                        </BadgeButton>
                        <BadgeButton
                            sx={{
                              fontWeight: 400,
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              padding: '4px 8px',
                              borderRadius: '14px'
                            }}
                            onClick={() => dispatch(handleClickWebToAppButton(true, {
                              from: 'missions',
                              lat: userPosition ? userPosition?.lat : null,
                              lng: userPosition ? userPosition?.lng : null,
                              user_id: user?.uid,
                              os: 'web',
                              city_id: cityConfig?.city_id.toString(),
                              link_to: `${process.env.REACT_APP_HOST}/missions`
                            }, logEvent))}
                        >
                            <BadgeIconContainer>
                                <RocketLaunchRounded sx={{ color: theme.palette.primary.main }}/>
                            </BadgeIconContainer>
                            <BadgeTitle>{t('user.missions')}</BadgeTitle>
                        </BadgeButton>
                        <BadgeButton
                            sx={{
                              fontWeight: 400,
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              padding: '4px 8px',
                              borderRadius: '14px'
                            }}
                            onClick={() => dispatch(handleClickWebToAppButton(true, {
                              from: 'avatar',
                              lat: userPosition ? userPosition?.lat : null,
                              lng: userPosition ? userPosition?.lng : null,
                              user_id: user?.uid,
                              os: 'web',
                              city_id: cityConfig?.city_id?.toString(),
                              link_to: `${process.env.REACT_APP_HOST}/user/${user?.uid}`
                            }, logEvent))}
                        >
                            <BadgeIconContainer>
                                <EmojiEmotions style={{ color: '#FFCA28', width: '100%' }}/>
                            </BadgeIconContainer>
                            <BadgeTitle>{t('user.my_avatar')}</BadgeTitle>
                        </BadgeButton>
                    </BadgesContainer>
                }

                <ResendVerificationEmail/>
            </UserInfoContainer>
            {contributionHistory?.contributions?.length > 0
              ? (
                    <>
                        <ActivityTitle>
                            {t('user.recent_activity')}
                        </ActivityTitle>
                        <UserInfoContainer padding={'13px'}>
                            <UserActivity/>
                        </UserInfoContainer>
                    </>
                )
              : (<NoResultsContainer style={{ margin: 0 }}>
                        <Player
                            autoplay
                            loop
                            src={EmptyBox}
                            style={{ height: '250px', width: '250px' }}
                        />
                        <NoResultsText>
                            {t('user.no_recent_activity')}<br/>
                        </NoResultsText>
                    </NoResultsContainer>
                )
            }
        </ComponentContainer>
  )
}

export default UserProfileStats
