import styled from 'styled-components/macro'

export const FiltersHeader = styled.div`  
    font-family: 'Visby Round'; 
    width: 100%;     
    border-top-left-radius: 16px;
    border-top-right-radius: 16px; 
    background-color: white; 
    border-bottom: 1px solid #E8EDEE;
`

export const Container = styled.div`   
    height: calc(100% - 57px);
    display: flex; 
    justify-content: space-between;
    flex-direction: column;
    width: 100%; 
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; 
`

export const ContainerFilters = styled.div`  
    background-color: white;
    border-radius: 16px;
    height: calc(100vh - 32px); 
`

export const FiltersContainer = styled.div`   
    padding: 24px;
    height: 60%;
    
    overflow: scroll;
    overflow-x: hidden;
    margin-right: 6px; 
    
    ::-webkit-scrollbar { 
      width: 6px;
      height: 6px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track { 
      margin-top: 31px; 
      border-radius: 7px;
      background-color: #E0E8F7;  
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #BCBEC0; 
      border-radius: 7px;
      height: 164px;
    }
`

export const ButtonContainer = styled.div`
    width: 100%;
    height: 72px;
    background-color: white; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; 
`

export const Button = styled.div`
    width: 220px; 
`
