import React, { useEffect, useState } from 'react'
import { ButtonContainer, NavigationContent, NavigationIcon, NavigationLabel } from './styles'
import { setOpenInvitationMessage } from '../../../actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalUserUid } from '../../../db/getLocalUserUid'
import { logEvent } from '../../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { theme } from '../../../theme/theme'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'

export const NavigationButton = ({ styles, tripSelected, transportType }) => {
  const dispatch = useDispatch()
  const uid = getLocalUserUid()
  const { t } = useTranslation()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)

  const [link, setLink] = useState(null)
  const [isAd, setIsAd] = useState(false)
  const [eventObj, setEventObj] = useState(null)

  useEffect(() => {
    if (transportType) {
      const { package_name_google: googlePackageName, package_name_apple: applePackageName } = transportType

      if (googlePackageName && applePackageName) {
        setIsAd(true)
        // detect if user is on android or ios or web
        const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1
        const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

        if (isAndroid) {
          setLink(`https://play.google.com/store/apps/details?id=${googlePackageName}`)
        } else if (isIos) {
          setLink(`https://apps.apple.com/app/${applePackageName}`)
        }

        const event = {
          name: `open_app_${transportType.name.toLowerCase()}`,
          params: {
            lat: userPosition?.lat || null, // Double or null
            lng: userPosition?.lng || null, // Double or null
            user_gender: userData?.gender || null, // String or null
            user_birthday_timestamp: userData?.birthday?.long_value || null, // Long or null
            user_id: uid, // String
            city_id: cityConfig?.city_id.toString(), // String
            app_installed: false, // Boolean
            app_name: isAndroid ? googlePackageName : isIos ? applePackageName : null // String
          }
        }

        setEventObj(event)
      } else {
        setIsAd(false)
        setLink(tripSelected?.link_to)

        const event = {
          name: 'web_to_app_conversion',
          params: {
            from: 'navigation_button', // String
            lat: userPosition?.lat || null, // Double or null
            lng: userPosition?.lng || null, // Double or null
            user_gender: userData?.gender || null, // String or null
            user_birthday_timestamp: userData?.birthday?.long_value || null, // Long or null
            user_id: uid, // String
            city_id: cityConfig?.city_id.toString(), // String
            link_to: tripSelected?.link_to || `${process.env.REACT_APP_HOST}/${window?.location?.href?.split('/')[3]}` // String
          }
        }
        setEventObj(event)
      }
    }
  }, [transportType])

  const handleClick = () => {
    logEvent(eventObj.name, eventObj.params)
    if (isAd) {
      window.open(link, '_blank')
    } else {
      dispatch(setOpenInvitationMessage({ enabled: true, props: eventObj.params }))
    }
  }

  return (tripSelected && transportType
    ? <ButtonContainer
        style={styles}
        sx={{
          backgroundColor: isAd ? transportType.color : theme.palette.primary.main,
          position: 'absolute',
          bottom: 210,
          zIndex: 800,
          right: 16,
          color: 'white',
          fontFamily: 'Visby Round',
          textTransform: 'none',
          borderRadius: 50,
          padding: '14px 14px',
          '&:hover': {
            backgroundColor: isAd ? transportType.color : theme.palette.primary.main
          }
        }}
        onClick={handleClick}
    >
      <NavigationContent>
        {isAd ? <LaunchRoundedIcon fontSize='small'/> : <NavigationIcon fontSize='small'/>}
        <NavigationLabel>{isAd ? 'Abrir' : t('results.start')}</NavigationLabel>
      </NavigationContent>
    </ButtonContainer>
    : null
  )
}
