import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'

export const getStopArrivals = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(params)
  }

  return (
    fetch(`${process.env.REACT_APP_DATABASE_HOST}/stops/getArrivals`, reqConfig)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Error getting stop arrivals')
        }
      })
      .then((response) => response?.result)
      .catch((err) => console.log(err))
  )
}

getStopArrivals.propTypes = {
  params: PropTypes.object.isRequired
}
