import PropTypes from 'prop-types'
import { getAuth } from 'firebase/auth'

export const getCityConfig = async (cityId) => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqBody = {
    city_id: cityId
  }

  const reqConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify(reqBody)
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/cities/getConfig`, reqConfig)
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Error getting city config')
      }
    })
    .then((response) => response?.result)
    .catch((err) => console.log(err))
}

getCityConfig.propTypes = {
  cityId: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired
}
