import styled from 'styled-components/macro'
import { IconButton } from '@mui/material'

export const ShareDrawerContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
padding: 8px;
box-sizing: border-box;
`

export const ShareItemsContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
padding: 12px;
box-sizing: border-box;
`

export const ShareDrawerTitle = styled.h3`
font-family: 'Visby Round';
font-size: 16px;
color: #222831;
margin: 0;
font-weight: 500;
margin-bottom: 20px;
`

export const ShareButtonsContainer = styled.div`
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
justify-content: space-around;
box-sizing: border-box;
`

export const ShareButtonContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: border-box;
`

export const ShareButton = styled(IconButton)`
background-color: ${props => `${props.bgcolor} !important` || 'transparent !important'};
border: ${props => props.bgcolor ? 'none !important' : '1px solid #C8CBD0 !important'};
margin-bottom: 4px !important;
`

export const ShareButtonText = styled.span`
font-family: 'Visby Round';
font-size: 12px;
color: #525252;
margin: 0;
`

export const SnackbarContentContainer = styled.div`
display: flex;
width: 95%;
background: #202125;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
border-radius: 4px;
box-sizing: border-box;
flex-direction: row;
justify-content: flex-start;
align-items: center;
padding: 11px 12px;
font-family: 'Visby Round';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.025em;
color: #FFFFFF;
`
