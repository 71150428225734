import styled from 'styled-components/macro'
import { Button } from '@mui/material'

export const Container = styled.div`
  height: ${window.innerHeight}px;
`

export const NavigationBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 16px;
  background-color: ${props => props.theme.palette.primary.main};
  width: 100%;
  position: sticky;
  top: 0;
  height: 48px;
  z-index: 2;
`

export const UserNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export const UserName = styled.h1`
  margin: 0;
  font-size: 20px;
  color: #ffffff;
  font-family: 'Visby Round';
`

export const UserProfileContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
  height: inherit;
  overflow: auto;
`

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  padding: ${props => props.padding || '24px'};
  margin-bottom: 27px;
`

export const ActivityTitle = styled.h5`
  margin: 0;
  font-size: 14px;
  color: #222831;
  font-family: 'Visby Round';
  padding-left: 16px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 26px;
`

export const DisplayName = styled.h2`
  margin: 0;
  margin-top: 8px;
  margin-bottom: ${props => props.configButton ? '8px' : '26px'};
  font-size: 16px;
  color: #222831;
  font-family: 'Visby Round';
`

export const StatsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
`

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export const StatData = styled.h3`
  margin: 0;
  margin-bottom: 8px;
  font-size: 14px;
  color: #222831;
  font-family: 'Visby Round';
`

export const StatTitle = styled.span`
  margin: 0;
  font-size: 14px;
  color: #909397;
  font-family: 'Visby Round';
`

export const BadgesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  margin-top: 24px;
`

export const BadgeButton = styled(Button)`
`

export const BadgeIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background-color: ${props => props.theme.palette.primary.light};
  padding: 20px;
  margin-bottom: 7px;
  width: 64px;
  height: 64px;
  box-sizing: border-box;
`

export const BadgeTitle = styled.h4`
  margin: 0;
  font-size: 12px;
  color: #909397;
  font-family: 'Visby Round';
`

export const MoreOptionsIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  width: 32px;
  height: 32px;
  margin-right: 15px;
  box-sizing: border-box;
  background-color: ${props => props.bgColor};
`

export const MoreOptionsIcon = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: ${props => props.color};
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-mask: url(${props => props?.url}) center/contain;
  mask: url(${props => props?.url}) center/contain;
`

export const ProfileContainer = styled.div`
  height: ${window.innerHeight}px;
  width: 100%;
`

export const UserStatsContainer = styled.div`
  height: calc(100% - 48px);
  overflow: auto
`

export const ComponentContainer = styled.div`
  height: inherit;
`
