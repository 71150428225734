import 'firebase/compat/auth'
import { types } from '../types/types'
import _ from 'lodash'
import amplitude from 'amplitude-js'
import { app, db, messaging, setUserProperties } from '../firebase/firebase-config'
import { handleOpenAlert, setAppConfiguration } from './ui'
import { getLocalFavLines } from '../db/getLocalFavLines'
import { getUserLines } from '../helpers/getUserLines'
import { setLocalFavLines } from '../hooks/setLocalFavLines'
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithRedirect,
  updateProfile
} from 'firebase/auth'
import { getUser } from '../helpers/getUser'
import { addDoc, collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { getUserSavedPlaces } from '../helpers/getUserSavedPlaces'
import { getUserPlaces } from '../helpers/getUserPlaces'
import { getToken, isSupported } from 'firebase/messaging'
import i18next from '../i18n'
import { getLocalCityConfig } from '../db/getLocalCityConfig'

export const setLinesFav = (list) => ({
  type: types.linesFav,
  payload: list
})

export const setTransportationType = (type) => ({
  type: types.transportationType,
  payload: type
})

export const setLineSearchResult = (result) => ({
  type: types.lineSearchResult,
  payload: result
})

export const setUserPosition = (position) => ({
  type: types.userPosition,
  payload: position
})

export const setUserAuth = (auth) => ({
  type: types.auth,
  payload: auth
})

export const setUserData = (data) => ({
  type: types.userData,
  payload: data
})
export const setFcmToken = (token) => ({
  type: types.fcmToken,
  payload: token
})

export const setPlace = (place) => ({
  type: types.savedPlaces,
  payload: place
})

export const startLogin = () => {
  return async (dispatch, getState) => {
    const auth = getAuth()

    let { user: { fcmToken, savedPlaces }, ui: { cityConfig } } = getState()

    const localCityConfig = getLocalCityConfig()

    if (!cityConfig && localCityConfig) {
      cityConfig = localCityConfig
    }

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        sessionStorage.setItem('uid', user.uid)
        amplitude.getInstance().setUserId(user.uid)

        user.getIdToken()
          .then((token) => {
            sessionStorage.setItem('token', token)
          })
          .catch((error) => {
            console.log(error)
          })

        if (user?.isAnonymous) {
          dispatch(setUserData(undefined))
          dispatch(setUserContribution(undefined))
          dispatch(setUserAuth(user))
          dispatch(setAppConfiguration())

          setUserProperties({ logged: false })

          const lines = getLocalFavLines()
          dispatch(setLinesFav(lines))
        } else {
          const userData = await getUser(user?.uid)

          if (userData && !userData?.project_id) {
            const docRef = doc(db, 'users', user?.uid)

            try {
              await setDoc(docRef, {
                project_id: process.env.REACT_APP_PROJECT_ID
              }, { merge: true })

              dispatch(setUserData({
                uid: userData.id,
                ...userData,
                project_id: process.env.REACT_APP_PROJECT_ID
              }))
            } catch (e) {
              console.log('Error setting user project_id value', e)
            }
          } else if (userData) {
            dispatch(setUserData({ uid: userData.id, ...userData }))
          } else {
            dispatch(setUserData({
              uid: user?.uid,
              photo_url: user?.photoURL,
              display_name: user?.displayName,
              email: user?.email,
              project_id: process.env.REACT_APP_PROJECT_ID
            }))
          }

          dispatch(setUserAuth(user))

          if (cityConfig) {
            setUserProperties({
              country_id: cityConfig?.country.id,
              country_name: cityConfig?.country.name,
              current_city: cityConfig?.name,
              current_city_id: cityConfig?.city_id,
              display_name: userData?.display_name,
              fcm_token: fcmToken || userData?.fcm_token,
              level: userData?.level,
              nickname: userData?.nickname,
              user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
              user_gender: userData ? userData?.gender : null,
              user_level: userData?.level,
              user_score: userData?.score,
              newsletter_subscribed: userData?.newsletter_subscribed,
              project_id: process.env.REACT_APP_PROJECT_ID
            })
          }

          if (fcmToken) {
            const userRef = doc(db, 'users', user.uid)
            await updateDoc(userRef, { fcm_token_web: fcmToken })
          }

          const userLinesRef = collection(db, `users/${user.uid}/lines`)

          const userLinesFav = await getUserLines() // User lines fav (Firestore)
          const localLines = getLocalFavLines()

          const localLinesFav = localLines?.length ? localLines : []

          const uniqueLines = _.differenceBy(localLinesFav, userLinesFav, 'line_id')
          const uniqueLinesFromDb = _.differenceBy(userLinesFav, localLinesFav, 'line_id')

          if (uniqueLines?.length && uniqueLinesFromDb?.length) {
            const allLines = [...localLinesFav, ...userLinesFav]
            const currentLinesFav = _.uniqBy(allLines, 'line_id')

            const newLinesDocs = uniqueLines.filter(line => !userLinesFav?.some(current => current?.line_id === line?.line_id))

            await Promise.all(
              newLinesDocs.map(async (newDoc) => {
                const docRef = await addDoc(userLinesRef, newDoc)
                console.log('Document written with ID: ', docRef.id)
              })
            )

            setLocalFavLines(currentLinesFav)
            dispatch(setLinesFav(currentLinesFav))
          } else {
            // if there are new lines in local storage, add them to db
            if (uniqueLines?.length) {
              await Promise.all(
                uniqueLines.map(async (newDoc) => {
                  const docRef = await addDoc(userLinesRef, newDoc)
                  console.log('Document written with ID: ', docRef.id)
                })
              )
            }

            // if there are new lines in db, add them to local storage
            if (uniqueLinesFromDb?.length) {
              setLocalFavLines([...uniqueLinesFromDb, ...localLinesFav])
              dispatch(setLinesFav([...uniqueLinesFromDb, ...localLinesFav]))
            }
          }

          const userSavedPlaces = await getUserSavedPlaces() // User saved places (Firestore)
          const userPlaces = await getUserPlaces()

          if (savedPlaces) {
            const arrFiltered = _.uniqBy([...savedPlaces, ...userSavedPlaces, ...userPlaces], 'id')
            dispatch(setPlace(arrFiltered))
          } else {
            const arrFiltered = _.uniqBy([...userSavedPlaces, ...userPlaces], 'id')
            dispatch(setPlace(arrFiltered))
          }

          dispatch(setAppConfiguration())
          setUserProperties({ logged: true })
        }
      } else {
        // User is signed out
        signInAnonymously(auth)
          .then(e => console.log(e))
          .catch(e => console.log(e))
      }

      isSupported()
        .then((isSupported) => {
          if (isSupported) {
            getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_V_API_KEY })
              .then((currentToken) => {
                if (currentToken) {
                  dispatch(setFcmToken(currentToken))
                } else {
                  // Show permission request UI
                  console.log('No registration token available. Request permission to generate one.')
                  // ...
                }
              }).catch((err) => {
                console.log('An error occurred while retrieving messaging token. ', err)
                // ...
              })
          } else {
            console.log('messaging not supported')
          }
        })
        .catch((e) => {
          console.log('Firebase messaging is not supported in this browser', e)
        })
    }
    )
  }
}

export const setUserSearchFilters = (filters) => ({
  type: types.searchFilters,
  payload: filters
})

export const setUserContribution = (contribution) => ({
  type: types.userContribution,
  payload: contribution
})

export const startLoginWithProvider = (provider, history, setLoading = {}) => {
  return async (dispatch) => {
    const auth = getAuth(app)

    const handleUser = async (userCredential) => {
      const user = userCredential?.user
      try {
        const userDetails = await getAdditionalUserInfo(userCredential)
        if (!userDetails.profile.verified_email) {
          await sendEmailVerification(user)
        }
        history.push('/')
      } catch (error) {
        console.log('error', error)
        history.push('/')
      }
    }

    signInWithRedirect(auth, provider)
      .then(async (userCredential) => {
        handleUser(userCredential)
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log('error', errorMessage, errorCode)
        setLoading(false)
        dispatch(handleOpenAlert({
          open: true,
          severity: 'error',
          duration: 3000,
          title: i18next.t('login.failure_login_title'),
          message: i18next.t('login.failure_login_message')
        }))
      })
  }
}

/**
 * Call firebase's signInWithEmailAndPassword method, sign in sent by parameter, and redirect the user to the home page.
 * In the event that the method fails, an alert is displayed with an error message.
 * @param {String} email - the user's email
 * @param {String} password - the user's password
 * @param {function} setCreateAccount - function to set the create account flag
 * @param {function} setErrorMessage - function to set the error message
 * @param history
 * @param setLoading
 * @returns {(function(*, *): void)|*}
 */

export const startLoginWithEmailAndPassword = (email, password, setCreateAccount, setErrorMessage, history, setLoading) => {
  return () => {
    const auth = getAuth()

    signInWithEmailAndPassword(auth, email, password)
      .then(async () => {
        // Signed in
        setLoading(false)
        history.push('/')
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code
        const errorMessage = error.message
        console.log('error', errorMessage)
        if (errorCode === 'auth/user-not-found') {
          setCreateAccount(true)
        } else if (errorCode === 'auth/wrong-password') {
          setErrorMessage('La contraseña es incorrecta.')
        }
      })
  }
}

/**
 * Call the createUserWithEmailAndPassword method of firebase, create an account with the email and password
 * sent by parameter and redirect the user to the account verification page.
 * In the event that the method fails, an alert is displayed with an error message.
 * @param {String} email
 * @param {String} password
 * @param {String} name
 * @param history
 * @param setLoading
 * @returns {(function(*): void)|*}
 */

export const createEmailAndPasswordUser = (email, password, name, history, setLoading) => {
  return (dispatch) => {
    const auth = getAuth()

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user
        // create/update firestore user document

        try {
          const docRef = doc(db, 'users', user.uid)
          await setDoc(docRef, {
            display_name: name
          }, { merge: true })
        } catch (error) {
          console.log('setDoc error', error)
        }

        try {
          // update user profile auth
          await updateProfile(user, {
            displayName: name
          })
        } catch (error) {
          console.log('update profile error', error)
        }

        try {
          await sendEmailVerification(user).catch((error) => {
            console.log('error', error)
          })
        } catch (error) {
          console.log('send email verification error', error)
        }
        // redirect to home
        setLoading(false)
        history.push('/')
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        dispatch(handleOpenAlert({
          open: true,
          severity: 'error',
          duration: 3000,
          title: i18next.t('login.failure_login_title'),
          message: i18next.t('login.failure_login_message')
        }))
      })
  }
}
