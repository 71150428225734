import React, { useEffect, useRef, useState } from 'react'
import { Layout } from '../../uicomponents/layouts/Layout'
import { AppBar, Box, IconButton, Skeleton, Tab, Tabs, Typography, useMediaQuery } from '@mui/material'
import { ArrowBackRounded } from '@mui/icons-material'
import { theme } from '../../theme/theme'
import { getStopById } from '../../db/getStopById'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getStopTimes } from '../../db/getStopTimes'
import { TimeLineItemContainer } from './styles'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded'
import { getClosestTime } from '../../utils'
import { useTranslation } from 'react-i18next'
import ShareLargeButton from '../../uicomponents/ShareLargeButton'
import ShareSmallButton from '../../uicomponents/ShareSmallButton'
import SendToPhoneModal from '../SendToPhoneModal'
import ShareModal from '../ShareModal'
import ShareDrawer from '../ShareDrawer'
import SwipeableViews from 'react-swipeable-views'
import { Timeline } from '@mui/lab'
import { CustomMap } from '../../uicomponents/Map'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box'
      }}
    >
      {value === index && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          {children}
        </Box>
      )}
    </div>
  )
}

const StopTimes = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const listRef = useRef(null)
  const auth = useSelector((state) => state?.user?.auth)
  const userData = useSelector(state => state?.user?.userData)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const arrivalSelected = useSelector(state => state?.ui?.arrivalSelected)

  const { cityId, shapeid, stopid } = useParams()
  const [stop, setStop] = useState()
  const [stopTimes, setStopTimes] = useState()
  const [tabSelected, setTabSelected] = useState(0)
  const [serviceIds, setServiceIds] = useState()

  const isMobile = useMediaQuery('(max-width:480px)')

  const serviceIdsDefault = [{
    id: 1,
    name: t('stop_times.day_tabs.week_days'),
    active: new Date().getDay() !== 0 && new Date().getDay() !== 6,
    stopTimes: [],
    enabled: false
  },
  {
    id: 2,
    name: t('stop_times.day_tabs.saturday'),
    active: new Date().getDay() === 6,
    stopTimes: [],
    enabled: false
  },
  {
    id: 3,
    name: t('stop_times.day_tabs.sunday_and_holydays'),
    active: new Date().getDay() === 0,
    stopTimes: [],
    enabled: false
  }]

  /**
   * It gets the stop data and stop times data, then it filters the stop times data by the service id
   * and sets the closest time for each service id
   */
  const getStopData = async () => {
    const stopPromise = await getStopById({ city_id: cityId, stop_id: stopid })
    const stopTimesPromise = await getStopTimes({
      city_id: cityId,
      shape_id: shapeid,
      stop_id: stopid
    })

    Promise.all([stopPromise, stopTimesPromise])
      .then((values) => {
        const stopData = values[0]
        const stopTimesData = values[1]
        console.log(stopData)
        console.log(stopTimesData)
        const active = serviceIdsDefault
          .filter((service) =>
            stopTimesData.some(
              (time) => Number(time.service_id) === service.id
            )
          ).find(service => service.active)

        if (active) {
          const index = serviceIdsDefault.findIndex((service) => service.id === active.id)
          setTabSelected(index)
        } else {
          setTabSelected(0)
        }

        serviceIdsDefault.map(service => {
          const enabled = stopTimesData.some(time => Number(time.service_id) === service.id)
          const stopTimes = stopTimesData.filter(time => Number(time.service_id) === service.id)
          if (enabled) {
            const closestTime = getClosestTime(stopTimes)
            service.enabled = true
            service.closestTime = closestTime
            service.stopTimes = stopTimes
          } else {
            service.closestTime = null
            service.stopTimes = []
            service.enabled = false
          }
        })

        setStop(stopData)
        setStopTimes(stopTimesData)
        setServiceIds(serviceIdsDefault)
      })
  }
  useEffect(() => {
    if (auth && !stop) getStopData()
  }, [auth])

  return (
    <Layout>
      <Box
        sx={{
          width: '450px',
          height: 'calc(100% - 32px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          zIndex: '430',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: 'rgb(34 40 49 / 16%) 0px 3px 6px',
          position: 'absolute',
          top: '16px',
          left: '16px',
          padding: '16px',
          '@media (max-width:480px)': {
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            padding: 0
          }
        }}
      >

        <Box
          sx={{
            width: '100%',
            height: '98px'
          }}
        >

          <Box
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              padding: '0 8px',
              height: '50px',
              '@media (max-width:480px)': {
                backgroundColor: theme.palette.primary.main,
                padding: '0px 5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <IconButton
              onClick={() => history.goBack()}
            >
              <ArrowBackRounded
                sx={{
                  color: theme.palette.icons.primary,
                  '@media (max-width:480px)': {
                    color: '#fff'
                  }
                }}
              />
            </IconButton>

            <Box
              sx={{
                width: 'calc(100% - 90px)',
                overflow: 'hidden hidden'
              }}
            >
              {stop
                ? (
                  <Typography
                    sx={{
                      color: '#222831',
                      fontSize: '16px',
                      margin: '0 0 0 8px',
                      '@media (max-width:480px)': {
                        color: '#fff',
                        margin: 0,
                        animation: 'scroll-left 8s linear infinite',
                        whiteSpace: 'nowrap',
                        '@keyframes scroll-left': {
                          '0%': {
                            transform: ' translateX(100%)'
                          },
                          '100%': {
                            opacity: 1,
                            transform: ' translateX(-100%)'
                          }
                        }
                      }
                    }}
                    variant="h1"
                  >
                    {stop.stop_name}
                  </Typography>
                  )
                : (
                  <Skeleton variant="text" width={'100%'}/>
                  )}
            </Box>
            {isMobile && (
              <ShareSmallButton
                eventName={'share_stop_times'}
                eventParams={{
                  user_id: auth?.uid,
                  os: 'web',
                  user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null,
                  user_gender: userData?.gender ? userData.gender : null,
                  user_lat: userPosition?.lat ? userPosition.lat : null,
                  user_lng: userPosition?.lng ? userPosition.lng : null,
                  city_id: cityConfig?.city_id?.toString(),
                  city_name: cityConfig?.name,
                  route_name: stopTimes?.length > 0 ? stopTimes[0]?.trip_headsign : null,
                  route_id: stopTimes?.length > 0 ? stopTimes[0]?.route_id : null,
                  line_id: stopTimes?.length > 0 ? stopTimes[0]?.route_id : null,
                  line_name: arrivalSelected?.trip?.route?.route_short_name || arrivalSelected?.trip?.route?.route_long_name,
                  lat: stop?.stop_lat,
                  lng: stop?.stop_lon,
                  address: stop?.stop_name,
                  stop_code: stop?.stop_code
                }}
                params={{
                  link: `${process.env.REACT_APP_HOST}/stop_times/${cityId}/${shapeid}/${stopid}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share_stop_times`,
                  title: t('stop_times.share.title'),
                  description: t('stop_times.share.description', {
                    tripHeadSign: stopTimes && stopTimes[0]?.trip_headsign,
                    stopName: stop && stop?.stop_name
                  })
                }}
                shareText={t('share.title')}
                shareType='share'
                iconColor='#ffffff'
              />
            )}

          </Box>

          {serviceIds && serviceIds?.length > 0 && (
            <Box
              sx={{
                padding: 0,
                boxSizing: 'border-box',
                width: '100%'
              }}
            >
              <AppBar
                sx={{ height: '48px', width: '100%' }}
                elevation={0}
                position="static"
                color="transparent"
              >
                <Tabs
                  value={tabSelected}
                  onChange={(e, newValue) => setTabSelected(newValue)}
                  variant="fullWidth"
                  aria-label="stop information options"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: isMobile
                        ? '#fff'
                        : theme.palette.primary.main
                    }
                  }}
                  sx={{
                    backgroundColor: isMobile
                      ? theme.palette.primary.main
                      : '#fff'
                  }}
                >
                  {serviceIds
                    .map((day) => {
                      if (!day.enabled) return null
                      return (
                        <Tab
                          key={day.name}
                          sx={{
                            minHeight: '48px',
                            padding: '6px 12px',
                            textTransform: 'none',
                            '&.MuiTab-textColorPrimary': {
                              color: '#000000DE',
                              '@media (max-width:480px)': {
                                color: '#BCBEC0'
                              }
                            },
                            '&.Mui-selected': {
                              color: theme.palette.primary.main,
                              '@media (max-width:480px)': {
                                color: '#fff'
                              }
                            }
                          }}
                          label={day.name}
                          defaultChecked
                        />
                      )
                    })}
                </Tabs>
              </AppBar>
            </Box>
          )}

        </Box>

        <Box
          sx={{
            width: '100%',
            maxHeight: 'calc(100% - 98px)',
            height: 'calc(100% - 98px) !important',
            overflow: 'auto auto !important'
          }}
        >

          <SwipeableViews
            axis={'x'}
            index={tabSelected}
            onChangeIndex={(index, indexLatest, { reason }) => {
              if (reason === 'swipe') {
                setTabSelected(index)
              }
            }}
            style={{
              width: '100%'
            }}
          >
            {serviceIds && serviceIds?.length > 0 && serviceIds
              ?.map((day, i) => {
                if (!day.enabled) return null
                return (
                  <TabPanel key={day.name} value={tabSelected} index={i}>
                    <Timeline
                      align="left"
                      sx={{
                        width: '100%',
                        padding: '10px'
                      }}
                      ref={listRef}
                    >
                      {day?.stopTimes
                        ?.map((time, index) => {
                          const active = index === day.closestTime
                          return (
                            <StopTime
                              key={index}
                              listRef={listRef}
                              index={index}
                              time={time}
                              day={day}
                              active={active}
                              tabSelected={tabSelected}
                            />
                          )
                        })}
                    </Timeline>
                  </TabPanel>
                )
              })}
          </SwipeableViews>

        </Box>

      </Box>

      {!isMobile && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: '32px',
              left: '480px',
              zIndex: 402,
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <ShareLargeButton
              eventName={'share_stop_times'}
              eventParams={{
                user_id: auth?.uid,
                os: 'web',
                user_birthday_timestamp: userData?.birthday?.long_valu ? userData.birthday.long_value : null,
                user_gender: userData?.gender ? userData.gender : null,
                user_lat: userPosition?.lat ? userPosition.lat : null,
                user_lng: userPosition?.lng ? userPosition.lng : null,
                city_id: cityConfig?.city_id?.toString(),
                city_name: cityConfig?.name,
                route_name: stopTimes?.length > 0 ? stopTimes[0]?.trip_headsign : null,
                route_id: stopTimes?.length > 0 ? stopTimes[0]?.route_id : null,
                line_id: stopTimes?.length > 0 ? stopTimes[0]?.route_id : null,
                line_name: arrivalSelected?.trip?.route?.route_short_name || arrivalSelected?.trip?.route?.route_long_name,
                lat: stop?.stop_lat,
                lng: stop?.stop_lon,
                address: stop?.stop_name,
                stop_code: stop?.stop_code
              }}
              params={{
                link: `${process.env.REACT_APP_HOST}/stop_times/${cityId}/${shapeid}/${stopid}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share_stop_times`,
                title: t('stop_times.share.title'),
                description: t('stop_times.share.description', {
                  tripHeadSign: stopTimes && stopTimes[0]?.trip_headsign,
                  stopName: stop && stop?.stop_name
                })
              }}
              shareText={t('share.title')}
              shareType={'share'}
            />
            <ShareLargeButton
              eventName={'send_to_phone'}
              eventParams={{
                user_id: auth?.uid,
                os: 'web',
                user_birthday_timestamp: userData ? userData?.birthday?.long_value : null,
                user_gender: userData ? userData?.gender : null,
                user_lat: userPosition ? userPosition.lat : null,
                user_lng: userPosition ? userPosition.lng : null,
                city_id: cityConfig?.city_id,
                city_name: cityConfig?.name,
                route_name: stopTimes ? stopTimes[0]?.trip_headsign : null,
                route_id: stopTimes ? stopTimes[0]?.route_id : null,
                line_id: stopTimes ? stopTimes[0]?.route_id : null,
                line_name: '',
                lat: stop?.stop_lat,
                lng: stop?.stop_lon,
                address: stop?.stop_name,
                stop_code: stop?.stop_code,
                from: 'stop_times'
              }}
              params={{
                link: `${process.env.REACT_APP_HOST}/stop_times/${cityId}/${shapeid}/${stopid}?invitedby=${auth?.uid}&referrerName=${userData ? userData?.display_name?.replace(' ', '%20') : 'Usuario'}&logEvent=share_stop_times`,
                title: t('stop_times.share.title'),
                description: t('stop_times.share.description', {
                  tripHeadSign: stopTimes && stopTimes[0]?.trip_headsign,
                  stopName: stop && stop?.stop_name
                })
              }}
              notificationBody={{
                title: t('stop_times.share.title'),
                content: t('stop_times.share.description', {
                  tripHeadSign: stopTimes && stopTimes[0]?.trip_headsign,
                  stopName: stop && stop?.stop_name
                })
              }}
              shareText={t('send_to_mobile.title')}
              shareType={'send-to-mobile'}
            />
          </Box>
          <CustomMap
            showDraggableDestinationMarkers={false}
          />
          <SendToPhoneModal/>
          <ShareModal/>
          <ShareDrawer/>
        </>
      )}
    </Layout>
  )
}

const StopTime = ({ active, time, day, index, listRef, tabSelected }) => {
  const itemRef = useRef(null)

  useEffect(() => {
    if (active && itemRef?.current && listRef.current) {
      itemRef.current.scrollIntoView(true)
    }
  }, [itemRef, tabSelected])

  return (
    <TimelineItem
      sx={{
        width: '100% !important',
        '&:before': {
          display: 'none'
        }
      }}
      id={active ? 'active' : ''}
    >
      <TimelineSeparator>
        <AccessTimeFilledIcon
          sx={{
            color: theme.palette.primary.main
          }}
        />
        {day?.stopTimes.length -
          1 !==
          index && <TimelineConnector/>}
      </TimelineSeparator>
      <TimelineContent
        sx={{
          width: '100%',
          marginTop: '-14px'
        }}
      >

        <TimeLineItemContainer ref={itemRef} active={active}>
          {parseInt(time.stop_times[0].arrival_time.split(':')[0]) >= 24
            ? `0${parseInt(time.stop_times[0].arrival_time.split(':')[0]) - 24}:${time.stop_times[0].arrival_time.split(':')[1]}`
            : time.stop_times[0].arrival_time
              .split(':')
              .slice(0, 2)
              .join(':')
          }
          {Boolean(time.wheelchair_accessible) && (
            <AccessibleRoundedIcon
              sx={{
                color: active ? '#ffffff' : theme.palette.primary.main
              }}
            />
          )}
        </TimeLineItemContainer>
      </TimelineContent>
    </TimelineItem>
  )
}

export default StopTimes
