import React, { useState, useEffect } from 'react'
import { Form, Inputs, Content, Buttons, Input, Cancel, Create, ErrorMessage } from './styles'
import { useDispatch } from 'react-redux'
import { IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { startLoginWithEmailAndPassword, createEmailAndPasswordUser } from '../../actions/user'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const EmailSignIn = ({ setLoginButtons }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState('')
  const [createAccount, setCreateAccount] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState([{
    label: t('login.name_input_label'),
    type: 'text',
    value: '',
    name: 'name',
    error: false,
    error_label: t('login.name_is_required'),
    show_password: false,
    autocomplete: 'name',
    required: createAccount,
    disabled: !createAccount
  },
  {
    label: t('login.email_input_label'),
    type: 'text',
    value: '',
    name: 'email',
    error: false,
    error_label: t('login.input_default_error'),
    show_password: false,
    autocomplete: 'email',
    required: true,
    disabled: false
  },
  {
    label: t('login.first_password_input_label'),
    type: 'password',
    value: '',
    name: 'password',
    error: false,
    error_label: t('login.input_default_error'),
    show_password: false,
    autocomplete: createAccount ? 'new-password' : 'current-password',
    required: true,
    disabled: false
  },
  {
    label: t('login.second_password_input_label'),
    type: 'password',
    value: '',
    name: 'password-repeat',
    error: false,
    error_label: t('login.input_default_error'),
    show_password: false,
    autocomplete: 'new-password',
    required: createAccount,
    disabled: !createAccount
  }])

  useEffect(() => {
    // update form values
    const newFormValues = formValues.map((input) => {
      if (input.name === 'name') {
        return { ...input, required: createAccount, disabled: !createAccount }
      }
      if (input.name === 'password-repeat') {
        return { ...input, required: createAccount, disabled: !createAccount }
      }
      return input
    })
    setFormValues(newFormValues)
  }, [createAccount])

  const schema = yup.object().shape({
    name: formValues.find(input => input.name === 'name').disabled ? yup.string() : yup.string().required(t('login.name_is_required')),
    email: yup.string().email(t('login.input_default_error')).required(t('login.email_is_required')),
    password: yup.string().required(t('login.password_is_required')),
    'password-repeat': formValues.find(input => input.name === 'password-repeat').disabled ? yup.string() : yup.string().oneOf([yup.ref('password'), null], t('login.passwords_not_match'))
  })

  const handleSingIn = (e) => {
    e.preventDefault()
    setLoading(true)
    const data = {}
    formValues.filter((input) => input.required).forEach((input) => {
      data[input.name] = input.value
    })
    schema.validate(data, { abortEarly: false })
      .then(() => {
        if (createAccount) {
          dispatch(createEmailAndPasswordUser(data.email, data.password, data.name, history, setLoading))
        } else {
          dispatch(startLoginWithEmailAndPassword(data.email, data.password, setCreateAccount, setErrorMessage, history, setLoading))
        }
      })
      .catch((err) => {
        setLoading(false)
        const errors = err.inner
        console.log(errors)
        const newFormValues = formValues.map((input) => {
          const error = errors.find((error) => error.path === input.name)
          if (error) {
            return { ...input, error: true, error_label: error.message }
          }
          return input
        })
        setFormValues(newFormValues)
      })
  }

  const handleClickShowPassword = (index) => {
    const newFormValues = [...formValues]
    newFormValues[index].show_password = !newFormValues[index].show_password
    setFormValues(newFormValues)
  }

  const handleChange = (e, index) => {
    const { value } = e.target
    const newFormValues = [...formValues]
    newFormValues[index].value = value
    newFormValues[index].error = false
    setFormValues(newFormValues)
  }

  return (
          <Content>
            <Form id={'sing-in'} onSubmit={handleSingIn}>
                <Inputs>
                  {formValues.map((input, index) => {
                    return !input.disabled && <Input
                      disabled={loading}
                      autoComplete={input.autocomplete}
                      autoFocus={index === 0}
                      variant="standard"
                      key={index}
                      label={input.label}
                      type={input.type === 'password' && input.show_password ? 'text' : input.type}
                      value={input.value}
                      name={input.name}
                      error={input.error}
                      error_label={input.error_label}
                      helperText={input.error && input.error_label}
                      onChange={(e) => handleChange(e, index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {input.type === 'password' && (
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => handleClickShowPassword(index)}
                                edge='end'
                              >
                                {!input.show_password ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  })}
                </Inputs>
                <Buttons>
                    <Cancel
                    disabled={loading}
                    onClick={() => setLoginButtons(true)}>{t('login.cancel_button')}</Cancel>
                    <Create
                    loading={loading}
                    disabled={loading}
                    type="submit">
                    {!loading && t('login.continue_button')}
                        </Create>
                </Buttons>
              {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
            </Form>
    </Content>
  )
}
