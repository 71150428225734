import styled from 'styled-components/macro'
import { Button, IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

export const ItemName = styled.span`
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: ${props => props.theme.palette.primary.main};
`

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  height: 100%;
`

export const LogoLoader = styled.img`
  width: ${props => props?.mode ? '50%' : '25%'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const FilterOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E8EDEE;
  color: #222831;
  font-family: 'Visby Round', serif;
  font-size: 14px;

  &:last-child {
    border-bottom: none;
  }
`

export const FilterOptionGroup = styled.div`
  padding-left: 0;
  @media (max-width: 480px) {
    padding-left: 8px;
  }
`

export const FilterContainer = styled.div`
  padding: 8px 0;
  @media (max-width: 480px) {
    padding: 9px 24px 0 16px;
  }
`

export const FilterTitle = styled.h2`
  margin: 0;
  font-size: 16px;
  font-family: 'Visby Round', serif;
  font-weight: 400;
  color: ${props => props.theme.palette.primary.main};
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const OptionText = styled.div`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Visby Round', serif;
  margin-left: ${props => props?.mode ? '0' : '9px'};
  color: ${props => props.theme.palette.text.primary.main};
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

export const RecommendedRoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px;
  background-color: white;
  width: 100%;
  @media (max-width: 480px) {
    margin: 0;
  }
`

export const ShowMore = styled(Button)`
  color: ${props => props.theme.palette.text.primary.main};
`

export const ButtonGoBack = styled(IconButton)`
  background-color: ${props => props?.background};
`

export const GoBackIcon = styled(ArrowBack)`
  color: ${props => props?.color};
`

export const Text = styled.p`
  font-size: 14px;
  margin-top: 0;
  color: #909397;
  font-weight: 600;
`
export const Image = styled.img`
  width: ${props => props?.width || 'auto'};
  height: ${props => props?.height || 'auto'};
  margin: ${props => props?.margin || 0};
`
