import React, { useEffect, useState } from 'react'
import UalabeeLogo from '../../img/ualabeelogo.svg'
import {
  ChangeCityText,
  CityContainer,
  CityTitleContainer,
  CopyText,
  DownloadAppContainer,
  Footer,
  Header,
  LegalContainer,
  LegalLink,
  LinkItem,
  LinksContainer,
  LogoUalabee,
  SocialNetworksContainer,
  Title
} from './styles'
import {
  CloseButton,
  CloseIcon,
  Container,
  CopyRightText,
  GooglePlayAdIcon,
  ItemsContainer,
  LoginItem,
  Logo,
  LogoContainer,
  MediaContainer,
  MenuItem,
  MenuItems,
  Navbar,
  SocialMediaButton,
  SocialMediaContainer,
  TextItem
} from '../Desktop/Menu/styles'
import downloadAppIcon from '../../img/desktop/googlePlayIcon.png'
import { useDispatch, useSelector } from 'react-redux'
import { useMenu } from '../../hooks/useMenu'
import {
  enabledAlertsAndNewsComponent,
  handleCloseMenu,
  handleOpenAlert,
  handleOpenMenu,
  setButtonNavbarSelected,
  setOpenCityChange,
  setOpenCountryAlert,
  setOpenSendCommentariesDialog,
  setShowMenuNotification,
  setShowUsefulInformation,
  verifyCityInfo
} from '../../actions/ui'
import { Badge, Box, Button, Drawer, IconButton, SwipeableDrawer, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LanguageSelector } from '../../uicomponents/ui'
import { theme } from '../../theme/theme'
import { logEvent } from '../../firebase/firebase-config'
import { useHistory, useLocation } from 'react-router-dom'
import { CloseRounded, FeedbackOutlined, InfoRounded } from '@mui/icons-material'
import { getDynamicLinks } from '../../db/gettDynamicLinks'
import { detectIos } from '../../utils'
import downloadAppInAppStore from '../../img/download-app-store.png'

const Menu = () => {
  const {
    menuItems,
    accountItems
  } = useMenu()

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { pathname } = useLocation()

  const mobile = useMediaQuery('(max-width:480px)')
  const isIos = detectIos()
  const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const whiteLabel = useSelector(state => state?.ui?.whiteLabel)
  const openMenu = useSelector(state => state?.ui?.openMenu)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)
  const showMenuNotification = useSelector(state => state?.ui?.components?.showMenuNotification)
  const auth = useSelector(state => state?.user.auth)
  const userData = useSelector(state => state?.user?.userData)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const alertsAndNews = useSelector(state => state?.ui?.components?.alertsAndNews)
  const showGooglePlayStore = !isMac && !isIos && !isIosApp

  const [currentMenuItems, setCurrentMenuItems] = useState([])

  useEffect(() => {
    if (mobile) {
      const currentMenuItems = isIosApp
        ? menuItems
          ?.filter(item => item.text !== t('user.options.become_a_contributor'))
          ?.filter(item => item?.enabled)
        : menuItems
          ?.filter(item => item?.enabled)

      setCurrentMenuItems(currentMenuItems)
    } else {
      setCurrentMenuItems(menuItems?.filter(item => item?.enabled))
    }
  }, [i18n.language])

  const handleSendCommentary = () => {
    dispatch(handleCloseMenu())
    localStorage.setItem('showMenuNotification', JSON.stringify(false))
    dispatch(setShowMenuNotification(false))
    dispatch(setOpenSendCommentariesDialog(true))
  }

  const handleCityInfo = () => {
    if (mobile) {
      dispatch(enabledAlertsAndNewsComponent({ enabled: false, current: undefined }))
    } else if (alertsAndNews?.enabled) {
      dispatch(enabledAlertsAndNewsComponent({ enabled: !alertsAndNews?.enabled }))
    }

    dispatch(setButtonNavbarSelected('information'))
    dispatch(setShowUsefulInformation(true))

    const eventParams = {
      lat: userPosition?.lat ? userPosition.lat : null,
      lng: userPosition?.lng ? userPosition.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: sessionStorage.getItem('uid'),
      os: 'web',
      user_birthday_timestamp: userData?.birthday?.long_value || null, // Long
      user_gender: userData?.gender || null // String
    }

    logEvent('city_info_button', eventParams)

    if (mobile) {
      history.push('/city_info')
      dispatch(verifyCityInfo())
    } else {
      if (pathname === '/city_info') {
        history.push('/')
      } else {
        history.push('/city_info')
        dispatch(verifyCityInfo())
      }
      dispatch(setOpenCountryAlert(true))
    }

    dispatch(handleCloseMenu())
  }

  return (
    <>
      {mobile
        ? (
          <SwipeableDrawer
            disableDiscovery
            disableSwipeToOpen
            disableEnforceFocus
            sx={{
              '& .MuiDrawer-paper': {
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingBottom: '24px',
                backgroundColor: '#ffffff',
                boxSizing: 'border-box',
                width: '80%',
                borderRadius: 0
              },
              '& .MuiDrawer-modal': {
                zIndex: '450 !important'
              }
            }}
            anchor="left"
            open={openMenu}
            onClose={() => dispatch(handleCloseMenu())}
            onOpen={() => dispatch(handleOpenMenu())}
          >
            <IconButton
              onClick={() => dispatch(handleCloseMenu())}
              sx={{
                padding: '10px',
                position: 'absolute',
                top: '5px',
                right: '5px'
              }}
            >
              <CloseRounded
                sx={{
                  color: '#fff'
                }}
              />
            </IconButton>
            <Header>
              <CityContainer bg={cityConfig?.map?.landscape_picture}>
                <LanguageSelector/>
                <CityTitleContainer>
                  <Title>{t('home.menu.title', { prop1: cityConfig?.name })}</Title>
                </CityTitleContainer>
                {JSON.parse(process.env.REACT_APP_CAN_CHANGE_CITY || 'true') &&
                  <ChangeCityText onClick={() => dispatch(setOpenCityChange(true))}>
                    {t('home.menu.change_city')}
                  </ChangeCityText>
                }
              </CityContainer>
              <LinksContainer>

                {process.env.REACT_APP_PROJECT_ID === 'renault_mobilize' && auth?.emailVerified && userData?.email?.split('@')[1] === 'ualabee.com' &&

                  <Button
                    sx={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      padding: '13px 18px'
                    }}
                    onClick={handleCityInfo}
                  >
                    <InfoRounded/>
                    <div style={{ marginLeft: 33 }}>
                      {t('home.navbar.items.alt_info_uppercase')}
                    </div>
                  </Button>

                }

                {currentMenuItems?.map((item) => {
                  if (item?.type === 'menu') {
                    return (
                      <LinkItem
                        key={item?.text}
                        href={item?.link}
                        target="blank"
                        onClick={() => {
                          if (item.sendEvent) {
                            item.sendEvent()
                          }
                        }}
                      >
                        {item?.icon}
                        <div style={{ marginLeft: 33 }}>
                          {item.text}
                        </div>
                      </LinkItem>
                    )
                  }
                })}

                <Button
                  sx={{
                    padding: '13px 16px',
                    fontSize: '14px',
                    color: theme.text.primary.main,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    '& .MuiButton-startIcon': {
                      margin: '0 33px 0 0'
                    }
                  }}
                  onClick={handleSendCommentary}
                  startIcon={
                    <Badge overlap="rectangular" color='error' variant="dot" invisible={!showMenuNotification}>
                      <FeedbackOutlined style={{ color: theme.palette.icons.secondary.main }}/>
                    </Badge>
                  }
                >
                  {t('home.menu.items.send_feedback')}
                </Button>

                <div style={{ display: 'none' }}>
                  {accountItems.map((item) => {
                    return (
                      <LinkItem key={item.text} href={item?.link} target="blank">
                        {item?.icon}
                        <div style={{ marginLeft: 33 }}>
                          {item.text}
                        </div>
                      </LinkItem>
                    )
                  })}
                </div>
              </LinksContainer>

        {!isIosApp &&
          <>
            {whiteLabel
              ? (
                <>
                  {whiteLabel.components.menu.downloadApp.enabled &&
                    <DownloadAppContainer>
                      <TextItem style={{
                        margin: 0,
                        marginBottom: 15
                      }}>{t('home.menu.download_app')}</TextItem>
                      <Button
                        onClick={() => handleDownload(mobile, dispatch)}
                      >
                        <GooglePlayAdIcon
                          src={isIos ? downloadAppInAppStore : downloadAppIcon}/>
                      </Button>
                    </DownloadAppContainer>
                  }
                </>
                )
              : (<DownloadAppContainer>
                  <TextItem
                    style={{ margin: 0, marginBottom: 15 }}>{t('home.menu.download_app')}</TextItem>
                  <Button
                    onClick={() => handleDownload(mobile, dispatch)}
                  >
                    <GooglePlayAdIcon src={isIos ? downloadAppInAppStore : downloadAppIcon}/>
                  </Button>
                </DownloadAppContainer>
                )
            }
          </>
        }
      </Header>
      <Footer>
        <LogoUalabee
          src={process.env.REACT_APP_HARDCODED_LOGO ? process.env.REACT_APP_HARDCODED_LOGO : UalabeeLogo}/>
        {/* <LogoUalabee src={whiteLabel ? whiteLabel?.logo : UalabeeLogo}/> */}
        <SocialNetworksContainer>
          {currentMenuItems?.map((item) => {
            if (item.type === 'social-media') {
              return (
                <IconButton
                  key={item?.link}
                  sx={{
                    margin: '0 10px'
                  }}
                  component="a"
                  href={item?.link}
                  target="blank"
                >
                  {item.icon}
                </IconButton>
              )
            }
          })}
        </SocialNetworksContainer>
        <CopyText>{t('home.menu.copyright')}</CopyText>
        <LegalContainer>
          <LegalLink
            href="https://ualabee.com/docs/privacy_policy.pdf"
            target="blank"
          >
            {t('home.menu.privacy_policies')}
          </LegalLink>
          <LegalLink href="https://ualabee.com/docs/tos.pdf" target="blank">
            {t('home.menu.terms_and_conditions')}
          </LegalLink>
        </LegalContainer>
      </Footer>
    </SwipeableDrawer>)
        : (
          <Drawer
            anchor={'left'}
            open={openMenu}
            onClose={() => dispatch(handleCloseMenu())}
            disableEnforceFocus
            PaperProps={{
              style: {
                width: 'fit-content',
                borderRadius: 0
              }
            }}
          >
            <Container>
              <Navbar>
                <LogoContainer>
                  <Logo src={process.env.REACT_APP_HARDCODED_LOGO || UalabeeLogo}/>
                  <CloseButton onClick={() => dispatch(handleCloseMenu())}>
                    <CloseIcon/>
                  </CloseButton>
                </LogoContainer>
                {JSON.parse(process.env.REACT_APP_CAN_CHANGE_CITY || 'true') &&
                  <ChangeCityText onClick={() => dispatch(setOpenCityChange(true))}>
                    {t('home.menu.change_city')}
                  </ChangeCityText>
                }
              </Navbar>
              <ItemsContainer>
                <Box
                  sx={{
                    width: '100%'
                  }}
                >
                  <LanguageSelector/>
                  <MenuItems>

                    {process.env.REACT_APP_PROJECT_ID === 'renault_mobilize' && auth?.emailVerified && userData?.email?.split('@')[1] === 'ualabee.com' &&

                      <Button
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginBottom: '15px'
                        }}
                        onClick={handleCityInfo}
                      >
                        <InfoRounded/>
                        <div style={{ marginLeft: 16, fontSize: '16px' }}>
                          {t('home.navbar.items.alt_info_uppercase')}
                        </div>
                      </Button>

                    }

                    {currentMenuItems?.map((item, index) => {
                      if (item?.type === 'menu') {
                        return (
                          <MenuItem
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              textTransform: 'none',
                              fontWeight: 400,
                              marginBottom: '15px',
                              color: theme.text.primary.main,
                              '&:last-child': {
                                marginBottom: 0
                              },
                              height: '36px'
                            }}
                            key={index}
                            href={item?.link}
                            target="blank"
                            onClick={() => {
                              if (item.sendEvent) {
                                item.sendEvent()
                              }
                            }}
                          >
                            {item.icon}
                            <TextItem>{item.text}</TextItem>
                          </MenuItem>
                        )
                      }
                    })}

                    <MenuItem
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textTransform: 'none',
                        fontWeight: 400,
                        marginBottom: '15px',
                        color: theme.text.primary.main,
                        '&:last-child': {
                          marginBottom: 0
                        },
                        height: '36px'
                      }}
                      onClick={handleSendCommentary}
                      target="blank"
                    >
                      <Badge overlap="rectangular" color='error' variant="dot"
                             invisible={!showMenuNotification}>
                        <FeedbackOutlined style={{ color: theme.palette.icons.secondary.main }}/>
                      </Badge>
                      <TextItem>{t('home.menu.items.send_feedback')}</TextItem>
                    </MenuItem>
                    <div style={{ display: 'none' }}>
                      {accountItems.map((item, index) => {
                        return (
                          <LoginItem
                            key={index}
                          >
                            {item.icon}
                            <TextItem>{item.text}</TextItem>
                          </LoginItem>
                        )
                      })}
                    </div>
                  </MenuItems>
                </Box>
                <MediaContainer>
                  {showGooglePlayStore &&
                    <>
                      {whiteLabel?.components?.menu?.downloadApp?.enabled || !whiteLabel &&
                        <>
                          <TextItem style={{
                            margin: 0,
                            marginBottom: 15
                          }}>{t('home.menu.download_app')}</TextItem>
                          <Button
                            onClick={() => handleDownload(mobile, dispatch)}
                          >
                            <GooglePlayAdIcon src={isIos ? downloadAppInAppStore : downloadAppIcon}/>
                          </Button>
                        </>
                      }
                    </>
                  }
                  <SocialMediaContainer>
                    {currentMenuItems?.map((item, index) => {
                      if (item?.type === 'social-media') {
                        return (
                          <SocialMediaButton
                            key={index}
                            href={item?.link}
                            target="blank"
                          >
                            {item?.icon}
                          </SocialMediaButton>
                        )
                      }
                    })}
                  </SocialMediaContainer>
                  <LegalContainer>
                    <LegalLink
                      href="https://ualabee.com/docs/privacy_policy.pdf"
                      target="blank"
                    >
                      {t('home.menu.privacy_policies')} ·
                    </LegalLink>
                    <LegalLink
                      href="https://ualabee.com/docs/tos.pdf"
                      target="blank">
                      {t('home.menu.terms_and_conditions')}
                    </LegalLink>
                  </LegalContainer>
                  <CopyRightText>{t('home.menu.copyright')}</CopyRightText>
                </MediaContainer>
              </ItemsContainer>
            </Container>
          </Drawer>
          )
      }
    </>
  )
}

export const handleDownload = (mobile, dispatch) => {
  const isIos = detectIos()

  if (isIos || mobile) {
    getDynamicLinks({ link: process.env.REACT_APP_HOST })
      .then((response) => {
        if (response?.shortLink) {
          if (!response.shortLink) return
          window.open(response?.shortLink, mobile ? '_self' : '_blank')
        } else {
          dispatch(handleOpenAlert({
            title: 'Ha ocurrido un error',
            severity: 'error'
          }))
        }
      })
      .catch((e) => {
        console.log(e)
      })
  } else {
    window.open(`https://play.google.com/store/apps/details?id=${process.env.REACT_APP_ANDROID_PACKAGE_NAME}`, '_blank')
  }
}

export default Menu
