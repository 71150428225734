import React, { useEffect } from 'react'
import { HomeContainer, Information, MapDriversContainer } from './styles'
import { Search } from '../Search'
import { Navbar } from '../Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { Lines } from '../Lines'
import { CityInfo } from '../CityInfo'
import { Line } from '../Line'
import { Filters } from '../../../uicomponents/desktop/tripPreview/Filters'
import { AlertInformation } from './../../../uicomponents/desktop/alerts/AlertInformation/index'
import { TripResults } from '../TripResults'
import UserProfilePreview from '../UserProfilePreview'
import { useLocation } from 'react-router-dom'
import {
  enabledAlertsAndNewsComponent,
  setLineSelected,
  setOpenAlertInformation,
  setShowLines,
  setShowNavbar,
  setShowSearcher
} from '../../../actions/ui'
import { Loading } from '../../../uicomponents/mobile/Loading'
import { Card } from '@mui/material'
import { MapFilters } from '../../MapFilters'
import { CustomMap } from '../../../uicomponents/Map'

export const Home = ({
  handleSearch,
  lineInfo,
  // polylineStyles,
  routeSelected
}) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const {
    navbar,
    search,
    line,
    filters,
    lines,
    alertsAndNews,
    mapFilters,
    alertInformation
  } = useSelector(state => state?.ui?.components)
  const cityInfoList = useSelector(state => state?.ui?.components?.city_info)
  const loading = useSelector(state => state?.ui?.loading)
  const isLine = pathname === '/lines'
  const cityAlerts = useSelector(state => state?.ui?.alerts)

  useEffect(() => {
    if (pathname.includes('news')) {
      dispatch(enabledAlertsAndNewsComponent({
        enabled: true
      }))

      if (pathname?.split('/')[2]) {
        dispatch(setOpenAlertInformation(true))
      }
    }

    if (lines?.enabled && !pathname?.includes('lines')) {
      dispatch(setShowLines(false))
      dispatch(setShowNavbar(true))
    }

    if (pathname === '/') {
      dispatch(setShowNavbar(true))
      dispatch(setShowSearcher(true))

      dispatch(enabledAlertsAndNewsComponent({ enabled: false }))
      dispatch(setLineSelected(null))
    }
  }, [pathname])

  return (
    <>
      {alertsAndNews?.enabled && cityAlerts?.length === 0 && <Loading/>}
      {pathname === '/city_info' && !cityInfoList && <Loading/>}
      {loading && <Loading/>}

      {pathname?.includes('trip_share')
        ? (
          <TripResults/>
          )
        : (<HomeContainer>
            <CustomMap
              showAdMarkers={true}
              showStopsNearby={Boolean(!pathname.includes('lines'))}
              showDraggableDestinationMarkers={Boolean(!pathname.includes('lines'))}
            />
            <Information
              isLine={isLine || false}
            >

              {search?.enabled && !filters.enabled &&
                <Search
                  handleSearch={handleSearch}
                  showTransportFilters={pathname === '/lines'}
                />
              }
              {process.env.REACT_APP_SHOW_NAVBAR !== 'false' && navbar?.enabled && !filters.enabled &&
                <Navbar
                  showCityInfo={pathname?.includes('city_info')}
                />
              }

              {pathname === '/lines' && <Lines/>}

              {pathname?.includes('city_info') &&
                <CityInfo/>
              }

              {line?.enabled &&
                <Line
                  routeSelected={routeSelected}
                  lineInfo={lineInfo}
                />
              }

              {filters?.enabled &&
                <Filters/>
              }

              {alertInformation &&
                <AlertInformation/>
              }
            </Information>
            <MapDriversContainer>
              {mapFilters?.enabled &&
                <Card
                  sx={{
                    zIndex: 400,
                    position: 'absolute',
                    top: '-407px',
                    left: '-435px',
                    boxShadow: '0px 3px 6px #22283129',
                    borderRadius: '16px'
                  }}
                >
                  <MapFilters/>
                </Card>
              }
            </MapDriversContainer>
            {process.env.REACT_APP_SHOW_LOGIN !== 'false' &&
              <UserProfilePreview/>
            }
          </HomeContainer>
          )
      }
    </>
  )
}
