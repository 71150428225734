import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SliderLabel, WalkIconImageContainer } from '../components/FilterDrawer/styles'
import walkIcon from '../img/walkIcon.png'
import { getTripResults, setShowFilters, setTimestamp, setTripResponse, setTripSelected } from '../actions/ui'
import { useHistory, useLocation } from 'react-router-dom'
import { setLocalResultFilters } from '../db/setLocalResultFilters'
import { getLocalResultFilters } from '../db/getLocalResultFilters'
import { setUserSearchFilters } from '../actions/user'
import { setUserProperties } from '../firebase/firebase-config'
import { useTranslation } from 'react-i18next'

export const useFilters = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const [openFilters, setOpenFilters] = useState(false)
  const { selectLabel } = useSelector(state => state?.ui)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const searchFilters = useSelector(state => state?.user?.searchFilters)
  const timestamp = useSelector(state => state?.ui?.dataTimeValue)
  const filters = useSelector(state => state?.ui?.components?.filters)
  const from = useSelector(state => state?.ui?.places?.from)
  const to = useSelector(state => state?.ui?.places?.to)
  const searchTimestamp = useSelector(state => state?.ui?.dataTimeValue)
  const user = useSelector(state => state?.user?.auth)

  const handleChangeRadio = (event) => {
    dispatch(setUserSearchFilters({
      ...searchFilters,
      type_of_route: event.target.value
    }))
  }

  const handleChange = (event, id) => {
    const currentTransportTypes = searchFilters?.transportation_types?.map(type => {
      if (type.transport_type_id === id) {
        type.checked = event.target.checked
      }
      return type
    })

    dispatch(setUserSearchFilters({
      ...searchFilters,
      transportation_types: currentTransportTypes
    }))
  }

  const handleCheckboxChange = (event) => {
    dispatch(setUserSearchFilters({
      ...searchFilters,
      remember_settings: event.target.checked
    }))
  }
  const handleClose = (setOpenFilter) => {
    setOpenFilter(false)
  }

  function showWalkIcon(props) {
    return (
      <WalkIconImageContainer {...props}>
        <SliderLabel>{props['aria-valuenow']} {t('search_preview.filters.items.blocks')}</SliderLabel>
        <img alt='' style={{ width: 28, height: 28 }} src={walkIcon}/>
      </WalkIconImageContainer>
    )
  }

  const setWalkSetting = (event, value) => {
    dispatch(setUserSearchFilters({
      ...searchFilters,
      walks: value,
      radius: value * 100
    }))
  }

  const handleClickApplyChanges = async () => {
    dispatch(setTimestamp(new Date().getTime()))

    const currentTransports = searchFilters?.transportation_types?.filter(type => type?.checked)

    const transports = currentTransports?.map(type => {
      return type.transport_type_id
    })?.toString()

    if (searchFilters?.remember_settings) {
      const localTipFilters = await getLocalResultFilters()

      if (localTipFilters) {
        const currentFilters = localTipFilters?.map((filters) => {
          if (filters?.city_id === cityConfig?.city_id) {
            filters.walks = searchFilters.walks
            filters.remember_settings = searchFilters?.remember_settings
            filters.transportation_types = searchFilters.transportation_types
            filters.type_of_route = searchFilters.type_of_route
          }
          return filters
        })

        setLocalResultFilters(currentFilters)
      } else {
        setLocalResultFilters([searchFilters])
      }

      setUserProperties({
        user_transport_type: transports,
        user_transfer: searchFilters.type_of_route,
        user_walking_distance: searchFilters?.walks
      })
    }

    let transfer

    if (searchFilters?.type_of_route === 'only-directs') {
      transfer = false
    } else if (searchFilters?.type_of_route === 'only-transfers') {
      transfer = true
    } else {
      transfer = null
    }

    const params = {
      city_id: cityConfig?.city_id,
      lat_from: from?.latLng?.lat,
      lng_from: from?.latLng?.lng,
      lat_to: to?.latLng?.lat,
      lng_to: to?.latLng?.lng,
      radius: searchFilters?.walks * 100,
      transfer,
      self_search: true,
      transport_types: transports?.length >= 1 ? transports : null,
      locale: 'es',
      lat_from_station: null,
      lng_from_station: null,
      lat_to_station: null,
      lng_to_station: null,
      arrive_by: selectLabel !== t('search_preview.arrival_time_first_option'),
      timestamp: searchTimestamp || null,
      invited_by: user?.uid,
      referrer_name: null
    }

    dispatch(setTripResponse(null))
    dispatch(setTripSelected(null))

    if (pathname.includes('trip_share')) {
      history.replace(`/trip_share?ciudad_id=${params.city_id}&latd=${params.lat_from}&lngd=${params.lng_from}&lath=${params.lat_to}&lngh=${params.lng_to}&radio=${params.radius}&invitedby=${params.invited_by}&referrerName=${params.referrer_name}&logEvent="share"&transport_types=${params.transport_types}&locale=${params.locale}&self_search=${params.self_search}&transfer=${params.transfer}&arrive_by=${params.arrive_by}&timestamp=${params.timestamp}`)
    } else {
      history.push(`/trip_share?ciudad_id=${params.city_id}&latd=${params.lat_from}&lngd=${params.lng_from}&lath=${params.lat_to}&lngh=${params.lng_to}&radio=${params.radius}&invitedby=${params.invited_by}&referrerName=${params.referrer_name}&logEvent="share"&transport_types=${params.transport_types}&locale=${params.locale}&self_search=${params.self_search}&transfer=${params.transfer}&arrive_by=${params.arrive_by}&timestamp=${params.timestamp}`)
    }

    dispatch(getTripResults())
    dispatch(setShowFilters(!filters?.enabled))
  }

  return {
    openFilters,
    setOpenFilters,
    handleChangeRadio,
    handleChange,
    handleCheckboxChange,
    showWalkIcon,
    setWalkSetting,
    handleClickApplyChanges,
    timestamp,
    handleClose
  }
}
