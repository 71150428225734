import { getAuth } from 'firebase/auth'

export const sendFeedback = (screen, params) => new Promise((resolve, reject) => {
  const services = {
    SEARCH_RESULT_DETAILS: 'searchResultDetails',
    SEARCH_RESULTS: 'searchResults',
    STOP: 'stop'
  }

  const auth = getAuth()
  const user = auth.currentUser

  user.getIdToken().then(token => {
    const reqConfig = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(params)
    }

    fetch(`${process.env.REACT_APP_DATABASE_HOST}/feedback/${services[screen]}`, reqConfig)
      .then((response) => {
        if (response.ok) {
          return response?.json()
        }
      }).then((response) => resolve(response?.result))
      .catch((err) => reject(err))
  }).catch((err) => reject(err))
})
