import React from 'react'
import { ModalButtonsContainer, ModalContainer, ModalIcon, ModalSubtitle, ModalTitle, WorldIconContainer } from './styles'
import WorldIcon from '../../img/ic_dialog_world.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAdsList,
  setCityAds,
  setCityIdentifiedDismissed,
  setCurrentCitySelected,
  setOpenIdentifiedCityModal
} from '../../actions/ui'
import { unsubscribeToCityTopics } from '../../db/unsubscribeToCityTopics'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import Modal from '@mui/material/Modal'
import { getAdsByCity } from '../../db/ads'

export const IdentifiedCityModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openCityModal = useSelector(state => state?.ui?.openCityModal)
  const identifiedCity = useSelector(state => state?.ui?.identifiedCity)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const fcmToken = useSelector(state => state?.user?.fcmToken)

  const handleCloseModal = () => {
    dispatch(setOpenIdentifiedCityModal(false))
    dispatch(setCityIdentifiedDismissed(true))
  }

  const handleOkModal = async () => {
    dispatch(setCurrentCitySelected(identifiedCity))
    dispatch(setOpenIdentifiedCityModal(false))

    getAdsByCity(identifiedCity?.city_id)
      .then((response) => {
        if (response?.rotationTime?.some(item => item.ad_type_id === 20)) { // it means that there are some Search results advertising to show
          dispatch(setCityAds(response))
        } else {
          dispatch(setAdsList([]))
        }
      })

    unsubscribeToCityTopics(cityConfig?.city_id, identifiedCity?.city_id, fcmToken)
      .catch(e => console.log(e))
  }

  return (
    <Modal
      open={openCityModal}
      onClose={handleCloseModal}
    >
      <ModalContainer>
        <WorldIconContainer>
          <ModalIcon src={WorldIcon}/>
        </WorldIconContainer>
        <ModalTitle>
          {t('identify_city.title', { prop1: identifiedCity?.name })}
        </ModalTitle>
        <ModalSubtitle>
          {t('identify_city.content')}
        </ModalSubtitle>
        <ModalButtonsContainer>
          <Button
            onClick={handleCloseModal}
            sx={{
              color: '#dc5849',
              textTransform: 'none'
            }}
          >
            {t('identify_city.i_disagree')}
          </Button>
          <Button
            onClick={handleOkModal}
            sx={{
              color: '#5fa9be',
              textTransform: 'none'
            }}
          >
            {t('identify_city.yes')}
          </Button>
        </ModalButtonsContainer>
      </ModalContainer>
    </Modal>
  )
}
