import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading, setOpenInvitationMessage } from '../../actions/ui'
import GooglePlayIcon from '../../img/download-app-google-play.png'
import {
  CloseButton,
  DownloadAppButton,
  DownloadAppButtonContainer,
  DrawerBar,
  DrawerContainer,
  GooglePlayImage,
  HeaderContent,
  InvitationMessageContainer,
  MessageContainer,
  MessageContent,
  MessageHeader
} from './styles'
import { getDynamicLinks } from '../../db/gettDynamicLinks'
import { logEvent } from '../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { SwipeableDrawer, useMediaQuery } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { theme } from '../../theme/theme'

export const InvitationMessage = () => {
  const dispatch = useDispatch()

  const mobile = useMediaQuery('(max-width:480px)')

  const { invitationMessage } = useSelector(state => state?.ui?.components)

  const handleClose = () => {
    dispatch(setOpenInvitationMessage({ enabled: false, props: undefined }))
  }

  const handleAccept = () => {
    dispatch(setLoading(true))
    getDynamicLinks({ link: invitationMessage?.props?.link_to })
      .then((url) => {
        dispatch(setLoading(false))
        if (!response.shortLink) return window.open(url?.shortLink, mobile ? '_self' : '_blank')
      })
      .catch((e) => {
        dispatch(setLoading(false))
        console.log(e)
      })
    logEvent('web_to_app_conversion_cta', invitationMessage?.props)
    handleClose()
  }

  return (
    <>
      {mobile
        ? (
          <SwipeableDrawer
            disableEnforceFocus
            disableSwipeToOpen
            sx={{
              '& .MuiDrawer-paper': {
                zIndex: '999',
                overflow: 'hidden',
                borderTopLeftRadius: '14px',
                borderTopRightRadius: '14px'
              }
            }}
            anchor={'bottom'}
            open={invitationMessage?.enabled}
            onOpen={() => dispatch(setOpenInvitationMessage({
              enabled: true,
              props: invitationMessage?.props
            }))}
            onClose={handleClose}
          >
            <Content
              handleAccept={handleAccept}
              handleClose={handleClose}
            />
          </SwipeableDrawer>
          )
        : (<InvitationMessageContainer
            sx={{
              '& .MuiPaper-rounded': {
                borderRadius: '14px',
                maxWidth: '410px'
              }
            }}
            open={invitationMessage?.enabled}
            onClose={handleClose}
          >
            <Content
              handleAccept={handleAccept}
              handleClose={handleClose}
            />
          </InvitationMessageContainer>
          )}
    </>
  )
}

const Button = ({ handleAccept }) => {
  const { t } = useTranslation()
  const mobile = useMediaQuery('(max-width:480px)')

  return (
    <>
      {mobile
        ? (<DownloadAppButton
          onClick={handleAccept}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            borderRadius: '10px',
            width: '100%',
            '&:hover': {
              backgroundColor: theme.palette.primary.main
            }
          }}
        >
          {t('invitation_message.download')}
        </DownloadAppButton>)
        : (
          <DownloadAppButton
            sx={{
              padding: 0,
              borderRadius: '8px',
              width: '120px',
              height: '36px'
            }}
            onClick={handleAccept}
          >
            <GooglePlayImage alt='Google play icon' src={GooglePlayIcon}/>
          </DownloadAppButton>
          )}
    </>
  )
}

const Content = ({ handleAccept, handleClose }) => {
  const { t } = useTranslation()

  const mobile = useMediaQuery('(max-width:480px)')

  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  return (
    <>
      {mobile
        ? (
          <MessageContainer>
            <DrawerBar/>
            <DrawerContainer>
              <HeaderContent>{t('invitation_message.title')}</HeaderContent>
              <MessageContent>
                {isIosApp
                  ? t('invitation_message.ios_content')
                  : t('invitation_message.content')
                }
              </MessageContent>
              {!isIosApp &&
                <Button handleAccept={handleAccept}/>
              }
            </DrawerContainer>
          </MessageContainer>
          )
        : (<MessageContainer>
            <MessageHeader>
              <HeaderContent>{t('invitation_message.title')}</HeaderContent>
              <CloseButton onClick={handleClose}>
                <CloseRounded sx={{ color: '#fff' }} fontSize={'small'}/>
              </CloseButton>
            </MessageHeader>
            <MessageContent>{t('invitation_message.content')}</MessageContent>
            <DownloadAppButtonContainer>
              <Button handleAccept={handleAccept}/>
            </DownloadAppButtonContainer>
          </MessageContainer>
          )
      }
    </>
  )
}
