import React from 'react'
import Modal from '@mui/material/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenLoginPopup, setOpenSendToPhoneModal } from '../../actions/ui'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ModalHeaderContainer, ModalHeaderTitle, SocialText } from '../ShareModal/styles'
import {
  DividerContainer,
  FooterText,
  HelpText,
  ModalContainer,
  QRCodeContainer,
  QRCodeHelpContainer,
  QRCodeLogo,
  SendToMyPhoneContainer,
  ShareContentContainer
} from './styles'
import QRCode from 'react-qr-code'
import UalabeeIsologo from '../../img/square_icon.png'
import { Link } from 'react-router-dom'
import SendToMobileRoundedIcon from '@mui/icons-material/SendToMobileRounded'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import { sendToPhone } from '../../db/sendToPhone'
import { useTranslation } from 'react-i18next'
import { theme } from '../../theme/theme'
import Button from '@mui/material/Button'

const SendToPhoneModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const openSendToPhoneModal = useSelector(state => state?.ui?.openSendToPhoneModal)
  const userData = useSelector(state => state?.user?.userData)
  const mode = useSelector(state => state?.ui?.mode)

  const handleSendToPhoneClick = async () => {
    const params = {
      link: openSendToPhoneModal?.link,
      title: openSendToPhoneModal.notificationBody.title,
      content: openSendToPhoneModal.notificationBody.content,
      icon: openSendToPhoneModal.notificationBody.icon
    }

    await sendToPhone(params)
  }

  const handleClickLogin = () => {
    dispatch(setOpenSendToPhoneModal({ enabled: false, params: undefined }))
    dispatch(setOpenLoginPopup(true))
  }

  return (
    <Modal
      disableEnforceFocus
      open={openSendToPhoneModal.enabled}
      onClose={() => dispatch(setOpenSendToPhoneModal({ enabled: false, params: undefined }))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <ModalHeaderContainer>
          <ModalHeaderTitle>
            {t('send_to_mobile.title')}
          </ModalHeaderTitle>
          <IconButton onClick={() => dispatch(setOpenSendToPhoneModal({ enabled: false, params: undefined }))}>
            <CloseRoundedIcon style={{ width: '20px', height: '20px', color: '#A6A9AC' }}/>
          </IconButton>

        </ModalHeaderContainer>
        {userData && userData.fcm_token &&
          <>
            <SendToMyPhoneContainer>

              <List style={{ width: '100%' }}>
                <ListItem style={{ padding: '0' }}>
                  <ListItemButton onClick={handleSendToPhoneClick} style={{
                    padding: '4px 8px',
                    margin: '0',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row'
                  }}>
                    <SendToMobileRoundedIcon
                      style={{ color: theme.palette.primary.main, marginRight: '16px' }}
                      fontSize="inherit"
                    />
                    <SocialText>
                      {t('send_to_mobile.title')}
                    </SocialText>
                  </ListItemButton>
                </ListItem>
              </List>
            </SendToMyPhoneContainer>
            <DividerContainer>

              <Divider style={{ fontFamily: 'Visby Round', color: '#8C8C8C' }} orientation="horizontal"
                       flexItem>
                Ó
              </Divider>
            </DividerContainer>
          </>
        }
        <ShareContentContainer>
          <QRCodeContainer>
            <QRCode
              size={130} level={'M'}
              fgColor={theme.palette.primary.main}
              value={openSendToPhoneModal?.params?.shortLink || ''}
            />
            <QRCodeLogo src={UalabeeIsologo}/>
          </QRCodeContainer>
          <QRCodeHelpContainer>
            <HelpText>
              {t('send_to_mobile.step_1')}
            </HelpText>
            <HelpText>
              {t('send_to_mobile.step_2')}
            </HelpText>
            <HelpText>
              {t('send_to_mobile.step_3')}
            </HelpText>
          </QRCodeHelpContainer>

        </ShareContentContainer>
        {mode
          ? (
            <FooterText>
              {t('send_to_mobile.help')}
              <Link
                sx={{ color: theme.palette.primary.main }}
                to="/login"
              >
                {t('send_to_mobile.see_more_options')}
              </Link>
            </FooterText>
            )
          : (
            <FooterText>
              {t('send_to_mobile.help')}
              <Button
                onClick={handleClickLogin}
                sx={{ color: theme.palette.primary.main }}
              >
                {t('send_to_mobile.see_more_options')}
              </Button>
            </FooterText>
            )
        }

      </ModalContainer>
    </Modal>
  )
}

export default SendToPhoneModal
