import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ShareIcon from '@mui/icons-material/Share'
import { handleClickDesktopShare, handleClickMobileShare, handleClickSendToPhone } from '../../actions/ui'
import IconButton from '@mui/material/IconButton'
import SendToMobileRoundedIcon from '@mui/icons-material/SendToMobileRounded'
import { ButtonContainer, ButtonTooltip } from './styles'
import CircularProgress from '@mui/material/CircularProgress'
import { logEvent } from '../../firebase/firebase-config'
import { theme } from '../../theme/theme'

const ShareSmallButton = ({
  shareText,
  shareType,
  params,
  iconColor,
  bgButtonColor,
  buttonSize,
  eventName,
  eventParams, notificationBody
}) => {
  const dispatch = useDispatch()
  const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  const [loading, setLoading] = useState(false)

  const getButtonIcon = () => {
    switch (shareType) {
      case 'share': {
        return <ShareIcon
          style={{ color: `${iconColor || '#A6A9AC'}` }}
          fontSize="inherit"
        />
      }
      case 'send-to-mobile': {
        return <SendToMobileRoundedIcon
          style={{ color: `${iconColor || '#A6A9AC'}` }}
          fontSize="inherit"
        />
      }
    }
  }

  const handleClick = () => {
    logEvent(eventName, eventParams)
    if (mobile) {
      dispatch(handleClickMobileShare(params, setLoading))
    } else {
      if (shareType === 'share') {
        dispatch(handleClickDesktopShare(params, setLoading))
      } else if (shareType === 'send-to-mobile') {
        dispatch(handleClickSendToPhone(params, setLoading, notificationBody))
      }
    }
  }

  return (
    <ButtonContainer>
      <ButtonTooltip
        onClick={handleClick}
        title={shareText}
        sx={{
          backgroundColor: '#F0F0F0',
          color: '#222831',
          fontSize: '12px'
        }}
      >
        <IconButton
          size={buttonSize || 'medium'}
          sx={{
            backgroundColor: `${bgButtonColor || 'transparent'}`,
            '&:hover': {
              backgroundColor: `${bgButtonColor || 'transparent'}`
            }
          }}>
          {getButtonIcon()}
        </IconButton>
      </ButtonTooltip>
      {loading && (
        <CircularProgress
          size={40}
          sx={{
            color: theme.palette.primary.main,
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1
          }}
        />
      )}
    </ButtonContainer>
  )
}

export default ShareSmallButton
