import React from 'react'
import { Alert as MuiAlert, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import { handleCloseAlert } from '../../../actions/ui'
import AlertTitle from '@mui/material/AlertTitle'

export const Alert = () => {
  const mobile = useMediaQuery('(max-width:480px)')

  const dispatch = useDispatch()

  const alert = useSelector(state => state?.ui?.alert)

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: mobile
          ? 'center'
          : 'right'
      }}
      open={alert.open}
      autoHideDuration={alert.duration}
      onClose={() => dispatch(handleCloseAlert())}>
      <MuiAlert
        style={{ borderRadius: 10 }}
        onClose={() => dispatch(handleCloseAlert())}
        severity={alert.severity}>
        <AlertTitle>{alert.title}</AlertTitle>
        {alert.message}
      </MuiAlert>
    </Snackbar>

  )
}
