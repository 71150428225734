import styled from 'styled-components/macro'
import { Button } from '@mui/material'
import { Map } from '@mui/icons-material'

export const Container = styled.div` 
    padding: 20px 16px 0 16px; 
    width: 100%;
    box-sizing: border-box;
`

export const Content = styled.div` 
    height: ${window.innerHeight}px;
`

export const ResultsContainer = styled.div`
    width: 100%;
    margin-top: 6px;
    height: calc(100% - 44px - 78px - 17px);
    overflow: auto;
`

export const Result = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 15px;
    margin-bottom: 2px;
`

export const TypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box; 
`

export const Distance = styled.span`
 font-family: 'Visby Round';
  color: rgba(0,0,0,0.6);
  font-size: 10px;
  margin: 0;
  padding: 0;
`

export const InfoContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
box-sizing: border-box;
`

export const Name = styled.h2`
    font-family: 'Visby Round';
    color: ${props => props.theme.palette.text.primary.main};
    font-size: 14px;
    margin: 0;
    padding: 0;
`

export const City = styled.h3`
    font-family: 'Visby Round';
    color: ${props => props.theme.palette.text.secondary.main}; 
    font-size: 10px;
    margin: 0;
    padding: 0;
`

export const HelpContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
margin-top: 20px;
padding: 20px;
box-sizing: border-box;
`

export const PickButton = styled(Button)` 
`

export const MapIcon = styled(Map)` 
`
