import React from 'react'
import { RecommendedRoutesContainer } from '../../../GlobalStyles'
import ResultItem from '../../ResultItem'
import { useTripResults } from '../../../hooks/useTripResults'
import {
  NoResultsContainer,
  NoResultsText,
  ShowLessIcon,
  ShowMoreContainer,
  ShowMoreIcon,
  ShowMoreText
} from '../../../components/SearchResults/styles'
import { Player } from '@lottiefiles/react-lottie-player'
import EmptyBox from '../../../animations/empty_box.json'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const RecommendedRoutes = ({
  showMore,
  setShowMore,
  itineraries,
  setItineraries
}) => {
  const { t } = useTranslation()

  const {
    handleTripClick
  } = useTripResults()

  const mode = useSelector(state => state?.ui?.mode)
  const tripSelected = useSelector(state => state?.ui?.tripSelected)
  const loading = useSelector(state => state?.ui?.loading)
  const tripResponse = useSelector(state => state?.ui?.tripResponse)

  const handleShowMore = () => {
    setShowMore(!showMore)

    if (!showMore) {
      setItineraries(tripResponse?.itineraries)
    } else {
      window.scrollTo(0, 0)
      setItineraries(tripResponse?.itineraries?.slice(0, 3))
    }
  }

  return (
    <RecommendedRoutesContainer>
      {tripResponse
        ? (
          <>
            {tripResponse?.itineraries?.length === 0
              ? (
                <NoResultsContainer>
                  <Player
                    autoplay
                    loop
                    src={EmptyBox}
                    style={{ height: '250px', width: '250px' }}
                  />
                  <NoResultsText>
                    {t('trip_share.could_not_find_results')}
                  </NoResultsText>
                </NoResultsContainer>
                )
              : (
                <>
                  {itineraries?.map((trip, index) => {
                    const currentTip = trip?.legs?.find(leg => leg?.mode !== 'WALK')

                    const tripSelectedIds = tripSelected?.legs.filter(leg => leg.mode !== 'WALK')?.map(leg => leg.shapeId).toString()
                    const tripIds = trip?.legs.filter(leg => leg.mode !== 'WALK')?.map(leg => leg.shapeId).toString()

                    return (
                      <ResultItem
                        disabled={loading}
                        key={index}
                        trip={trip}
                        onClickFunction={handleTripClick}
                        border={true}
                        icons={false}
                        showOccupancyLevel={currentTip?.crowdedAverage > 0}
                        selected={!mode && tripSelectedIds === tripIds}
                      />
                    )
                  })}

                  {tripResponse?.itineraries?.length > 3 &&
                    <ShowMoreContainer
                      sx={{
                        width: '100%',
                        textTransform: 'none',
                        height: '48px'
                      }}
                      onClick={handleShowMore}
                    >
                      <ShowMoreText
                      >  {showMore ? t('trip_share.button_load_fewer_search_results') : t('trip_share.button_load_more_search_result')}
                      </ShowMoreText>
                      {showMore ? <ShowLessIcon/> : <ShowMoreIcon/>}
                    </ShowMoreContainer>
                  }
                </>
                )

            }</>
          )
        : (
            [0, 1, 2].map((skeleton, index) => (
            <ResultItem
              disabled={true}
              key={index}
              trip={null}
              border={true}
              icons={false}
              showOccupancyLevel={false}
              selected={false}
            />
            ))
          )}

    </RecommendedRoutesContainer>
  )
}
