import React from 'react'
import { AppRouter } from './router/AppRouter'
import { MapProvider } from 'react-map-gl'

export const App = () => {
  return (
    <MapProvider>
      <AppRouter/>
    </MapProvider>
  )
}
