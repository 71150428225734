import React from 'react'
import { Box } from '@mui/material'
import { HelpMsgText } from './styles'

const HelpMessage = ({ message, onClick, color }) => {
  return (
        <Box
            color={color}
            onClick={onClick}
            sx={{
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxSizing: 'border-box',
              padding: '4px 16px',
              borderRadius: '20px',
              backgroundColor: color || '#ffb633',
              marginBottom: 0,
              cursor: 'pointer'
            }}
        >
            <HelpMsgText>{message}</HelpMsgText>
        </Box>
  )
}

export default HelpMessage
