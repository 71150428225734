import { getAuth } from 'firebase/auth'

export const deleteAccount = async () => {
  const auth = getAuth()
  const user = auth.currentUser
  const token = await user.getIdToken()

  const reqConfig = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    }
  }

  return fetch(`${process.env.REACT_APP_DATABASE_HOST}/users`, reqConfig).then((response) => {
    if (response.ok) {
      return response.json()
    }
  }).then((response) => response?.result)
    .catch((err) => console.log(err))
}
