import styled from 'styled-components/macro'

export const InfoContainer = styled.div`
  font-family: 'Visby Round', serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 16px;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const InfoImageContainer = styled.div`
  width: 100%;
  height: 122px;
  border-radius: 0 0 10px 10px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  @media (max-width: 480px) {
    border-radius: 10px;
  }
  -webkit-box-shadow: inset 0px -39px 22px -10px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: inset 0px -39px 22px -10px rgba(0, 0, 0, 0.65);
  box-shadow: inset 0px -39px 22px -10px rgba(0, 0, 0, 0.65);
  background-image: ${(props) => {
    return props.backgroundImg && `url(${props.backgroundImg});`
  }};
`

export const ScheduleContainer = styled.div`

`

export const ScheduleText = styled.div`
  margin-bottom: 5px;
`

export const ScheduleTitle = styled.div`
  font-family: 'Visby Round', serif;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 18px;
`
