import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Icon, Info, Label, Reason, Title, Vows } from './styles'
import { handleClickWebToAppButton, setStopSecurity } from '../../../../actions/ui'
import { getLocalUserUid } from '../../../../db/getLocalUserUid'
import { logEvent } from '../../../../firebase/firebase-config'
import { useTranslation } from 'react-i18next'
import { Dialog, SwipeableDrawer } from '@mui/material'

export const Quiz = () => {
  const dispatch = useDispatch()
  const uid = getLocalUserUid()
  const { t } = useTranslation()

  const stopSecurity = useSelector(state => state?.ui?.components?.stopSecurity)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const mode = useSelector(state => state?.ui?.mode)

  const handleOpen = () => {
    dispatch(setStopSecurity({
      ...stopSecurity,
      enabled: true
    }))
  }

  const handleClose = () => {
    dispatch(setStopSecurity({
      ...stopSecurity,
      enabled: false
    }))
  }

  const handleClick = (item) => {
    dispatch(handleClickWebToAppButton(true, {
      from: `stop_survey_security_no_${item?.id}`,
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      user_id: uid,
      os: 'web',
      city_id: cityConfig?.city_id?.toString(),
      link_to: `${process.env.REACT_APP_HOST}/users_ranking`
    }, logEvent))

    handleClose()
  }

  return (
    <>
      {mode
        ? (
          <SwipeableDrawer
            anchor={'bottom'}
            open={stopSecurity?.enabled}
            onClose={handleClose}
            onOpen={handleOpen}
            disableDiscovery
            PaperProps={{
              style: {
                borderTopLeftRadius: '14px',
                borderTopRightRadius: '14px'
              }
            }}
          >
            <Container>
              <Title>{t('stop.why')}</Title>
              {stopSecurity?.reasons?.map((item) => {
                return (
                  <Reason key={item?.id}>
                    <Info>
                      <Icon alt='' src={item?.icon}/>
                      <Label>{item?.name_es}</Label>
                    </Info>
                    <Vows
                      sx={{
                        backgroundColor: item.selected ? '#d8d8d8' : 'inherit',
                        border: item.selected ? 'none' : '1px solid #d8d8d8'
                      }}
                      variant='outlined'
                      label={item?.vows}
                      clickable onClick={() => handleClick(item)}
                    />
                  </Reason>
                )
              })}
            </Container>
          </SwipeableDrawer>
          )
        : (<Dialog
            open={stopSecurity?.enabled} onClose={handleClose}
            PaperProps={{
              style: { borderRadius: '16px' }
            }}
          >
            <Container>
              <Title>{t('stop.why')}</Title>
              {stopSecurity?.reasons?.map((item) => {
                return (
                  <Reason key={item?.id}>
                    <Info>
                      <Icon alt='' src={item?.icon}/>
                      <Label>{item?.name_es}</Label>
                    </Info>
                    <Vows
                      sx={{
                        backgroundColor: item.selected ? '#d8d8d8' : 'inherit',
                        border: item.selected ? 'none' : '1px solid #d8d8d8'
                      }}
                      variant='outlined'
                      label={item?.vows}
                      clickable onClick={() => handleClick(item)}
                    />
                  </Reason>
                )
              })}
            </Container>
          </Dialog>
          )
}

    </>
  )
}
