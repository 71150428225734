export const getLocalAlertTypes = async () => {
  try {
    if (!localStorage.getItem('alertTypes') || localStorage.getItem('alertTypes') === 'undefined' || typeof {} !== 'object' || Object.keys(localStorage.getItem('alertTypes')).length === 0) return null

    return localStorage.getItem('alertTypes')
      ? JSON.parse(localStorage.getItem('alertTypes'))
      : null
  } catch (e) {
    console.log(e)
  }
}
