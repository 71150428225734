export const getLocalAlertTypesUpdatedAt = () => {
  try {
    if (!localStorage.getItem('alertTypesUpdatedAt') || localStorage.getItem('alertTypesUpdatedAt') === 'undefined' || typeof {} !== 'object' || Object.keys(localStorage.getItem('alertTypesUpdatedAt')).length === 0) return null

    return localStorage.getItem('alertTypesUpdatedAt')
      ? JSON.parse(localStorage.getItem('alertTypesUpdatedAt'))
      : null
  } catch (e) {
    console.log(e)
  }
}
