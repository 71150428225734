import React from 'react'
import {
  BackIcon,
  CityLabel,
  CloseButton,
  CloseIcon,
  FiltersTitle,
  LocationContainer,
  Logo,
  MenuIcon,
  Navbar,
  SearchNavbarContainer
} from './styles'
import logoUalabee from '../../../../img/ualabeelogo.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleOpenMenu,
  setFromPlacesSelected,
  setOpenSearch,
  setOpenTripPreview,
  setOpenTripResults,
  setSearch,
  setShowFilters,
  setShowLines,
  setShowNavbar,
  setShowSearcher,
  setToPlacesSelected
} from '../../../../actions/ui'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Badge, IconButton, Skeleton, Typography } from '@mui/material'
import { PlaceRounded } from '@mui/icons-material'
import { theme } from '../../../../theme/theme'
import maplibregl from 'maplibre-gl'

export const SearchNavbar = ({ map }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { tripPreview, filters } = useSelector(state => state?.ui?.components)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const bounds = useSelector(state => state?.map?.bounds)
  const showMenuNotification = useSelector(state => state?.ui?.components?.showMenuNotification)

  const handleClickClose = () => {
    if (!filters?.enabled) {
      dispatch(setOpenTripPreview(false))

      if (tripPreview?.enabled) {
        dispatch(setOpenSearch(true))
        dispatch(setSearch('', new Date()))
        dispatch(setOpenTripPreview(false))
        dispatch(setShowNavbar(true))
      }

      dispatch(setToPlacesSelected({
        name: t('hint_location_B'),
        latLng: {
          lat: cityConfig?.map.destination_marker_lat,
          lng: cityConfig?.map.destination_marker_lng
        }
      }))

      if (userPosition && bounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))) {
        dispatch(setFromPlacesSelected({ name: t('my_location'), latLng: userPosition }))

        const p1 = new maplibregl.LngLat(cityConfig?.map?.destination_marker_lng, cityConfig?.map?.destination_marker_lat)
        const p2 = new maplibregl.LngLat(userPosition?.lng, userPosition?.lat)

        const bounds = new maplibregl.LngLatBounds(p1, p2)

        map?.fitBounds(bounds, {
          padding: 70
        })
      } else {
        dispatch(setFromPlacesSelected({
          name: t('hint_location_A'),
          latLng: {
            lat: cityConfig?.map.origin_marker_lat,
            lng: cityConfig?.map.origin_marker_lng
          }
        }))
      }

      if (pathname.includes('trip_share')) {
        dispatch(setOpenSearch(true))
        dispatch(setShowNavbar(true))
        dispatch(setOpenTripResults(false))
        history.push('/')
      }

      if (filters.enabled && !pathname.includes('trip_share')) {
        dispatch(setShowFilters(false))
        dispatch(setOpenTripPreview(true))
      } else if (filters.enabled && pathname.includes('trip_share')) {
        dispatch(setOpenTripResults(true))
        dispatch(setShowFilters(false))
      }
    } else {
      dispatch(setShowFilters(false))

      if (pathname.includes('trip_share')) {
        dispatch(setOpenTripResults(true))
      } else {
        dispatch(setOpenTripPreview(true))
      }
    }
  }

  const handleBackClick = () => {
    if (pathname === '/lines') {
      dispatch(setShowLines(false))
      dispatch(setShowNavbar(true))

      history.goBack()
    } else {
      if (pathname.includes('trip_share') && !tripPreview.enabled) {
        dispatch(setOpenTripResults(false))
        dispatch(setShowSearcher(false))
        dispatch(setShowNavbar(false))
        dispatch(setOpenTripPreview(true))

        history.push('/')
      } else if (tripPreview.enabled) {
        dispatch(setOpenTripPreview(false))
        dispatch(setShowSearcher(true))
        dispatch(setShowNavbar(true))

        if (userPosition && bounds?.contains(new maplibregl.LngLat(userPosition?.lng, userPosition?.lat))) {
          dispatch(setFromPlacesSelected({ name: t('my_location'), latLng: userPosition }))
        } else {
          dispatch(setFromPlacesSelected({
            name: t('hint_location_A'),
            latLng: {
              lat: cityConfig?.map?.origin_marker_lat,
              lng: cityConfig?.map?.origin_marker_lng
            }
          }))
        }

        dispatch(setToPlacesSelected({
          name: t('hint_location_B'),
          latLng: {
            lat: cityConfig?.map.destination_marker_lat,
            lng: cityConfig?.map.destination_marker_lng
          }
        }))
      }
    }
  }

  return (
    <SearchNavbarContainer mode={!!filters?.enabled || false}>
      <Navbar>
        {pathname === '/' || pathname.includes('news') || pathname.includes('city_info') || pathname.includes('user_reports') || pathname.includes('login')
          ? (
            <>
              {process.env.REACT_APP_SHOW_MENU === 'true' && !filters?.enabled &&
                <IconButton edge="start" onClick={() => dispatch(handleOpenMenu())}>
                  <Badge overlap="rectangular" color='error' variant="dot"
                         invisible={!showMenuNotification}>
                    <MenuIcon
                      sx={{
                        color: '#A6A9AC'
                      }}
                    />
                  </Badge>
                </IconButton>
              }</>
            )
          : (
            <>
              <IconButton edge="start" onClick={handleBackClick}>
                <BackIcon/>
              </IconButton>
            </>
            )
        }

        {filters?.enabled
          ? (
            <FiltersTitle>{t('search_preview.filters.title')}</FiltersTitle>
            )
          : (
            <>{process.env.REACT_APP_IS_IFRAME
              ? (
                <a href={'https://ualabee.com'} target={'blank'}>
                  <Logo
                    src={process.env.REACT_APP_HARDCODED_LOGO ? process.env.REACT_APP_HARDCODED_LOGO : logoUalabee}/>
                </a>
                )
              : (
                <Logo
                  src={process.env.REACT_APP_HARDCODED_LOGO ? process.env.REACT_APP_HARDCODED_LOGO : logoUalabee}
                />
                )
            }
            </>
            )
        }
        {process.env.REACT_APP_IFRAME_LOGO
          ? <a href={process.env.REACT_APP_LOGO_EXTERNAL_LINK} target="blank">
            <Logo
              src={process.env.REACT_APP_IFRAME_LOGO}/>
          </a>
          : <LocationContainer>
            {pathname === '/' || pathname === '/lines' || pathname.includes('news') || pathname.includes('city_info') || pathname.includes('user_reports') || pathname.includes('login')
              ? (
                <CityLabel>
                  <PlaceRounded
                    sx={{ color: theme.palette.icons.secondary.main }}
                    fontSize="small"
                  />
                  {cityConfig?.name
                    ? (
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: '16px',
                          marginLeft: '8px',
                          color: theme.text.secondary.main
                        }}
                      >
                        {cityConfig?.name}
                      </Typography>)
                    : (
                      <Skeleton sx={{ marginLeft: '5px' }} width={'120px'} height={'16px'}/>
                      )
                  }
                </CityLabel>)
              : (
                <CloseButton
                  sx={{
                    marginRight: '10px',
                    color: '#A6A9AC'
                  }}
                  onClick={handleClickClose}
                >
                  <CloseIcon/>
                </CloseButton>
                )
            }
          </LocationContainer>
        }
      </Navbar>
    </SearchNavbarContainer>
  )
}
