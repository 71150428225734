import PropTypes from 'prop-types'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebase/firebase-config'
import { getAuth } from 'firebase/auth'

export const loadUserVows = async (params) => {
  const auth = getAuth()
  const user = auth.currentUser

  if (user) {
    if (params?.doc_id) {
      const docRef = doc(db, `/global/transportation/stops/${params?.doc_id}/quality_surveys/security/votes/${user?.uid}`)

      try {
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          return docSnap.data()
        } else {
          console.log('No document found')
        }
      } catch (error) {
        console.log('Error getting document' + error)
      }
    } else {
      console.log('No doc_id provided')
    }
  } else {
    console.log('User not logged in')
  }
}

loadUserVows.propTypes = {
  db: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired
}
