import styled from 'styled-components/macro'

export const Content = styled.div`
  padding: 30px 24px 24px 24px;
  height: ${props => props?.isIosApp ? '100%' : 'calc(100% - 56px)'};
  
  box-sizing: border-box;overflow: ${props => props.isLoading ? 'hidden hidden' : 'auto'};
`

export const MobileCityInfo = styled.div`
  width: 100vw;height: ${window.innerHeight}px;
  width: 100%;
`

export const CityInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0;
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 97px;
  border-radius: 10px;
  background-color: ${(props) => {
    return props.color ? props.color : '#20AFA7'
  }};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 24px 17px;
`

export const CardTitle = styled.h2`
  font-family: "Visby Round", serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #ffffff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const CardDescription = styled.p`
  font-family: "Visby Round", serif;
  font-size: 12px;
  margin: 0;
  color: #ffffff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export const ContentContainer = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`
