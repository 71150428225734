import limaLogo from './img/limaLogo.png'
import React from 'react'
import {
  AccessibilityNewRounded,
  BusinessRounded,
  Facebook,
  Instagram,
  PeopleRounded,
  Twitter,
  LocationCityRounded
} from '@mui/icons-material'

export const whiteLabel = () => {
  switch (process.env.REACT_APP_WHITE_LABEL) {
    case 'lima':
      return {
        cityId: 31,
        logo: limaLogo,
        favIcon: true,
        title: 'Lima',
        description: '',
        components: {
          menu: {
            enabled: true,
            items: [
              {
                text: 'Lima item 1',
                icon: <BusinessRounded variant='secondary.main'/>,
                link: 'https://ualabee.com/empresa',
                type: 'menu'
              },
              {
                text: 'Lima item 2',
                icon: <AccessibilityNewRounded variant='secondary.main'/>,
                link: 'https://ualabee.com/colaboradores',
                type: 'menu'
              },
              {
                text: 'Lima item 3',
                icon: <PeopleRounded variant='secondary.main'/>,
                link: 'https://ualabee.com/comunidad',
                type: 'menu'
              },
              {
                text: 'Lima item 4',
                icon: <LocationCityRounded variant='secondary.main'/>,
                link: 'https://ualabee.com/index',
                type: 'menu'
              },
              {
                icon: <Facebook sx={{ color: 'primary.main' }}/>,
                link: 'https://www.facebook.com/ualabee',
                type: 'social-media'
              },
              {
                icon: <Instagram sx={{ color: 'primary.main' }}/>,
                link: 'https://www.facebook.com/ualabee',
                type: 'social-media'
              },
              {
                icon: <Twitter sx={{ color: 'primary.main' }}/>,
                link: 'https://www.facebook.com/ualabee',
                type: 'social-media'
              }
            ],
            downloadApp: {
              enabled: false,
              link: 'https://play.google.com/store/apps/'
            }
          },
          cityChange: {
            enabled: false
          }
        }
      }
    case 'municipalidad-cordoba':
      return {
        cityId: 1,
        favIcon: true,
        description: '',
        components: {
          menu: {
            enabled: false,
            items: [],
            downloadApp: {
              enabled: false,
              link: ''
            }
          },
          cityChange: {
            enabled: false
          }
        }
      }
    case 'cencosud':
      return {
        cityId: 21,
        favIcon: true,
        title: 'Lima',
        description: '',
        components: {
          menu: {
            enabled: false,
            items: [],
            downloadApp: {
              enabled: false,
              link: ''
            }
          },
          cityChange: {
            enabled: false
          }
        }
      }
    default:
      return undefined
  }
}
