import { setCenter, setZoom } from '../actions/map'
import { setLocalCurrentZoom } from '../db/setLocalCurrentZoom'
import { setLocalCurrentCenter } from '../db/setLocalCurrentCenter'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalUserUid } from '../db/getLocalUserUid'
import { useHistory } from 'react-router-dom'
import { enabledAlertsAndNewsComponent, setCurrentNavbarPosition, setOpenAlertInformation } from '../actions/ui'
import { logEvent } from '../firebase/firebase-config'

export const useAlerts = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const userPosition = useSelector(state => state?.user?.userPosition)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const { userReportDrawerRef } = useSelector(state => state?.ui)
  const uid = getLocalUserUid()

  const handleBack = () => {
    dispatch(setCurrentNavbarPosition(0))
    dispatch(enabledAlertsAndNewsComponent({ enabled: false }))
    history.push('/')
  }

  const handleAlertClick = (alert, map) => {
    dispatch(setOpenAlertInformation(true))

    logEvent('alert_clicked', {
      alert_id: alert.id,
      is_alert: alert.isAlert,
      lat: userPosition ? userPosition?.lat : null,
      lng: userPosition ? userPosition?.lng : null,
      city_id: cityConfig?.city_id.toString(),
      user_id: uid,
      os: 'web'
    })

    if (alert?.isAlert) {
      handleBack()
      history.push(`/user_reports/${alert.id}`)

      // set data in Redux
      dispatch(setZoom(16))
      dispatch(setCenter([alert.lng, alert.lat]))
      dispatch(enabledAlertsAndNewsComponent({ enabled: false, current: alert }))

      // set data in localStorage
      setLocalCurrentZoom(18)
      setLocalCurrentCenter([alert.lng, alert.lat])

      map?.flyTo({
        center: [alert.lng, alert.lat],
        zoom: 16,
        speed: 0.5,
        padding: {
          bottom: userReportDrawerRef?.current?.offsetHeight
        }
      })
    } else {
      dispatch(enabledAlertsAndNewsComponent({ enabled: true, current: alert }))
      history.push(`/news/${alert.id}`)
    }
  }

  return {
    handleAlertClick,
    handleBack
  }
}
