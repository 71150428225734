import React from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded'
import FeedbackOnboardingModal from '../FeedbackOnboarding'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import CircularProgress from '@mui/material/CircularProgress'
import { useFeedback } from '../../hooks/useFeedback'
import SuccessFeedbackModal from '../SuccessFeedbackModal'
import { Box, Button, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import { theme } from '../../theme/theme'
import FeedbackForm from '../FeedbackForm'
import { CloseRounded } from '@mui/icons-material'

/**
 * It renders a feedback bar at the top of the screen that can be closed and a modal that can be opened
 * @returns {JSX.Element} A component that shows a feedback bar at the bottom of the screen.
 * @constructor
 */

const FeedbackBar = ({
  feedbackObj,
  padding = '16px',
  formContainer = 'drawer',
  questionId,
  screen,
  barTittle
}) => {
  const {
    loading,
    questions,
    onboardingModalOpen,
    formOpen,
    successModalOpen,
    handleOpen,
    handleOnboardingClose,
    setFormOpen,
    setSuccessModalOpen,
    sendEvent
  } = useFeedback(questionId, screen)

  return (
    <>
      <Button
        sx={{
          flexGrow: 1,
          padding,
          backgroundColor: '#fff',
          opacity: 0.8,
          width: '100%',
          boxSizing: 'border-box',
          borderRadius: 0,
          zIndex: 0
        }}
        onClick={handleOpen}
      >
        <Grid container>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              width: '100%',
              boxSizing: 'border-box',
              flexDirection: 'row'
            }}
            item xs={12}
          >
            <IconButton
              disabled={loading}
            >

              {loading
                ? <CircularProgress
                  sx={{
                    color: theme.palette.primary.main
                  }}
                  size={24}
                />
                : <FeedbackRoundedIcon
                  sx={{
                    color: theme.palette.primary.main
                  }}
                />}
            </IconButton>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.primary.main,
                fontSize: '14px',
                margin: 0,
                fontWeight: 200,
                textAlign: 'center',
                cursor: 'pointer',
                userSelect: 'none',
                WebkitTapHighlightColor: 'transparent'
              }}
            >
              {barTittle}
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <FeedbackOnboardingModal open={onboardingModalOpen} handleClose={handleOnboardingClose}/>

      {formContainer === 'drawer'
        ? <SwipeableDrawer
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor="bottom"
          open={formOpen}
          onClose={() => setFormOpen(false)}
          onOpen={() => setFormOpen(true)}
          sx={{
            borderRadius: '14px 14px 0 0',
            '.MuiDrawer-paper': {
              borderRadius: '14px 14px 0 0',
              padding: '10px'
            }
          }}
        >
          <Box>

            <Grid
              item={true}
              component='div'
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '10px'
              }}
            >
            <span
              style={{
                margin: 0,
                padding: 0,
                width: '20px',
                height: '4px',
                borderRadius: '2px',
                backgroundColor: 'rgba(0,0,0,0.2)',
                boxSizing: 'border-box',
                display: 'table'
              }}
            />
            </Grid>
            {questions &&
              <FeedbackForm
                question={questions}
                handleClose={setFormOpen}
                setSuccessModalOpen={setSuccessModalOpen}
                feedbackObj={feedbackObj}
                sendEvent={sendEvent}
              />
            }

          </Box>
        </SwipeableDrawer>
        : <Modal
          open={formOpen}
          onClose={() => setFormOpen(false)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              maxWidth: '400px',
              backgroundColor: '#fff',
              borderRadius: '15px',
              padding: '20px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
            }}
          >
            <IconButton
              onClick={() => setFormOpen(false)}
              sx={{
                position: 'absolute',
                top: '28px',
                right: '20px'
              }}
            >
              <CloseRounded/>
            </IconButton>
            {questions &&
              <FeedbackForm
                feedbackObj={feedbackObj}
                setSuccessModalOpen={setSuccessModalOpen}
                handleClose={setFormOpen}
                question={questions}
                sendEvent={sendEvent}
              />
            }
          </Box>
        </Modal>
      }
      <SuccessFeedbackModal setSuccessModalOpen={setSuccessModalOpen} successModalOpen={successModalOpen}/>
    </>
  )
}

export default FeedbackBar
