import React, { useEffect, useState } from 'react'
import { Buttons, Cancel, Content, Create, Form, Input, Inputs, Title } from './styles'
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, updateProfile } from 'firebase/auth'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { CodeVerification } from '../CodeVerification'
import './styles.css'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { handleOpenAlert } from '../../actions/ui'

export const PhoneSignIn = ({ setLoginButtons, setLoading }) => {
  const auth = getAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [formLoading, setFormLoading] = useState(false)
  const [formMode, setFormMode] = useState('phone')
  const [defaultCountry, setDefaultCountry] = useState('ar')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [confirmationAuthResult, setConfirmationAuthResult] = useState(null)
  const [name, setName] = useState('')
  const [phoneNumberFormat, setPhoneNumberFormat] = useState()

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      size: 'invisible'
    }, auth)
    if (localStorage.getItem('i18nextLng').includes('-')) {
      // split the language code and get the country code
      const country = localStorage.getItem('i18nextLng').split('-')[1].toLocaleLowerCase()
      // set the country code
      setDefaultCountry(country)
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormLoading(true)
    const appVerifier = window.recaptchaVerifier

    signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
      .then((confirmationResult) => {
        setConfirmationAuthResult(confirmationResult)
        setFormLoading(false)
        setFormMode('phone-verification')
      })
      .catch((error) => {
        window.recaptchaVerifier.render().then(function (widgetId) {
          grecaptcha.reset(widgetId)
        })
        console.log('error', error)
        setFormLoading(false)
        dispatch(handleOpenAlert({
          open: true,
          severity: 'error',
          duration: 3000,
          title: t('login.failure_login_title'),
          message: t('login.failure_login_message')
        }))
      })
  }

  const handleSaveName = async () => {
    setLoading(true)
    setFormLoading(true)
    await updateProfile(auth.currentUser, {
      displayName: name
    })
    setFormLoading(false)
    setLoading(false)
    history.push('/')
  }

  const handleFormat = (country) => {
    const { format, dialCode, countryCode } = country

    if (!phoneNumberFormat || phoneNumberFormat.countryCode !== countryCode) {
      const formatArr = format.split(' ').map((part, index) => {
        if (index === 0) {
          return `+${dialCode}`
        } else {
          return part.replaceAll('.', Math.floor(Math.random() * 10))
        }
      })

      const helper = formatArr.join(' ')

      setPhoneNumberFormat({
        countryCode,
        helper
      })
    }
  }

  return (
    <Content>
      {formMode === 'phone' &&
        <>
          <Title>{t('login.phone_number_title')}</Title>
          <Form
            onSubmit={handleSubmit}
          >
            <Inputs>
              <PhoneInput
                inputClass={'phone-input'}
                country={defaultCountry}
                value={''}
                onChange={(value, country) => {
                  handleFormat(country)
                  setPhoneNumber(value)
                }}
              />
              {phoneNumberFormat &&
                <span className='helper_phone_text'>{phoneNumberFormat.helper}</span>
              }
            </Inputs>
            <Buttons>
              <Cancel
                disabled={formLoading}
                onClick={() => setLoginButtons(true)}>{t('login.cancel_button')}</Cancel>
              <Create
                id='sign-in-button'
                loading={formLoading}
                disabled={formLoading}
                type="submit">
                {!formLoading && t('login.continue_button')}
              </Create>
            </Buttons>
            <span className='fee_warning'>{t('login.fee_warning')}</span>
          </Form>
        </>
      }
      {formMode === 'phone-verification' &&
        <>
          <Title>{t('login.code_verification_title', {
            prop1: `+${phoneNumber}`
          })}</Title>
          <CodeVerification confirmationAuthResult={confirmationAuthResult} setFormMode={setFormMode}
                            phoneNumber={phoneNumber}/>
        </>
      }
      {formMode === 'name-form' &&
        <>
          <Title>{t('login.name_form_title')}</Title>
          <Form
            onSubmit={handleSaveName}
          >
            <Inputs>
              <Input
                disabled={formLoading}
                autoComplete={'name'}
                autoFocus={true}
                variant='standard'
                label={t('login.name_form_title')}
                type={'text'}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Inputs>
            <Buttons>
              <Create
                loading={formLoading}
                disabled={formLoading}
                type="submit">
                {!formLoading && t('login.continue_button')}
              </Create>
            </Buttons>
          </Form>
        </>
      }
    </Content>
  )
}
