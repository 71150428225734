import React, { useEffect, useRef } from 'react'
import FilterDrawer from '../../uicomponents/FilterDrawer'
import HelpMessage from '../../uicomponents/HelpMessage'
import { Virtuoso } from 'react-virtuoso'
import { Content, LinesContainer, LinesContent, LinesList, SearchContainer } from './styles'
import { LineItem } from '../../uicomponents/LineItem'
import { Home } from '../Desktop/Home'
import { useDispatch, useSelector } from 'react-redux'
import { SkeletonApp } from '../../uicomponents/SkeletonApp'
import { useLines } from '../../hooks/useLines'
import TitleTopBar from '../../uicomponents/TitleTopBar'
import LinesFilerNavBar from '../LinesFilterNavBar'
import { SkeletonLine } from '../../uicomponents/desktop/line/Line'
import NavBar from '../NavBar'
import { Loading as LoadingLinear } from '../../uicomponents/mobile/Loading'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../uicomponents/layouts/Layout'
import { setCurrentLines, setSearch, setShowNavbar, verifyLines } from '../../actions/ui'
import { useMediaQuery } from '@mui/material'
import { setLineSearchResult, setTransportationType } from '../../actions/user'

const Lines = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const mobile = useMediaQuery('(max-width:480px)')

  const {
    handleSearch
  } = useLines()

  const cityConfig = useSelector(state => state?.ui?.cityConfig)
  const mode = useSelector(state => state?.ui?.mode)
  const loading = useSelector(state => state?.ui?.loading)
  const currentLines = useSelector(state => state?.ui?.currentLines)
  const lineSearchResult = useSelector(state => state?.user?.lines?.lineSearchResult)
  const lines = useSelector(state => state?.ui?.lines)
  const user = useSelector(state => state?.user?.auth)
  const isIosApp = useSelector(state => state?.ui?.isIosApp)

  useEffect(() => {
    dispatch(setShowNavbar(false))
    dispatch(setLineSearchResult(''))
    dispatch(setSearch('', new Date()))
  }, [])

  useEffect(() => {
    if (!user) return
    dispatch(verifyLines(mobile))
  }, [user])

  const virtuoso = useRef(null)

  const handleLineFilter = (typeId = 0) => {
    dispatch(setTransportationType(typeId))

    let currentLines = typeId === 0 ? lines : lines.filter(line => line.transport_type_id === typeId)
    if (lineSearchResult || lineSearchResult?.length >= 0) {
      currentLines = currentLines?.filter(line =>
        (line?.route_short_name || line?.route_long_name)?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            lineSearchResult
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          )
      )
    }
    dispatch(setCurrentLines(currentLines))
  }

  return (
    <Layout>
      {loading && <LoadingLinear/>}
      {user
        ? (
          <>
            {mode
              ? (
                <LinesContent>
                  <SearchContainer>
                    <TitleTopBar backPath={'/'} title={t('lines.header.title')}/>
                    <FilterDrawer
                      onChangeFunction={handleSearch}
                      placeholder={t('lines.autocomplete.placeholder')}
                      containerPadding={'0px 16px'}
                    />
                  </SearchContainer>
                  <LinesContainer
                  >
                    {currentLines
                      ? (
                        <Content>
                          <LinesList
                          >
                            {currentLines?.length >= 1
                              ? (
                                <Virtuoso
                                  id='line-list'
                                  ref={virtuoso}
                                  useWindowScroll={false}
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'auto'
                                  }}
                                  totalCount={currentLines?.length}
                                  itemContent={(index) => {
                                    const icon = cityConfig?.transport_types?.find((type) => currentLines[index]?.transport_type?.transport_type_id === type?.transport_type_id)?.flat_icon

                                    return (
                                      <LineItem
                                        key={currentLines[index]?.route_id}
                                        line={currentLines[index]}
                                        icon={icon}
                                      />
                                    )
                                  }}
                                />
                                )
                              : (<div style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginTop: 10
                                }}>
                                  <HelpMessage
                                    message={t('lines.helper_text')}
                                    color={'#cfcfcf'}
                                  />
                                </div>
                                )
                            }

                            {isIosApp &&
                              <NavBar/>
                            }
                          </LinesList>
                          <LinesFilerNavBar onClickFunction={handleLineFilter}/>
                        </Content>
                        )
                      : (
                        <SkeletonLine/>
                        )
                    }
                  </LinesContainer>
                </LinesContent>
                )
              : (
                <Home/>
                )
            }
          </>

          )
        : (
          <SkeletonApp/>
          )
      }
    </Layout>
  )
}

export default Lines
