import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CityInfo from '../components/CityInfo'
import Lines from '../components/Lines'
import Line from '../components/Line'
import Stop from '../components/Stop'
import SearchAutocomplete from '../components/SearchAutocomplete'
import DestinationPreview from '../components/DestinationPreview'
import SearchResults from '../components/SearchResults'
import { ResultsRoutesAndTrajectory } from '../components/ResultsRoutesAndTrajectory'
import CovidCenters from '../components/Covid/CovidCenters'
import Login from '../components/Login'
import { LandingPage } from '../components/LandingPage'
import UserProfile from '../components/UserProfile'
import { setUserPosition, startLogin } from '../actions/user'
import { useDispatch } from 'react-redux'
import NoLoginDialog from '../uicomponents/NoLoginDialog'
import { ChooseFromMap } from '../components/ChooseFromMap'
import { Alert } from '../uicomponents/ui'
import {
  setIsIosApp,
  setLoading,
  setOpenBottomSmartBanner,
  setOpenSmartBanner,
  setOpenTopSmartBanner,
  setShowETA
} from '../actions/ui'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { remoteConfig } from '../firebase/firebase-config'
import { SkeletonApp } from '../uicomponents/SkeletonApp'
import { InvitationMessage } from '../components/InvitationMessage'
import { Ad } from '../uicomponents/ui/Ad'
import { Home } from '../components/Home'
import StopTimes from '../components/StopTimes'

export const AppRouter = () => {
  const dispatch = useDispatch()

  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    if (!process.env.REACT_APP_WHITE_LABEL && !navigator?.userAgent?.includes('UalabeeLite=true') && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      dispatch(setLoading(true))
      setFetching(true)

      fetchAndActivate(remoteConfig)
        .then(() => {
          const { _value: showCaptivePortal } = getValue(remoteConfig, 'show_captive_portal')
          dispatch(setOpenSmartBanner(JSON.parse(showCaptivePortal)))

          if (JSON.parse(showCaptivePortal)) {
            setFetching(false)
            dispatch(setLoading(false))
          }

          const { _value: showTopBanner } = getValue(remoteConfig, 'show_smart_banner_top')
          dispatch(setOpenTopSmartBanner(JSON.parse(showTopBanner)))
          const { _value: showBottomBanner } = getValue(remoteConfig, 'show_smart_banner_bottom')
          dispatch(setOpenBottomSmartBanner(JSON.parse(showBottomBanner)))

          setFetching(false)
          dispatch(setLoading(false))
        })
        .catch((e) => {
          setFetching(false)
          dispatch(setLoading(false))

          console.log('Error fetching and activating remote config', e)
        })
    } else {
      setFetching(false)
    }

    dispatch(startLogin())

    const currentShowETA = localStorage.getItem('show_arrival_time_information')
      ? JSON.parse(localStorage.getItem('show_arrival_time_information'))
      : true
    dispatch(setShowETA(currentShowETA))
  }, [])

  useEffect(() => {
    dispatch(setIsIosApp(navigator.userAgent.includes('UalabeeLite=true')))
  }, [navigator.userAgent])

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const userPosition = {
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude
          }
          dispatch(setUserPosition(userPosition))
        },
        (err) => {
          console.log(`ERROR(${err.code}): ${err.message}`)
        }, {
          enableHighAccuracy: false,
          timeout: 5000, // A positive long value representing the maximum length of time (in milliseconds) the device is allowed to take in order to return a position.
          maximumAge: 0 //  it means that the device cannot use a cached position and must attempt to retrieve the real current position.
        })
    } else {
      console.log('Browser does not support geolocation!')
    }
  }, [])

  if (fetching) {
    return <SkeletonApp/>
  }

  return (
    <Router>

      <div
        style={{
          width: '100vw',
          height: '100vh',
          maxHeight: '100vh'
        }}
      >

        <Switch>

          <Route
            exact
            path="/"
            render={() => <Home/>}
          />

          <Route
            exact
            path="/news"
            render={() => <Home/>}
          />

          <Route
            exact
            path="/city_info"
            render={() => <CityInfo/>}
          />

          <Route
            exact
            path="/city_info/:cityId/:id"
            render={() => <CityInfo/>}
          />

          <Route
            exact
            path="/news/:id"
            render={() => <Home/>}
          />

          <Route
            exact
            path="/user_reports/:id"
            render={() => <Home/>}
          />

          <Route
            exact
            path="/lines"
            render={() => <Lines/>}
          />

          <Route
            exact
            path="/routes/:cityId/:lineId"
            render={() => <Line/>}
          />

          <Route
            exact
            path="/routes/:cityId/:lineId/:routeId"
            render={() => <Line/>}
          />

          <Route
            exact
            path="/stops/:cityId/:stopCode"
            render={() => <Stop/>}
          />

          <Route
            exact
            path="/stop_times/:cityId/:shapeid/:stopid"
            render={() => <StopTimes/>}
          />

          <Route
            exact
            path="/search"
            render={() => <SearchAutocomplete/>}
          />

          <Route
            exact
            path="/destination-preview"
            render={() => <DestinationPreview/>}
          />

          <Route
            exact
            path='/trip_share'
            render={() => <SearchResults/>}
          />

          <Route
            exact
            path='/results-routes-and-trajectory'
            render={() => <ResultsRoutesAndTrajectory/>}
          />

          <Route
            exact
            path='/covid'
            render={() => <CovidCenters/>}
          />

          <Route
            exact
            path="/covid/:id"
            render={() => <CovidCenters/>}
          />

          <Route
            exact
            path="/login"
            render={() => <Login/>}
          />

          <Route
            exact
            path="/deep_links"
            render={() => <LandingPage/>}
          />

          <Route
            exact
            path="/user/:uid"
            render={() => <UserProfile/>}
          />

          <Route
            exact
            path="/choose-from-map"
            render={() => <ChooseFromMap/>}
          />

          {/* <Redirect to="/deep_links"/> */}

        </Switch>

        <NoLoginDialog/>
        <Alert/>
        <Ad/>

        <InvitationMessage/>

      </div>

    </Router>
  )
}
