import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenShareModal } from '../../actions/ui'
import {
  CopiedContainer,
  CopiedText,
  LinkCopyButton,
  LinkInput,
  LinkOptionContainer,
  ModalContainer,
  ModalHeaderContainer,
  ModalHeaderTitle,
  ShareOptionsContainer,
  ShareOptionTitle,
  SocialIcon,
  SocialText
} from './styles'
import IconButton from '@mui/material/IconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import WhatsAppIcon from '../../img/whatsapp-icon.svg'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { useTranslation } from 'react-i18next'

const ShareModal = () => {
  const dispatch = useDispatch()
  const { openShareModal } = useSelector(state => state?.ui)
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()

  const handleCopy = () => {
    navigator?.clipboard?.writeText(openShareModal?.params?.shortLink).then(function () {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    }, function (err) {
      /* clipboard write failed */
      console.log(err)
    })
  }

  const handleWhatsAppShare = () => {
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    if (mobile) {
      window.open(`whatsapp://send?text=${openShareModal?.params?.shortLink}`, '_blank')
    } else {
      window.open(`https://web.whatsapp.com/send?text=${openShareModal?.params?.shortLink}`, '_blank')
    }
  }

  return (
    <Modal
      disableEnforceFocus
      open={openShareModal.enabled}
      onClose={() => dispatch(setOpenShareModal({ enabled: false, params: undefined }))}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContainer>
        <ModalHeaderContainer>
          <ModalHeaderTitle>
            {t('share.title')}
          </ModalHeaderTitle>
          <IconButton onClick={() => dispatch(setOpenShareModal({ enabled: false, params: undefined }))}>
            <CloseRoundedIcon style={{ width: '20px', height: '20px', color: '#A6A9AC' }}/>
          </IconButton>

        </ModalHeaderContainer>
        <ShareOptionsContainer>
          <ShareOptionTitle>{t('share.link')}</ShareOptionTitle>
          <LinkOptionContainer>

                        <LinkInput disabled value={openShareModal?.params?.shortLink}/>
                        {copied
                          ? <CopiedContainer>
                                <CheckRoundedIcon style={{ color: '#49CB5C', fontSize: '14px', marginRight: '6px' }}/>
                                <CopiedText>{t('share.copied')}</CopiedText>
                            </CopiedContainer>
                          : <LinkCopyButton onClick={handleCopy}>{t('share.copy_link')}</LinkCopyButton>
                        }
                    </LinkOptionContainer>
                    <ShareOptionTitle>{t('share.social')}</ShareOptionTitle>
                    <List style={{ width: '100%' }}>
                        <ListItem onClick={handleWhatsAppShare} style={{ padding: '0' }}>
                            <ListItemButton style={{
                              padding: '4px 8px',
                              margin: '0',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row'
                            }}>
                                <SocialIcon src={WhatsAppIcon} alt={t('share_with_wpp')}/>
                                <SocialText>
                                    {t('send_by_wpp')}
                                </SocialText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </ShareOptionsContainer>
            </ModalContainer>
        </Modal>
  )
}

export default ShareModal
