import styled from 'styled-components/macro'

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    padding: 20px 16px 0px 16px;
    height: ${window.innerHeight}px;
    width: 100%;
`

export const LogoUalabee = styled.img`
    width: 150px;
`

export const DisclaimerContainer = styled.div`
  font-family: 'Visby Round';
  font-size: 14px;
  margin: 0;
  color: #9a9898;
  margin-top: 20px;
  width: 100%;
  text-align: center;
`

export const ButtonsContainer = styled.div`
display: flex;
flex-direction: column;
position: relative;
align-items: center;
justify-content: center;
box-sizing: border-box;
flex-direction: column;
align-items: center;
justify-content: center;
`

export const LegalLink = styled.a`
  font-size: 14px;
  font-family: 'Visby Round';
  color: #0d98b1;
  margin: 0;
`

export const LoaderContainer = styled.div`
display: flex;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
`
