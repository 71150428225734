import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Menu } from '@mui/material'
import { LanguageRounded } from '@mui/icons-material'
import { logEvent } from '../../../firebase/firebase-config'
import { theme } from '../../../theme/theme'
import { Container } from './styles'
import { Link } from 'react-router-dom'

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation()

  const supportedLanguages = [
    { id: 'en', language: t('languages.english') },
    { id: 'es-CL', language: t('languages.spanish', { country: '(Chile)' }) },
    { id: 'es', language: t('languages.spanish', { country: '' }) }
  ]

  const mode = useSelector(state => state?.ui?.mode)
  const user = useSelector(state => state?.user?.auth)
  const userPosition = useSelector(state => state?.user?.userPosition)
  const userData = useSelector(state => state?.user?.userData)
  const cityConfig = useSelector(state => state?.ui?.cityConfig)

  const [anchorEl, setAnchorEl] = useState(null)
  const [language, setLanguage] = useState('English')

  const open = Boolean(anchorEl)

  useEffect(() => {
    setLanguage(i18n.language)
  }, [i18n.language])

  const handleClick = (event) => {
    logEvent('open_language_selector', {
      lat: userPosition?.lat ? userPosition.lat : null, // Double
      lng: userPosition?.lng ? userPosition.lng : null, // Double
      user_gender: userData?.gender ? userData.gender : null, // String
      user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null, // Long
      user_id: user?.uid, // String
      city_id: cityConfig?.city_id?.toString() // String
    })

    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = async (e) => {
    const locale = e.target.id

    const language = supportedLanguages?.find(lng => lng?.id === locale)

    logEvent('change_language_cta', {
      lat: userPosition?.lat ? userPosition.lat : null, // Double
      lng: userPosition?.lng ? userPosition.lng : null, // Double
      user_gender: userData?.gender ? userData.gender : null, // String
      user_birthday_timestamp: userData?.birthday?.long_value ? userData.birthday.long_value : null, // Long
      user_id: user?.uid, // String
      language_name: language?.language, // String
      language_locale: language?.id, // String
      city_id: cityConfig?.city_id?.toString() // String
    })

    try {
      await i18n.changeLanguage(locale)
      setLanguage(locale)
    } catch (e) {
      console.log('Error changing language', e)
    }

    handleClose()
  }

  return (
        <Container>
            <Button
                variant='contained'
                sx={{
                  backgroundColor: mode ? '#00000080' : theme.palette.primary.main,
                  position: mode ? 'absolute' : 'relative',
                  top: 8,
                  left: 8,
                  '&:hover': {
                    backgroundColor: mode ? '#00000080' : theme.palette.primary.main,
                    boxShadow: 'none'
                  },
                  textTransform: 'uppercase',
                  fontFamily: 'Visby Round',
                  fontSize: '12px',
                  borderRadius: '8px',
                  margin: '8px',
                  boxShadow: 'none'
                }}
        startIcon={<LanguageRounded fontSize='small'/>}
        onClick={handleClick}
        aria-label={`language ${language}`}
      >
        {language}
      </Button>
      <Menu
        id="languages-menu-list"
        aria-labelledby="languages-menu-list"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '&.MuiMenu-root .MuiMenu-paper': {
            boxShadow: '0px 3px 6px #22283129',
            borderRadius: '8px'
          }
        }}
      >
        {supportedLanguages.map(({ id, language }) => (
          <Link
            style={{
              display: 'flex',
              width: '100%',
              padding: '6px 16px',
              textDecoration: 'none',
              color: 'rgba(0, 0, 0, 0.87)'
            }}
            to={`/?hl=${id}`}
            key={id} id={id}
            onClick={handleChange}>
              {language}
          </Link>
        ))}
      </Menu>
    </Container>
  )
}
